<template>
  <router-view />
</template>

<script></script>

<style>
  @import './assets/css/main.css';
  @import './assets/css/article.css';
  @import './assets/css/institution.css';
</style>

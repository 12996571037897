export default {
  study_abroad_interaction : {
    interaction_1 : {
      title   : '本科申請菲律賓留校推薦',
      content : {
        content_1 : '隨著菲律賓近年的經濟不斷發展，也踴躍出許多優質的大學，是許多學生留學的熱門選擇國家之一，那麼本科申請菲律賓留學有哪些院校推薦？就讓小編來為大家推薦一下！',
        content_2 : '本科申請菲律賓留學有哪些院校推薦',
        content_3 : '菲律賓大學',
        content_4 :
          '菲律賓大學是菲律賓規模最大的國立綜合大學。菲律賓排名居第一位，2021年QS亞洲榜排名第69名。菲律賓大學是第一個由菲律賓立法機構所建立的大學。於1908年創建。總共有56所學院並提供413個學位課程，擁有約4700個教職員和約46,000個學生。 在菲律賓大學沒有對宗教的束縛和對服裝的規章制度，崇尚一種自由的校園風氣。 ',
        content_5 : '馬尼拉雅典耀大學',
        content_6 : `菲律賓排名居第二位，2021年QS亞洲榜排名第135名。馬尼拉雅典耀大學成立於1859年，是一所包括文學院、商學院、法學院等眾多科系的綜合性私立大學。 1959年，獲得頒發大學學位資格，
 而今大學不斷擴大為包括文科學院、商學研究所、法學院等多個獨立學院在內的綜合型大學。除此之外，就如大部分的菲律賓大學一樣，擁有高中及小學。菲律賓現任總統貝尼尼奧·阿基諾三世曾就讀該校，並在從政前擔任該校的助理教授。 `,
        content_7 : '德拉薩大學',
        content_8 : `菲律賓排名居第3位，2021年QS亞洲榜排名第166名。德拉薩大學創立於1911年，位於馬尼拉的市區，目前約有25,000名在校學生就讀。
 是菲律賓國內第一位的私立院校，同時也是菲律賓知名的貴族學校。由於德拉薩在始建之初的目標就是成為菲律賓的貴族學校，因此，即使已經跨越百年，今天的德拉薩仍然是既有天賦又有錢的學生才能跨入的大學——這就是德拉薩大學在菲律賓人心中的形象，不僅是學術的象徵，也是身分的象徵。 `,
        content_9  : '聖卡洛斯大學',
        content_10 : `菲律賓排名居第5位，2021年QS亞洲榜排名451-500名。菲律賓聖卡洛斯大學(University of San Carlos)，簡稱USC，創立於1565年，
 至今已有450多年的校史，是菲律賓也是亞洲最古老的私立貴族學府。聖卡洛斯大學有近40個本科專業和83個碩士和博士專業供考生挑選，是我國教育部留學服務中心認可的第一批「菲律賓正規高校」之一，同時也是我國駐菲律賓大使館確認的第一批「有資質接收外國留學生菲律賓高校」之一。 `,
        content_11 : 'Adamson University',
        content_12 : '中文名：亞當森大學',
        content_13 : `學校簡介大學創立於1932年，創辦之初是為了發展化學工業的需求，特別針對菲律賓在化學領域的科學和技術。 1941年2月，學校建立大學教育系。
 1964年12月作為天主教大學加入亞當森·奧扎南教育機構，專門致力於天主教教學的發展，廣泛擴展學校，大量招生，尤其是針對貧困人群，並在全球天主教教學學術上有著傑出貢獻。亞當森大學（Adamson University）是天主教Vincentian的教育機構， 是公認的優質教育（特別是針對處境不利者的優質教育）的領先中心。 `,
        content_14 : `亞當森大學是一所天主教大學，位於馬尼拉市中心。希臘化學家喬治·盧卡斯·亞當森（George Lucas Adamson）博士於1932年將其創建為亞當森工業化學學院。
 它在1941年成為一所大學，並在1964年，使團或文森特教派成為新的所有者和管理者。 亞當森大學的旗艦課程是工程學，但九個學院中所有課程的品質都通過各種認證， 其自主身份和ISO 9001：2015認證來表明。
 透過不斷升級的設備和設施，教職員工的專業發展，加強研究並將宣傳服務制度化，這些得以維持；但是，儘管素質教育有所改善，但負擔能力仍然存在。亞當森大學透過招收國際學生， 與外國機構和組織的夥伴關係，東協一體化以及在世界各地設立校友分會而走向全球。 `,
        content_15 :
          'AdU在研究生院、法學院、文科學院、工程學院、護理學院、藥學院、建築學院、工商管理學院和教育學院以及中等教育、初等教育和預科教育均設有課程。其中有8門課程被菲律賓高等教育委員會列為發展中心和卓越中心，並已獲得自主權利。 ',
        content_16 : 'Polytechnic University of the Philippines',
        content_17 : '中文名：菲律賓理工大學',
        content_18 : '類型：州立大學入學考試：必修（PUPCET）宗教信仰：非宗派學期結構：學期學年：六月開學',
        content_19 : '學校簡介',
        content_20 :
          '菲律賓理工大學創立於1904年，是國家輔助並認可的高等教育學府。 菲律賓理工大學的總部坐落於經濟繁華的首都城市馬尼拉，目前在校人數約5 萬可以說是菲律賓學生人數最多的國立大學。 大學有教學經驗豐富及學術背景良好的教授。 '
      }
    },
    interaction_2 : {
      title   : '菲律賓留學：適合的五類人群',
      content : {
        content_1 :
          '近來，菲律賓留學因其費用低廉、英語環境、美式教育等優勢，成為中國工薪家庭留學的新寵兒。那麼到底是什麼樣的學生真正適合留學菲律賓呢？留學專家表示，適合去菲律賓留學的學生主要分為以下五類。 ',
        content_2 : '1、高中(職)或中專學歷的學生：考不上大學的落榜生或只能考上國內三本或專科的學生。可以直接去菲律賓讀一個重點大學熱門本科。 ',
        content_3 : '2、四年本科生：對於本科專業不太好，想轉專業的同學，可以直接赴菲換一個新專業攻讀碩士，這樣就業範圍就擴大了很多。 ',
        content_4 : '3、三年專科畢業的學生：在評估後用最短的時間補修本科的學分之後獲得菲律賓的本科文憑，本碩連讀的話最快用3年時間獲得碩士文憑。 ',
        content_5 : '4、具有碩士文憑的在職人員：因為工作的原因想進一步提升自己學位的人士，可以選擇菲律賓院校的在職班學習，從而獲得博士文憑。 ',
        content_6 : '5、遊學：想提升自己的英語口語和聽力，利用假期到菲律賓進行短暫的英語提升的學生，菲律賓院校有不同的語言課程供你選擇。 '
      }
    },
    interaction_3 : {
      title   : '菲律賓各大學的優勢專業',
      content : {
        content_1 : '菲律賓聖保羅大學：',
        content_2 : `工商管理博士、工商管理碩士（飯店與餐飲管理專業）、工商管理碩士（組織發展專業）、工商管理碩士（資訊系統專業）、
 教育哲學博士（教育領導與管理專業）、教育哲學博士（課程設計專業）、教育哲學博士（發展與監督專業）、教育碩士（教育領導與管理專業）、音樂文學博士（重點學習：合唱指揮、作曲、音樂教育、管弦樂指揮與表演）、音樂文學碩士（重點學習：合唱指揮、作曲、音樂教育、管弦樂指揮與表演）`,
        content_3 : '菲律賓國父大學：',
        content_4 : `工商管理博士--Doctor inBusinessAdministration、公共管理博士--Doctor in PublicAdministration、
 教育博士 或 譯為 教育學博士 --Doctor ofEducation、工商管理碩士、公共管理碩士、公共管理碩士（公共衛生專業）、
 教育文學碩士（教育管理專業）-MasterofArtsinEducation（SpecializationinEducationalAdministration）、
 教育文學碩士（語言教育專業）-MasterofArtsinEducation（SpecializationinLanguageEducation）、教育文學碩士（數學專業）-Master of Arts in Education（Specialization in Mathematics）、
 教育文學碩士（特殊教育專業）-Master of Arts in Education（Specialization in SpecialEducation）`,
        content_5 : '菲律賓中央大學：',
        content_6 : `管理學博士（公共管理專業）、管理學博士（旅遊和酒店管理專業）、管理學博士（企業管理專業）、教育學博士（行政管理專業）、文學碩士（英語語言文學專業）、
 文學碩士（英語文學專業）、電腦科學碩士（含論文）、電腦科學碩士（不含論文）、教育碩士（行政管理專業）、教育碩士（體育專業含論文）、教育碩士（體育專業不含論文）、工商管理碩士（含論文）、工商管理碩士（不含論文）、工商管理碩士（旅遊與酒店管理專業）`,
        content_7 : '雅典耀大學：',
        content_8 : `哲學博士（英語語言文學）、教育碩士（行政學）、教育碩士（教育管理）、經濟學博士、經濟學博士（本科直博）
 、經濟學碩士心理學碩士（組織心理學專業）、心理學碩士（社會心理學專業）、心理學碩士（心理諮商專業）、心理學博士（組織心理學專業）、心理學博士（社會心理學專業）、心理學博士（心理諮商專業）、對外漢語教學碩士、電子工程碩士、電腦科學碩士、電腦科學哲學博士`,
        content_9 : '菲律賓科技大學：',
        content_10 :
          '土木工程碩士、土木工程碩士（土壤工程專業）、土木工程碩士（結構工程碩士）、理學碩士（電子工程專業）、理學碩士（通訊工程專業）、工程碩士（土木工程專業）、管理學碩士、管理科學博士（教育學專業）、管理科學博士（職業指導專業）',
        content_11 : '菲律賓女子大學：',
        content_12 : `工商管理博士、工商管理碩士（MBA）、工商管理碩士（非論文）、旅遊管理碩士、酒店管理博士、理學碩士（酒店與餐廳管理專業）、
 理學碩士（烹飪藝術專業）、公共管理碩士（MPA）、公共管理碩士（非論文）、理學碩士（資訊科技專業）、文學碩士（心理學專業）、教育碩士、教育碩士（英語專業） 、教育碩士（教育管理專業）、教育碩士（幼兒教育專業）、美術與設計碩士、音樂哲學博士、民族音樂學博士、音樂表演博士、音樂教育碩士、音樂表演碩士`,
        content_13 : '遠東大學：',
        content_14 :
          '工商管理碩士、護理碩士、心理學哲學博士、教育博士（課程與教學）、教育博士（教育管理）、教育博士（語言文學）、體育碩士教育碩士（課程與教學專業） 、教育碩士（教育管理專業）、教育碩士（英語教學專業）、文學碩士（教育行政管理專業）',
        content_15 : '東方大學：',
        content_16 : `工商管理博士（行銷專業）、工商管理博士（財務管理專業）、工商管理博士（人力資源管理專業）、工商管理博士（營運與供應鏈管理專業）、
 工商管理碩士（行銷專業）、工商管理碩士（財務管理專業）、工商管理碩士（人力資源管理專業）、工商管理碩士（營運與供應鏈管理專業）、
 工商管理碩士（創業專業）、旅遊與酒店管理碩士（帶論文）、旅遊與酒店管理碩士（不含論文）、公共管理碩士（帶論文）、公共管理碩士（不含論文）、圖書情報碩士、教育文學碩士（教育管理專業）、教育博士（教育管理與領導專業）、資訊科技碩士、會計碩士（論文）、會計碩士（不含論文）、建築管理碩士（含論文）、建築管理碩士（不含論文）`,
        content_17 : '永恆大學：',
        content_18 : `工商管理博士、工商管理碩士（人力資源管理專業，不含論文）、工商管理碩士（資訊科技管理專業）、教育哲學博士（教育管理專業）、
 教育哲學博士（科學教育專業）、教育哲學博士（英語專業）、教育哲學博士（特殊教育專業）、教育文學碩士（教育與領導專業）、
 教育文學碩士（幼兒發展）、教育文學碩士（教育管理）、教育文學碩士（英語）、教育文學碩士（菲律賓）、教育文學碩士（數學）、
 教育文學碩士（體育教育與運動）、教育文學碩士（社會科學）、教育文學碩士（特殊教育）、公共管理碩士（備註：有論文）、公共管理碩士（備註：不含論文）`,
        content_19 : '菲律賓理工大學：',
        content_20 : `工商管理博士（DBA）、工商管理碩士（MBA）、公共管理博士（DPA）、公共管理碩士（MPA）、經濟學碩士（MAE）、
 應用統計學碩士、教育管理博士（DEM）、教育管理碩士、心理學碩士（MP）、體育運動碩士（MPES）、圖書情報學碩士、工程管理博士、工程科學碩士（MSE）、工業工程與管理理學碩士（MSIEM）、建築管理理學碩士、通訊碩士（MC）、資訊科技碩士（MIT）`,
        content_21 : '菲律賓卡威迪國立大學',
        content_22 : `農業哲學博士（作為科學專業）、 農業哲學博士（農業科學專業）、 農業哲學博士（農業系統專業）、 教育哲學博士（教育管理專業）、
 管理哲學博士（管理學專業）、教育文學碩士（生物學專業）、教育文學碩士（滑雪專業） 、教育文學碩士（課程與教育學）、 教育文學碩士（中學教育專業）、教育文學碩士（小學教育專業）、 教育文學碩士（數學專業）、 農業碩士（動物科學專業）、農業碩士（作物保護專業） 、管理碩士（人力資源管理專業）、管理碩士（組織管理專業）`,
        content_23 : '同學在選擇專業時可以此作為參考，同時需多方面綜合考慮，選擇最適合自己且受益最大的專業。 '
      }
    },
    interaction_4 : {
      title   : '菲律賓布大孔子學院赴兩所華校推廣網絡孔子學院',
      content : {
        content_1 :
          '根據菲律賓《世界日報》消息，近日，菲律賓布拉卡大學孔子學院分別與菲律賓馬尼拉華校普濟學院及中西學院成功舉辦了“學好中國話，朋友遍天下”全球網絡孔子學院推廣活動。參加本次活動的兩校師生分別約百餘人。 ',
        content_2 :
          '活動伊始，普濟學院校長蔡莉莎和中西學院的校長蔡蕊沓分別致辭。布拉卡大學孔子學院中方院長李陽博士及菲方院長CECILIA A.GERONIMO博士也相繼對在場的師生就國家漢辦的漢語推廣計畫和菲律賓孔子學院設立概況做了簡短的介紹。 ',
        content_3 :
          '李陽向長久以來給予布拉卡大學孔子學院大力支持以及幫助的社會各界人士表示衷心的感謝。她鼓勵在場的師生積極參與孔子學院的各項漢語學習活動，並表示，菲中友誼源遠流長，孔子學院將盡力為菲律賓漢語教學與研究提供最直接、最有效的服務，為促進中菲友誼和文化交流再建新的橋。 ',
        content_4 :
          '在普濟學院和中西學院兩所華校的推廣活動分別歷時兩小時。期間布拉卡大學孔子學院中方教師就孔子學院總部支持的網絡孔院網站做了簡明扼要且全面的介紹，並以視頻短片的形式將全站58個頻道，一萬多個豐富的學習資源以濃縮的方式介紹給了現場師生，讓在場的師生們切身體驗了網絡孔院這個高品質的漢語學習平台。 ',
        content_5 :
          '來自布拉卡大學的3位本士漢語教師生動且活躍的漢語推廣活動更是讓在場的師生受到了莫大的鼓舞。喬一老師「你住在哪裡」的話題迎來了在場師生的陣陣掌聲。對許多華裔的學生來說，喬一老師的漢語也許說得還不及他們，但是，喬一老師學習漢語的熱情和毅力以及其取得的成績卻讓每位師生欽佩、感動。 ',
        content_6 :
          '李蘭老師囊括中國名人、服飾、飲食和風景名勝等多元素傳統文化教學遊戲讓學生在寓教於樂的過程中領略了中華傳統文化的博大精深。睿諾老師嫻熟的珠算教學也讓學生們再次溫習了古老的中國數學運算技巧的魅力。 ',
        content_7 : '此次推廣活動中，布拉卡大學孔子學院院長李陽也代表國家漢辦、網絡孔子學院總部為兩所學校捐贈了書籍、音響資料及有聲掛圖等漢語教學與學習資源'
      }
    },
    interaction_5 : {
      title   : '菲律賓華校與中國學校進行校際聯誼活動',
      content : {
        content_1 :
          '據南京市僑辦消息，近日，菲律賓計順​​菲華中學裡熱鬧非凡，該校與江蘇省南京市航空航天大學附屬中學締結友誼學校以來，接二連三地開展了多項學生互通交流活動，豐富了校園生活，促進了校園文化建設，更為孩子開拓視野、提升自我開闢了一條嶄新的道路。 ',
        content_2 : `兩校結為友誼學校以來，特別重視學生們的交流互通，想真正實踐菲律賓學生與中國學生一對一的交流。
 因此，國小4年級全體同學與中學部分同學參加了「我寫封信給朋友」的活動。他們採用抽籤的形式，與南航附中的孩子們結對，彼此寫信交流，互相認識。在信中，中國孩子介紹了自己的家鄉、學習生活、老師朋友和家庭，還有的介紹自己喜歡的運動、熱愛的活動、家鄉的風味小吃。 `,
        content_3 : `計順菲華的孩子們讀到這些熱情洋溢的來信，無比激動，也快樂地給朋友們回信，在信裡，他們表達了自己結識新朋友的愉快和欣喜，
 也向對方介紹了自己，自己的家庭和校園，介紹了美麗的千島之國菲律賓，介紹了他們的家園馬尼拉……還有許多孩子，
 在信裡附上了自己的照片，自己設計的圖畫，甚至是親手製作的小禮物。為了提升孩子的語言能力，互相學習，
 相互借鑒，信件均採用中文、英文雙語。通信，使中國孩子提高了自己的英文水平，也讓菲律賓孩子也豐富了自己的華語知識。
 最可貴的是，孩子終於體會到，華語是個可以真真切切拿來傳遞思想、表情達意、展現自我的語言工具。他們在結識新朋友的過程中，不知不覺也接觸了中國文化，感受到了世界的廣大無邊。這些來自異國，卻根植於內心的文化深深衝擊著他們的心靈，同時，更深入了他們對本土文化的理解與熱愛。 `
      }
    }
  }
}

import home from './home'
import institution from './institution'
import study_abroad_daily from './study_abroad_daily'
import study_abroad_interaction from './study_abroad_interaction'
import country from './country'
import study from './study'
import overseas_services from './overseas_services'
import medical_studies from './medical_studies'

export default {
  ...home,
  ...institution,
  ...study_abroad_daily,
  ...study_abroad_interaction,
  ...country,
  ...study,
  ...overseas_services,
  ...medical_studies
}

export default {
  appName     : '欢迎来到菲律宾留学官网，立誉出国留学服务机构',
  globalError : '发生错误，请联系技术人员！',
  navbar      : {
    title           : '立誉留学 梦在此扬帆',
    service_hotline : '服务热线：',
    language        : {
      tw : '繁體中文',
      cn : '简体中文'
    },
    list : {
      home                       : '首页',
      country_introduction       : '国家介绍',
      country_overview           : '国家概况',
      country_features           : '国家特色',
      country_system             : '国家制度',
      study_overview             : '留学概况',
      study_intro                : '留学简介',
      application_requirements   : '申请条件及材料',
      supported_schools          : '可协助办理之学校',
      study_costs                : '留学费用',
      authorization_certificate  : '学校授权证书',
      university_recommendations : '院校推荐',
      image_gallery              : '精彩图片',
      campus_scenery             : '校园美景',
      overseas_services          : '境外服务',
      medical_studies            : '医、牙留学'
    }
  },
  banner : {
    title   : '立誉留学',
    desc    : '留学不再迷惘，专业顾问陪您走！',
    know_us : '了解我们'
  },
  contact_us : {
    ace_office : 'ACE 办公室/宿舍',
    um_office  : 'UM 办公室/宿舍',
    title      : '联络我们 & 让我们开始留学之旅',
    firstname  : '名称',
    lastname   : '姓氏',
    message    : '讯息',
    submit     : '提交'
  },
  about_me : {
    title     : '菲律宾立誉留学顾问有限公司',
    content_1 : `菲律宾立誉留学顾问有限公司是由台湾、菲律宾、中国、韩国等四个地区留学专业人士联合创办的留学菲律宾的专业服务机构，
    总部设在菲律宾首都马尼拉，在台湾台北、台中、高雄，韩国首尔、釜山，中国上海、北京、湖南、山东、江苏等地设有办事处。
    公司在菲律宾马尼拉拥有五栋学生宿舍大楼（含学生餐厅）、两所语言学校、一家贸易公司、一家旅行社及两间韩国餐厅，
    公司拥有十二名以上专业留学咨询顾问及一百多名老师和服务人员，提供从留学前选择专业的辅导、留学中的课业辅导和生活照顾到毕业后的学历认证及就业辅导等完整的高质量服务。 `,

    content_2 : '​自1998年成立至今，凭借着良好的信誉、完善的服务和科学的管理， 得到菲律宾各院校、海外合作机构和留学生家长的好评，是每个留学生的最佳选择。 ',
    content_3 : '我们已经成功办理了来自台湾、中国和韩国的超过10000名学生（含短期培训及游学团）， 分别就读于菲律宾各类院校，学成归国后都得到相当好的就业机会及发展。 '
  },
  footer : {
    list : {
      contact_us : '联系我们',
      about_me   : '关于我们'
    },
    copyright           : '网站标识：Copy Right 2004 立誉留学顾问有限公司 All Rights Reserved.',
    registration_number : '网站备案号：沪ICP备17051041号-2'
  },
  study_abroad : {
    daily       : '留学每日园地',
    interaction : '留学互动'
  },
  institution_recommendation : {
    title     : '院校推荐',
    content_1 : '还在为选择哪所大学而烦恼？',
    content_2 : '我们为您精选菲律宾顶尖学府 涵盖[医学系]、[牙医系]，满足您对未来的无限憧憬。',
    content_3 : '立誉留学都能找到最适合您的求学之路。'
  },
  contact : {
    submitSuccess : '成功提交！',
    continue      : '继续填写新信息？'
  },
  redirect : {
    errorMessageTitle : '发生错误！'
  },
  formTemplate : {
    upload       : '上传文件',
    reset        : '重填',
    submit       : '确定提交',
    input        : '输入',
    choose       : '选择',
    beforeRemove : '确定移除',
    handleExceed : '最多上传一张，请先删除上一项',
    error        : '失败',
    cancel       : '取消'
  },
  form : {
    required            : '“{field}”为必填。',
    invalid_characters  : '仅能输入英文、数字、符号。',
    emailRequireMessage : '请输入邮箱',
    emailFormatMessage  : '邮箱格式错误',
    emailPlaceholder    : '请输入邮箱',
    imageSizeLimitMb    : '图片不得超过{limit}MB。',
    buttonConfirm       : '确定',
    search              : '搜索',
    clear               : '清除',
    back                : '返回'
  },
  errorPage : {
    403 : {
      p1 : '您没有权限访问该页面',
      p2 : '返回首页',
      p3 : '返回上一页'
    },
    404 : {
      p1 : '您访问的页面不存在！',
      p2 : '返回首页',
      p3 : '返回上一页'
    }
  }
}

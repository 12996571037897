import { createRouter, createWebHistory } from 'vue-router'
// import NProgress from 'nprogress'
// import 'nprogress/nprogress.css'
import { i18nInstance } from '@/plugins/i18n.js'
import basicRoute from '@/router/basic.js'
import homeRoute from '@/router/home.js'

let i18n = i18nInstance()
// NProgress.configure({ showSpinner : false })

const errorRoute = {
  path     : '/:pathMatch(.*)*', // 捕獲所有未匹配的路由
  redirect : '/404'
}

const routes = [...basicRoute, ...homeRoute, errorRoute]
const router = createRouter({
  history : createWebHistory(),
  routes,
  scrollBehavior (to, from, savedPosition) {
    // 如果有 hash，且是從其他頁面回到當前頁面
    if (to.hash) {
      const element = document.getElementById(to.hash.substring(1)) // 獲取目標元素
      if (element) {
        const offset = 60 // 偏移量，比如導航欄的高度
        const y = element.getBoundingClientRect().top + window.scrollY - offset // 計算位置

        return { top : y, behavior : 'smooth' } // 返回平滑滾動到目標位置
      }
    }

    // 如果是返回上一頁且有 savedPosition，保持原來的位置
    if (savedPosition) {
      return savedPosition // 返回保存的位置
    }

    // 總是滾動到頂部
    return { top : 0 }
  }
})

router.beforeEach((to, from, next) => {
  // NProgress.start()
  window.document.title = (to.meta.title ? to.meta.title + ' - ' : '') + i18n.global.t('appName')
  next()
})

// router.afterEach(() => {
//   NProgress.done()
// })

export default router

export default {
  country : {
    overview : {
      content_1 : '地理概况',
      content_2 :
        '菲律宾是位于赤道北端、亚洲东南部的群岛国家。北隔巴士海峡与中国台湾省遥遥相望，南和西南隔苏拉威西海、苏禄海以及巴拉巴克海峡与印度尼西亚、马来西亚相望，西滨南海，东临太平洋，海岸线全长18,533公里。 ',
      content_3 : `在7,107个岛屿中，命名的有2,800个，主要岛屿有11个，分为北部的吕宋岛、中部的米沙鄢群岛、南部的棉兰老岛，以及西南部的巴拉望岛和苏禄群岛。
        在11个主要岛屿中，吕宋岛是最大的，面积为104,688平方公里，约占全国总面积的35%；其次是棉兰老岛，面积为94,630平方公里，
        约占全国总面积的32%。菲律宾各岛的山地占全国土地总面积的四分之三以上，拥有50多座火山，其中10多座是活火山。 `,
      content_4 : `菲律宾属于热带海洋气候，特点是高温、多雨、湿度大和多台风。平均气温为27°C，平均湿度为76%；5月至10月为雨季，11月至翌年4月为旱季，
        最热的月份为5月。菲律宾群岛年平均降水量约为2,000毫米。 `,
      content_5 : `菲律宾的金属矿藏主要有铜、金、银、铁、铬、镍等20余种，钢的蕴藏量约为41亿吨，金约1.01亿吨，镍约16亿吨，铁约30亿吨，铬约2,000万吨。
        非金属矿藏有20多种，其中石油储量约3.6亿桶，地热资源预估有20.9亿桶原油标准能源。 `,
      content_6  : '森林面积1,250万公顷，拥有红木、樟木、桃花心木等名贵木材。菲律宾群岛的水产资源丰富，鱼类品种有名称的就达2,400多种， 已开发的海水和淡水渔场面积为2,080平方公里。 ',
      content_7  : '人口',
      content_8  : '菲律宾约有9,470万人口，并以每年2.32%的速度增长。联合国在1998年发表的一份报告中将菲律宾列为世界上人口最稠密的国家之一。 ',
      content_9  : '马来族占85%以上，包括他加禄人、伊洛戈人、邦班牙人、比萨亚人和比科尔人等，少数民族有穆斯林、华人、印尼人、阿拉伯人、印度人、西班牙人和美国人，还有少数土著民族。 ',
      content_10 : '语言',
      content_11 : '菲律宾有70多种语言，国语是以他加禄语为基础的菲律宾语，通用语言为英语，官方语言为英语和菲律宾语。 ',
      content_12 : '宗教',
      content_13 : '居民多信奉天主教，少数人信奉基督教新教、伊斯兰教和佛教等其他宗教。 ',
      content_14 : '首都',
      content_15 : `首都马尼拉位于吕宋岛西岸、马尼拉湾畔，跨越帕西格河两岸，是全国最大的城市及经济、文化、交通中心。 400多年前，
        西班牙殖民者在马尼拉湾登陆，发现海滨长满如绒毯般柔软的细叶短草，便随口起名“马尼拉”，意思是“长满尼拉草的地方”。
        西班牙统治长达350年，美国统治近50年，这对马尼拉的发展过程留下了深刻的影响，因此有人称它为“亚洲的纽约”。人口约165.5万。 `,
      content_16 : `自东向西注入马尼拉湾的帕西格河横贯整座城市，沿河两岸为老城区，多为西班牙风格的建筑，有圣奥古斯丁教堂、圣地亚哥堡等古迹。
        著名的罗哈斯大道北接港区，南连国际机场，贯穿全市最繁华的街区，全长10公里，大道一侧临海，另一侧是形状各异、色彩鲜明的楼群，
        尤其是中段以西的一块填海造就的70公顷土地上，集中着国际会议中心、综合文化中心、国际贸易展览中心、椰子宫等马尼拉最具代表性的宏大建筑。 `,
      content_17 : `马尼拉最著名的名胜古迹包括长达10公里的罗哈斯滨海大道、面对马尼拉湾的黎刹公园（原名伦尼踏公园）、
        坐落在马尼拉湾的盆型建筑菲律宾文化园；建于1571年的圣奥古斯丁天主教堂；建于1571年的宏伟华丽的马尼拉卡思德拉尔天主教堂；
        建于1762年且颇负盛名的马尼拉郊外拉斯皮纳斯教堂；位于马尼拉市北部的商业中心王彬街（即唐人街）以及共和国最高行政中心总统府所在地马拉卡南宫。 `,
      content_18 : '旅游概况',
      content_19 : '菲律宾这座“千岛之国”，自然景观十分美丽，异国情调浓郁，加之东西方文化与古老民风民俗的融合，使这个岛国蒙上了一层神秘的面纱，愈加吸引游人。 ',
      content_20 : '棉兰老河',
      content_21 : '全长400公里，是菲律宾第一大河。卡加延河是菲律宾第二大河，全长352公里，吕宋岛上的内湖是全国最大的湖泊，长48公里，宽约40公里。 ',
      content_22 : '棉兰老岛的阿波火山',
      content_23 : '海拔2,953米，是菲律宾最高的火山。吕宋岛东南部的马荣火山，海拔2,416米，是菲律宾最大的火山。 ',
      content_24 : '吕宋岛西南部的塔尔火山',
      content_25 : '，是地球上最低和最小的火山。 ',
      content_26 : '菲律宾群岛的萨马岛和棉兰老岛',
      content_27 : '以东80公里的“菲律宾海沟”深度超过7,000米，最深处达10,497米，是世界上最深的海沟之一。 ',
      content_28 : '巴纳韦高山梯田',
      content_29 : `是菲律宾的一个古代奇迹，位于吕宋岛北部伊富高省巴纳韦镇附近，
        这里有2,000多年前菲律宾伊富高民族在海拔1,500米以上修建的古代稻梯田。梯田面积最大的为1/4公顷，最小的仅4平方米。
        用石块修成的梯田，外壁最高约达4米，最低不到2米。盘山台阶似的灌溉渠层层升高，总长度达1.9万公里。 `,
      content_30 : '塔尔湖',
      content_31 : `位于吕宋岛西南部八打雁省境内的大雅台东南山脚下，湖长20多公里，宽约15公里，湖水碧波粼粼，湖的正中央就是闻名遐迩的“塔尔火山岛” 。
        这座火山是菲律宾最低、四周被湖水环绕的火山，最高处也只有海拔300米。火山中间有一火山湖，又称“火山口”，
        面积约1平方公里，形成湖中有山、山中有湖的美丽景观。在菲律宾50多座火山中，塔尔火山是其中地势最低、最为活跃的火山，
        亦是世界上地势最低的火山。四五百年间，塔尔火山已爆发40余次`
    },
    features : {
      title_1   : '[国名]',
      title_2   : '[国旗]',
      title_3   : '[国徽]',
      title_4   : '[国歌]',
      title_5   : '[国服]',
      title_6   : '[国花]',
      title_7   : '[国树]',
      title_8   : '[国果]',
      content_1 : `菲律宾共和国 (Republic of the Philippines)。菲律宾在很早以前，是以吕宋、麻逸、苏禄、胡洛等地的名称闻名的。
        1521年葡萄牙航海者麦哲伦奉西班牙殖民主义者之命踏上这个群岛时，正好是天主教宗教节日，于是就为群岛起了一个有宗教意义的名称——圣拉哈鲁群岛。
        后来因为麦哲伦干涉岛上内争被当地人民杀戮，这个名称也就被人们遗忘。 1542年，西班牙航海家洛佩兹继麦哲伦之后第二个来到这个群岛。
        为了在亚洲炫耀西班牙帝国的“功绩”，便按照西班牙皇太子菲利普的名字，把群岛命名为菲律宾群岛。 1898年6月，菲律宾人民推翻西班牙殖民者的统治，
        宣布独立，将国名改为菲律宾共和国。 1946年7月，菲律宾摆脱美国的殖民统治，宣布独立，国名仍称为“菲律宾共和国”。 `,
      content_2 : '[国庆] 6月12日 (1898年)',
      content_3 : `菲律宾共和国国旗是在反抗西班牙殖民统治、争取自由和独立的斗争中制定的。呈横长方形，长与宽之比为2:1。
        靠旗杆一侧为白色等边三角形，中间是放射着八束光芒的黄色太阳，三角形的每个角落各有一颗黄色的五角星。旗面右边是红蓝两色的直角梯形，
        两色的上下位置可以调换。平时蓝色在上，战时红色在上。蓝色象征忠诚、正直，红色象征勇气，白色等边三角形象征和平和纯洁。三角形里的太阳代表自由，
        表示阳光普照全国。八道较长的光线代表1896年最先拿起武器反抗西班牙统治的八个省，其余的光线代表其他各省。 三颗五角星象征菲律宾群岛的三大区域——吕宋、米沙鄢和棉兰老。 `,
      content_4 : `菲律宾共和国的国徽呈盾形，中央是太阳放射光芒图案，三颗五角星在盾面上部，其寓意同国旗。左下方为蓝地黄色的鹰，
        右下方为红地黄色狮子，盾徽下面的白色绶带上用英文写着“菲律宾共和国”。盾形国徽的图案代表菲律宾的三个历史时期，
        即西班牙殖民统治时期、美国殖民统治时期和菲律宾共和国时期。国徽的上方和中部代表菲律宾共和国时期，底色都是白色，
        上方有三个金黄色的五角星，代表菲律宾群岛的三大区域——吕宋、米沙鄢和棉兰老。中部是有八道金黄色辐射线的太阳，表示阳光普照全国。
        国徽的左下方代表美国殖民统治时期，蓝色的底面上绘有一只向左看的金黄色的美国秃鹰，左爪握着橄榄枝，表示和平；右爪握着三支矛，
        表示随时准备战斗，以保卫和平。国徽的右下方代表西班牙殖民统治时期，红色的底面上绘有一只跃立的金黄色狮子，
        这是采用当时西班牙王国国旗上的狮子图形。狮子和鹰图案分别为在西班牙和美国殖民统治时期菲律宾的标志，象征菲律宾摆脱殖民统治、 获得独立的历史进程。 `,
      content_5 : `菲律宾共和国的国歌是《菲律宾民族进行曲》。歌词作者为何塞·帕尔马。 1898年美国侵略军侵占马尼拉后，
        菲律宾人民在抗战派领袖阿·马比尼和安·卢纳将军的领导下继续英勇抗战。当时，何塞·帕尔马在卢纳将军创办的《独立报》报社当记者，
        亲身经历了中吕宋激烈的抗美战争。他于1899年利用原菲律宾著名钢琴师和作曲家胡连·菲利佩于1898年6月谱成的《马达洛菲律宾进行曲》的曲调，
        以西班牙文填词，成为菲律宾国歌。他创作的菲律宾国歌歌词，充满了反抗侵略、向往自由和热爱祖国的激情。 `,
      content_6 : `菲律宾男子的国服叫“巴隆他加禄”衬衣。这是一种丝质紧身衬衣，长可及膝，领口如同一般可以系领带的衬衫，长袖，袖口如同西服上装。
        前领口直到下襟两侧，都有抽丝镂空图案，花纹各异，颇为大方。据说，在西班牙人统治时期，为了便于从远处区别西班牙人和菲律宾人，
        殖民者下令所有菲律宾人必须把衬衣穿在外面，不许把衬衣下摆扎在裤内。后来，菲律宾人开始在衬衣上刺绣各种图案，以此表示菲律宾人的自豪。
        50年代初，这种服装被正式推为菲律宾男子的国服，成为外交场合、庆祝活动和宴会的正式礼服。菲律宾女子的国服叫“特尔诺”。
        这是一种圆领短袖连衣裙，由于它两袖挺直，两边高出肩稍许，宛如蝴蝶展翅，所以也叫“蝴蝶服”。这种服装结合了许多西欧国家，
        特别是西班牙妇女服装的特点，并经过三四百年的沿革，而成为菲律宾妇女的国服。 `,
      content_7 : `菲律宾国花是被称为“桑巴吉塔”的茉莉花。这是一种灌木类植物，一般高一米多，花色洁白、香味浓郁，有单瓣和双瓣两种。
        据说，古代菲律宾男子向他心爱的姑娘求婚时，一般都赠送茉莉花花环。如果姑娘将花环挂在脖子上，就意味着接受了他的爱。
        然后，他们在月光下用他加禄语誓约：“桑巴吉塔”（我答应永远爱你）。
        因此，茉莉花在菲律宾又称之为“誓爱花”。 “桑巴吉塔”是忠于祖国、忠于爱情的象征。菲律宾青年常常将它作为献给爱人的礼物，
        向对方表达“坚贞于爱情的心声”。每到鲜花盛开的5月，姑娘们都佩带上茉莉花环，唱起赞歌，互相祝愿。在国际交往中， 菲律宾人也常把茉莉花环献给外国贵宾，以表示纯真的友谊。 `,
      content_8 : `菲律宾的国树是纳拉树，它是紫檀木的一种。这种树高大挺拔，终年常绿，迎着太阳开放出金光灿烂的花朵。
        木质坚硬细致，是制作高级家具和乐器的良好材料。纳拉树的树皮在受伤时会渗出一种猩红色的液体。菲律宾人说，这象征自己民族血管里流动着的，
        而又随时准备为捍卫独立洒在祖国大地上的鲜血。这种树可作为染料和制药原料。菲律宾人民选择纳拉树作为国树， 以象征本民族坚强不屈、乐于献身的高尚品质。 `,
      content_9  : '香飘遐迩、名闻世界的水果——芒果，是菲律宾的国果。它形呈椭圆，甜中带有微酸，味道有点儿像桃子。 成熟后呈黄色，异香扑鼻。芒果是菲律宾人民最喜欢的热带水果。 ',
      content_10 : '[国石] 珍珠',
      content_11 : '[国鸟] 菲律宾鹰'
    },
    system : {
      content_1 : '宪法',
      content_2 : '菲律宾现行宪法于1987年2月2日通过，2月11日由总统正式宣布生效',
      content_3 : '宪法规定',
      content_4 :
        '实行行政、立法、司法三权分立的政体。总统内阁制，总统是国家元首。政府首脑兼武装部队总司令。拥有行政权，由总统直接选举产生。任期为6年，不得连任。总统无权实施戒严法，无权颁国公，不得任意拘捕反对派。禁止军事干预政治，保障人权，政教分离。进行民主选举。 ',
      content_5  : '国会',
      content_6  : '国会是最高的立法机构，由参众两院组成，最高法院拥有司法权。 ',
      content_7  : '行政区别',
      content_8  : '全国划分为13个大区，73个省，2个特区和60个市。 ',
      content_9  : '司法机构',
      content_10 : `奉行和平、中立、互尊的外交政策。在平等、和平、互尊的基础上发展同所有国家的关系，
        注重与周边国家的关系。奉行对美、日友好关系。积极发展同东盟及其他国际组织间的关系，加强同第三世界国家的关系，是东盟组织成员国，
        积极推动国家的三大目标是，推动国家发展，保护海外菲人。菲律宾开发效力，积极参与国际和区域事务，努力改变和推动菲律宾国内外上的形象。
        根据1996年公布，同124个国家建立外交关系。 `,
      content_11 : '产业',
      content_12 : `第三产业比重较大，产值约占GDP 的一半，第一、二产业在国民经济中也占有相当重要的地位，六十年代后期采取经济开放政策，积极吸引外资，经济发展取得一定成效，1982
        年被世界银行列为“中等收入国家”。此后受西方经济衰退和自身政局动荡影响，经济呈下滑趋势，90
        年代初拉莫斯总统执政后，为振兴经济采取了一系列积极措施，经济开始全面复苏，并保持较高增长速度。 1997 年亚洲金融危机期间，菲经济受到的影响相对较小，1999
        年，菲经济逐渐开始恢复，2000 年，由于政局动荡，菲金融形势一度恶化，汇率创历史新低，股市亦跌至两年来最低水平。 2001
        年阿罗约总统执政后，推进经济改革，重点提高农业生产力，受全球经济减速和政局动荡的影响，菲仅保持缓慢增长，经济走势低迷，外贸下滑，外来投资减少，政府债务负担加重，贫困问题仍较突出。 `,
      content_13 : '矿业',
      content_14 : `主要有铜、金、银、铁、铬、镍等20 余种，铜蕴藏量约37.16 亿吨，金1.36 亿吨，镍1.27 亿吨，有丰富的地热资源，预计有20.9 亿桶原油标准能源。 1976
        年以来在巴拉望岛西北部海域发现石油，储藏量为 3.5 亿桶。森林面积 1250 万公顷，覆盖率约 40%，工矿业总产值占国民生产总值 31.9%，从业人口占总劳力
        15.6%。制造业约占工矿业总产值的 78.5%，建筑业约占 17.5%，矿产业约占 3%，2001 年工矿业产值为 248 亿美元，比 2000 年增长 1.9%。 `,
      content_15 : '农业',
      content_16 :
        '菲律宾是个农业国，农业人口占总人口的2/3以上，主要粮食作物是稻谷和玉米。椰子、甘蔗、马尼拉麻和烟草是菲律宾的四大经济作物，值得菲律宾人引以自豪的当属水果生产，菲律宾盛产椰子，香蕉、芒果、菠萝，其中椰子产量和出口量均占全世界总产量和出口量的六成以上。 ',
      content_17 : '工业',
      content_18 : '菲律宾的工业以农、林产品的加工为主，另有一些纺织、水泥、汽车装配等工业。铜是主要矿产，有丰富的地热资源，预计有 20.9 亿桶原油标准能源',
      content_19 : '交通',
      content_20 : `交通运输以公路运输为主，铁路不发达，均集中在吕宋岛，航空运输主要由国家航空公司经营，全国各主要岛屿间都有航班，全国有机场有 300
        多个，主要机场有尼诺·阿基诺国际机场，宿雾市的马克丹国际机场和达沃机场等。 `,
      content_21 : '港口',
      content_22 : '主要港口为马尼拉、宿雾、怡朗、三宝颜等。 ',
      content_23 : '[货币名称]比索(Peso)',
      content_24 : `宪法规定，中小学实行义务教育。政府重视教育，2002 年教育预算为 1053 亿比索，占政府预算开支的 13.48%，鼓励私人办学，为私立学校提供长期低息贷款，并免征财产税。 1994
        年全国识字率为 93.5%。 1996/1997 学年，全国有小学 37721 所，中学 63695 所，高等学院 1316 所；在校生中，小学生 1150.48 万，中学生 488.35 万，大学生 222.09
        万。著名高等院校有菲律宾大学、安德雷尔大学、东方大学、远东大学、圣托马斯大学等。 `,
      content_25 : '菲律宾国民教育实行从学前教育到高等教育的一套完善教育体制，即：',
      content_26 : '学前教育 (2-3 年)',
      content_27 : '小学教育(7 年)',
      content_28 : '中学教育 (4 年)',
      content_29 : '普通大学生课程 (3-5 年)',
      content_30 : '研究生课程 (1-5 年)',
      content_31 : `所有小学、中学均设有全国通考制度。在菲律宾，教育是政府的责任，政府对小学 (7 年) 和中学 (4 年) 的 11 年教育实行免费教育，但不是强制性义务教育。从儿童按 7
        岁入小学算起，一直到大学毕业取得学士学位时的年龄通常在 22 岁左右。 `,
      content_32 : '高等教育机构可分为两大组成部分：',
      content_33 : '公立高等教育机构 —— 国立大学、国立学院、国立高等学院或研究所',
      content_34 : '私立高等教育机构 —— 私立大学、私立学院、外国大学分校',
      content_35 : '在高等教育方面，私立教育机构扮演了非常重要的角色，为国内外学生提供了广泛的教育机会。目前有超过 20,000 名外国学生分别在菲律宾国立或私立高等学府就读。 '
    }
  }
}

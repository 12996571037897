export default {
  medical_studies : {
    content_1 : '菲律宾医学院简介',
    content_2 : `菲律宾医学科系八年学成后为临床医学博士学位，其中分为预医学系（四年）和正医学系（四年）两个阶段。
  预医是指大学部医学相关科系的学士学位，正医则为临床医学博士学位，医学博士即有资格考美国职业医师执照。 `,
    content_3 : '医学相关科系（预医科系）',
    content_4 : '可直接申请就读医学院正医学系的预医科系，一般与医学相关的科系，如医技、药学、复健、生物、护理、放射、公共卫生、医管、兽医等等学士学位科系。 ',
    content_5 : '就读正医科系的基本条件',
    content_6 :
      '具备医学相关科系的学士学位；非相关医学科系的学士学位者，需补修医学系相关的基础课程一年，包括：数学9学分（包含代数、三角、统计）；化学10学分（包含一般化学、有机化学）；生物学15学分（包含植物学、动物学）；物理学5学分，以下俗称39学分。 ',
    content_7 : '通过菲律宾医学院入学资格考试（NMAT）',
    content_8 : '申请就读医学院',
    content_9 :
      '一般在申请学校前，应先报考菲律宾医学院入学资格考试（NMAT）。但若因申请时间太晚，则可向原毕业学校申请英文版毕业证书及成绩单，经申请学校学分评估，认可后直接申请就读正医学系一年级，依各校规定不同，NMAT可延后参加。 ',
    content_10 : '菲律宾医学院入学资格考试 NMAT',
    content_11 : 'NMAT- National Medical Admission Test，即菲律宾医学院入学资格考试 考试报名及其条款：',
    content_12 : '大学或学院（医学相关科系）三年级以上学生、三年制大专毕业或在学最后一年者可报名参加考试。 ',
    content_13 : '须准备英文毕业（在读）证明和在校成绩单、护照及照片（3张2寸）。 ',
    content_14 : '报名并购买报名简章（本公司在菲办理）。 ',
    content_15 : '每年会考两次，约十月、二月中旬前报名：十二月、四月中旬的星期日考试，考试时间为一天，约六周后公布成绩。 ',
    content_16 : '考试科目： Verbal、Inductive Reasoning、Quantitative、Perceptual Acuity、Biology、Physics、Social Science、Chemistry，共八门。 ',
    content_17 : 'NMAT会考的成绩单须保存到医学院毕业，因回国认证学历时必备，如成绩不理想的可以复考多次，取最高分数。 ',
    content_18 : '美、加等地的学生可直接在美国纽约或旧金山考场报名考试，每年十月及十二月考试，前两周截止报名。 ',
    content_19 : '如UST、FEU部分名校，学校医学院独立的招生入学考试安排在1~3月，申请名校就读的学生需参加十二月NMAT的考试。 ',
    content_20 : '申请人资格',
    content_21 : '医学相关大学本科生：',
    content_22 : '医学相关科系学生，可以直接申请就读正医科，并可同时报考NMAT。 ',
    content_23 : '非医学相关大学本科生：',
    content_24 : '提供英文的成绩单，给医学院评估，非医学相关科系须补修39学分，并可同时报考NMAT。 （若成绩单中有科目与医学科系有相关性的，即可抵免部分学分）。 ',
    content_25 : '三年制大专生：',
    content_26 : '先完成学士学位（预医）资格才可就读正医。相关科系约需一年半至两年，非相关科系约需两年至两年半。补修学士学位中含39基础学分，并可同时报考NMAT。 ',
    content_27 : '在读、大专肄业生：',
    content_28 : '申请转学菲律宾医学院预医学大三或大四。预医学系毕业后再就读正医学系。 ',
    content_29 : '高中毕业生：',
    content_30 :
      '申请就读医学院预医学系，预医三年级以上即可报考NMAT，预医毕业后再进入正医学系就读。以高中生而言，就读医学系的毕业年限为八年（预医学、正医学各四年），部分学校预医学每年参加暑修，三年即能毕业。 ',
    content_31 : '初中毕业、高中肄业生',
    content_32 : '申请转学菲律宾高中就读，一般初中毕业生补读三年高中，高一完成补读两年，高中毕业后直接申请就读医学院预医学，毕业后再就读正医学。 ',
    content_33 : '备注：',
    content_34 : '临床医学预科阶段攻读医学相关学系专业，如药学、护理学、生物学等等。 ',
    content_35 : '毕业后可拿医学相关学系学士学位，随后决定是否继续读临床医学正科。 ',
    content_36 : '临床医学博士毕业后，可以考美国医师执照。 ',
    content_37 : '预医科+正医科',
    content_38 : '预医四年、正医四年 总计八年',
    content_39 : '预计花费',
    content_40 : '了解更多',
    content_41 : '预医 学费5,800 CNY/学期',
    content_42 : '正医学费14,000 CNY/学期',
    content_43 : '食宿费1,730 CNY/月',
    content_44 : '证件 首年4,000 CNY',
    content_45 : '(学签、体检、居住证)',
    content_46 : '第二年起1,000 CNY 年',
    content_47 : '预医奉献金5,000 CNY',
    content_48 : '正医奉献金13,000 CNY',
    content_49 : '正医科',
    content_50 : '四年',
    content_51 : '预计花费',
    content_52 : '了解更多',
    content_53 : '正医学费14,000 CNY/学期',
    content_54 : '食宿费1,730 CNY/月',
    content_55 : '证件 首年4,000 CNY',
    content_56 : '(学签、体检、居住证)',
    content_57 : '第二年起1,000 CNY年',
    content_58 : '正医奉献金13,000 CNY',
    content_59 : '医师执照',
    content_60 : '中国医师执照：',
    content_61 : '医师资格考试的性质是行业准入考试，是评价申请医师资格者是否具备从事医师工作所必须的专业知识与技能的考试。医师资格考试分实践技能考试和医学综合笔试两部分。 ',
    content_62 :
      '实践技能考试采用多站测试的方式，考区设有实践技能考试基地，根据考试内容设置若干考站，考生依次通过考站接受实践技能的测试。每位考生必须在同一考试基地的考站进行测试。 ',
    content_63 :
      '医师资格考试医学综合笔试于9月中旬举行，具体时间以卫生部医师资格考试委员会公告时间为准，执业医师考试时间为2天，分4个单元；执业助理医师考试时间为1天，分2个单元，每单元均为两个半小时。 ',

    content_64 : '美国医师执照',
    content_65 : `美国医师执照考试被全世界公认，医学院的学生正在医学系二年级以上，即可在菲律宾当地报考美国执照第一阶段基础医学考试。正医学系三年级以上，即可在菲律宾当地报考美国执照第二阶段临床医学考试。
  每年的考试时间为第一阶段6月、10月；第二阶段3月、8月，第三阶段则须赴美参加考试。 `,
    content_66 : '大学部预医学系',
    content_67 : '药学',
    content_68 : '护理学',
    content_69 : '医学科学',
    content_70 : '生物化学',
    content_71 : '心理学',
    content_72 : '生物学',
    content_73 : '物理学',
    content_74 : '化学',
    content_75 : '职能治疗学',
    content_76 : '物理治疗学',
    content_77 : '放射技术学',
    content_78 : '医事技术学',
    content_79 : '动物学',
    content_80 : '医学院名校碌',
    content_81 : '备注：有"★"的学校是本公司保证入学的，且位于马尼拉市区的名校',
    schools    : {
      school_1  : '安琪大学 ★',
      school_2  : '德拉萨大学 ★',
      school_3  : '伊密里欧大学 ★',
      school_4  : '远东大学 ★',
      school_5  : '马尼拉中心大学 ★',
      school_6  : '圣刘易斯大学',
      school_7  : '圣路克斯医学院 ★',
      school_8  : '东方医学院 ★',
      school_9  : '圣汤玛士大学',
      school_10 : '菲律宾国立大学',
      school_11 : '维京大学'
    }
  }
}

export default {
  appName     : '​歡迎來到菲律賓留學官網，立譽出國留學服務機構',
  globalError : '發生錯誤，請聯絡技術人員！',
  navbar      : {
    title           : '立譽留學 夢在此揚帆​',
    service_hotline : '服務熱線：',
    language        : {
      tw : '繁體中文',
      cn : '简体中文'
    },
    list : {
      home                       : '首頁',
      country_introduction       : '國家介紹',
      country_overview           : '國家概況',
      country_features           : '國家特色',
      country_system             : '國家制度',
      study_overview             : '留學概況',
      study_intro                : '留學簡介',
      application_requirements   : '申請條件及材料',
      supported_schools          : '可協助辦理之學校',
      study_costs                : '留學費用',
      authorization_certificate  : '學校授權證書',
      university_recommendations : '院校推薦',
      image_gallery              : '精采圖片',
      campus_scenery             : '校園美景',
      overseas_services          : '境外服務',
      medical_studies            : '醫、牙留學'
    }
  },
  banner : {
    title   : '立譽留學',
    desc    : '留學不再迷惘，專業顧問陪您走！',
    know_us : '了解我們'
  },
  contact_us : {
    ace_office : 'ACE 辦公室/宿舍',
    um_office  : 'UM 辦公室/宿舍',
    title      : '聯絡我們 & 讓我們開始留學之旅',
    firstname  : '名稱',
    lastname   : '姓氏',
    message    : '訊息',
    submit     : '送出'
  },
  about_me : {
    title     : '菲律賓立譽留學顧問有限公司',
    content_1 : `菲律賓立譽留學顧問有限公司是由台灣、菲律賓、中國、韓國等四個地區留學專業人士聯合創辦的留學菲律賓的專業服務機構，
          總部設在菲律賓首都馬尼拉，在台灣台北、台中、高雄，韓國漢城、釜山，中國上海、北京、湖南、山東、江蘇等地設有辦事處。
          公司在菲律賓馬尼拉擁有五棟學生宿舍大樓（含學生餐廳）、兩所語言學校、一家貿易公司、一家旅行社及兩間韓國餐廳，
          公司擁有十二名以上專業留學諮詢顧問及一百多名老師和服務人員，提供從留學前選擇專業的輔導、留學中的課業輔導和生活照顧到畢業後的學歷認證及就業輔導等完整的高質量服務。`,

    content_2 : '​自1998年成立至今，憑藉著良好的信譽、完善的服務和科學的管理， 得到菲律賓各院校、海外合作機構和留學生家長的好評，是每個留學生的最佳選擇。',
    content_3 : '我們已經成功辦理了來自台灣、中國和韓國的超過10000名學生（含短期培訓及遊學團）， 分別就讀於菲律賓各類院校，學成歸國後都得到相當好的就業機會及發展。'
  },
  footer : {
    list : {
      contact_us : '聯繫我們',
      about_me   : '關於我們'
    },
    copyright           : '網站標籤：Copy Right 2004 立譽留學顧問有限公司 All Rights Reserved.',
    registration_number : '​網站備案號：沪ICP備17051041號-2'
  },
  study_abroad : {
    daily       : '留學每日園地',
    interaction : '留學互動'
  },
  institution_recommendation : {
    title     : '院校推薦',
    content_1 : '還在為選擇哪所大學而煩惱？',
    content_2 : '我們為您精選菲律賓頂尖學府 涵蓋[醫學系]、[牙醫系]，滿足您對未來的無限憧憬。',
    content_3 : '立譽留學都能找到最適合您的求學之路。'
  },
  contact : {
    submitSuccess : '成功送出！',
    continue      : '繼續填寫新訊息？'
  },
  redirect : {
    errorMessageTitle : '發生錯誤！'
  },
  formTemplate : {
    upload       : '上傳檔案',
    reset        : '重填',
    submit       : '確定送出',
    input        : '輸入',
    choose       : '選擇',
    beforeRemove : '確定移除',
    handleExceed : '最多上傳一張，請先刪除上一選項',
    error        : '失敗',
    cancel       : '取消'
  },
  form : {
    required            : '「{field}」為必填。',
    invalid_characters  : '僅能輸入英文、數字、符號。',
    emailRequireMessage : '請輸入email',
    emailFormatMessage  : 'Email 格式錯誤',
    emailPlaceholder    : '請輸入email',
    imageSizeLimitMb    : '圖片不得超過{limit}mb。',
    buttonConfirm       : '確定',
    search              : '搜尋',
    clear               : '清除',
    back                : '返回'
  },
  errorPage : {
    403 : {
      p1 : '您沒有權限訪問該頁面',
      p2 : '返回首頁',
      p3 : '返回上一頁'
    },
    404 : {
      p1 : '您訪問的頁面不存在！',
      p2 : '返回首頁',
      p3 : '返回上一頁'
    }
  }
}

export default {
  study_abroad_interaction : {
    interaction_1 : {
      title   : '本科申请菲律宾留学院校推荐',
      content : {
        content_1 : '随着菲律宾近年的经济不断发展，也踊跃出许多优质的大学，是许多学生留学的热门选择国家之一，那么本科申请菲律宾留学有哪些院校推荐？就让小编来为大家推荐一下！',
        content_2 : '本科申请菲律宾留学有哪些院校推荐',
        content_3 : '菲律宾大学',
        content_4 :
          '菲律宾大学是菲律宾规模最大的国立综合性大学。菲律宾排名居第一位，2021年QS亚洲榜排名第69名。菲律宾大学是第一个由菲律宾立法机构所建立的大学。于1908年创建。总共有56所学院并提供413个学位课程，拥有约4700个教职人员和约46000个学生。 在菲律宾大学没有对宗教的束缚和对服装的规章制度，崇尚一种自由的校园风气。',
        content_5 : '马尼拉雅典耀大学',
        content_6 : `菲律宾排名居第二位，2021年QS亚洲榜排名第135名。马尼拉雅典耀大学始建于1859年，是一所包括文学院、商学院、法学院等众多科系的综合性私立大学。1959年，获得颁发大学学位资格，
          而今大学不断扩大为包括文科学学院、商学研究所、法学院等多个独立学院在内的综合型大学。除此之外，就如大部分的菲律宾大学一样，拥有高中及小学。菲律宾现任总统贝尼尼奥·阿基诺三世曾经就读该校，并在从政前担任过该校的助理教授。`,
        content_7 : '德拉萨大学',
        content_8 : `菲律宾排名居第3位，2021年QS亚洲榜排名第166名。德拉萨大学创立于1911年，位于马尼拉的市区，目前大约有25.000名在校学生就读。
          是菲律宾国内第一位的私立院校，同时也是菲律宾知名的贵族学校。由于德拉萨在始建之初的目标就是成为菲律宾的贵族学校，因此，即使已经跨越百年，今天的德拉萨仍然是既有天赋又有钱的学生才能跨入的大学——这就是德拉萨大学在菲律宾人心中的形象，不仅是学术的象征，还是身份的象征。`,
        content_9  : '圣卡洛斯大学',
        content_10 : `菲律宾排名居第5位，2021年QS亚洲榜排名451-500名。菲律宾圣卡洛斯大学(University of San Carlos)，简称USC，始建于1565年，
          至今已有450多年的校史，是菲律宾也是亚洲最古老的私立贵族学府。圣卡洛斯大学有近40个本科专业和83个硕士和博士专业供考生挑选，是我国教育部留学服务中心认可的第一批“菲律宾正规高校”之一，同时也是我国驻菲律宾大使馆确认的第一批“有资质接收外国留学生菲律宾高校”之一。`,
        content_11 : 'Adamson University',
        content_12 : '中文名：亚当森大学',
        content_13 : `学校简介大学创立于1932年，创办之初是为了发展化学工业的需求，尤其针对菲律宾在化学领域的科学和技术。1941年2月，学校建立大学教育系。
          1964年12月作为天主教大学加入亚当森·奥扎南教育机构，专门致力于天主教教学的发展，广泛扩展学校，大量招生，尤其是针对贫困人群，并在全球天主教教学学术上有着杰出贡献。亚当森大学（Adamson University）是天主教Vincentian的教育机构， 是公认的优质教育（特别是针对处境不利者的优质教育）的领先中心。`,
        content_14 : `亚当森大学是一所天主教大学，位于马尼拉市中心。希腊化学家乔治·卢卡斯·亚当森（George Lucas Adamson）博士于1932年将其创建为亚当森工业化学学院。
          它在1941年成为一所大学，并在1964年，使团或文森特教派成为新的所有者和管理者。 亚当森大学的旗舰课程是工程学，但九个学院中所有课程的质量都通过各种认证， 其自主身份和ISO 9001：2015认证来表明。
          通过不断升级的设备和设施，教职员工的专业发展，加强研究和将宣传服务制度化，这些得以维持；但是，尽管素质教育有所改善，但负担能力仍然存在。亚当森大学通过招收国际学生， 与外国机构和组织的伙伴关系，东盟一体化以及在世界各地设立校友分会而走向全球。`,
        content_15 :
          'AdU在研究生院、法学院、文科学院、工程学院、护理学院、药学院、建筑学院、工商管理学院和教育学院以及中等教育、初等教育和预科教育均设有课程。其中有8门课程被菲律宾高等教育委员会列为发展中心和卓越中心，并已获得自主权利。',
        content_16 : 'Polytechnic University of the Philippines',
        content_17 : '中文名：菲律宾理工大学',
        content_18 : '类型：州立大学入学考试：必修（PUPCET）宗教信仰：非宗派学期结构：学期学年：六月开学',
        content_19 : '学校简介',
        content_20 :
          '菲律宾理工大学创立于1904年，是国家辅助并认可的高等教育学府。 菲律宾理工大学的总部坐落于经济繁华的首都城市马尼拉，目前在校人数大约50,000 可以说是菲律宾学生人数最多的国立大学。 大学有教学经验丰富及学术背景良好的教授。'
      }
    },
    interaction_2 : {
      title   : '菲律宾留学：适合的五类人群',
      content : {
        content_1 :
          '近来，菲律宾留学因其费用低廉、英语环境、美式教育等优势，成为中国工薪家庭留学的新宠儿。那么到底什么样的学生真正适合留学菲律宾呢？留学专家表示，适合去菲律宾留学的学生主要分为以下五类。',
        content_2 : '1、高中(职)或中专学历的学生：考不上大学的落榜生或只能考上国内三本或专科的学生。可以直接去菲律宾读一个重点大学热门本科专业。',
        content_3 : '2、四年本科生：对于本科专业不太好，想转专业的同学，可以直接赴菲换一个新专业攻读硕士，这样就业范围就扩大了很多。',
        content_4 : '3、三年专科毕业的学生：在评估后用最短的时间补修本科的学分之后获得菲律宾的本科文凭，本硕连读的话最快用3年时间获得硕士文凭。',
        content_5 : '4、具有硕士文凭的在职人员：因为工作的原因想进一步提高自己学位的人士，可以选择菲律宾院校的在职班学习，从而获得博士文凭。',
        content_6 : '5、游学：想提高自己的英语口语和听力，利用假期到菲律宾进行短暂的英语提高的学生，菲律宾院校有不同的语言课程供你选择。'
      }
    },
    interaction_3 : {
      title   : '菲律宾各大学的优势专业',
      content : {
        content_1 : '菲律宾圣保罗大学：',
        content_2 : `工商管理博士、工商管理硕士（酒店与餐饮管理专业）、工商管理硕士（组织发展专业）、工商管理硕士（信息系统专业）、
          教育哲学博士（教育领导与管理专业）、教育哲学博士（课程设计专业）、教育哲学博士（开发与监督专业）、教育学硕士（教育领导与管理专业）、音乐文学博士（重点学习：合唱指挥、作曲、音乐教育、管弦乐指挥和表演）、音乐文学硕士（重点学习：合唱指挥、作曲、音乐教育、管弦乐指挥和表演）`,
        content_3 : '菲律宾国父大学：',
        content_4 : `工商管理博士--Doctor inBusinessAdministration、公共管理博士--Doctor in PublicAdministration、
          教育博士 或 翻译为 教育学博士 --Doctor ofEducation、工商管理硕士、公共管理硕士、公共管理硕士（公共卫生专业）、
          教育文学硕士（教育管理专业）-MasterofArtsinEducation（SpecializationinEducationalAdministration）、
          教育文学硕士（语言教育专业）-MasterofArtsinEducation（SpecializationinLanguageEducation）、教育文学硕士（数学专业）-Master of Arts in Education（Specialization in Mathematics）、 
          教育文学硕士（特殊教育专业）-Master of Arts in Education（Specialization in SpecialEducation）`,
        content_5 : '菲律宾中央大学：',
        content_6 : `管理学博士（公共管理专业）、管理学博士（旅游和酒店管理专业）、管理学博士（企业管理专业）、教育学博士（行政管理专业）、文学硕士（英语语言文学专业）、
          文学硕士（英语文学专业）、计算机科学硕士（含论文）、计算机科学硕士（不含论文）、教育学硕士（行政管理专业）、教育学硕士（体育专业含论文）、教育学硕士（体育专业不含论文）、工商管理硕士（含论文）、工商管理硕士（不含论文）、工商管理硕士（旅游与酒店管理专业）`,
        content_7 : '雅典耀大学：',
        content_8 : `哲学博士（英语语言文学）、教育学硕士（行政学）、教育学硕士（教育管理）、经济学博士、经济学博士（本科直博）
          、经济学硕士心理学硕士（组织心理学专业）、心理学硕士（社会心理学专业）、心理学硕士（心理咨询专业）、心理学博士（组织心理学专业）、心理学博士（社会心理学专业）、心理学博士（心理咨询专业）、对外汉语教学硕士、电子工程硕士、计算机科学硕士、计算机科学哲学博士`,
        content_9 : '菲律宾科技大学：',
        content_10 :
          '土木工程硕士、土木工程硕士（岩土工程专业）、土木工程硕士（结构工程硕士）、理学硕士（电子工程专业）、理学硕士（通信工程专业）、工程硕士（土木工程专业）、管理学硕士、管理科学博士（教育学专业）、管理科学博士（职业指导专业）',
        content_11 : '菲律宾女子大学：',
        content_12 : `工商管理博士、工商管理硕士（MBA）、工商管理硕士（非论文）、旅游管理硕士、酒店管理博士、理学硕士（酒店与餐厅管理专业）、
          理学硕士（烹饪艺术专业）、公共管理硕士（MPA）、公共管理硕士（非论文）、理学硕士（信息技术专业）、文学硕士（心理学专业）、教育学硕士、教育学硕士（英语专业）、教育学硕士（教育管理专业）、教育学硕士（幼儿教育专业）、美术与设计硕士、音乐哲学博士、民族音乐学博士、音乐表演博士、音乐教育硕士、音乐表演硕士`,
        content_13 : '远东大学：',
        content_14 :
          '工商管理硕士、护理学硕士、心理学哲学博士、教育学博士（课程与教学）、教育学博士（教育管理）、教育学博士（语言文学）、体育硕士教育学硕士（课程与教学专业）、教育学硕士（教育管理专业）、教育学硕士（英语教学专业）、文学硕士（教育行政管理专业）',
        content_15 : '东方大学：',
        content_16 : `工商管理博士（市场营销专业）、工商管理博士（财务管理专业）、工商管理博士（人力资源管理专业）、工商管理博士（运营与供应链管理专业）、
          工商管理硕士（市场营销专业）、工商管理硕士（财务管理专业）、工商管理硕士（人力资源管理专业）、工商管理硕士（运营与供应链管理专业）、
          工商管理硕士（创业专业）、旅游与酒店管理硕士（带论文）、旅游与酒店管理硕士（不带论文）、公共管理硕士（带论文）、公共管理硕士（不带论文）、图书情报硕士、教育文学硕士（教育管理专业）、教育学博士（教育管理与领导专业）、信息技术硕士、会计学硕士（论文）、会计学硕士（不含论文）、建筑管理硕士（含论文）、建筑管理硕士（不含论文）`,
        content_17 : '永恒大学：',
        content_18 : `工商管理博士、工商管理硕士（人力资源管理专业，不含论文）、工商管理硕士（信息技术管理专业）、教育哲学博士（教育管理专业）、
          教育哲学博士（科学教育专业）、教育哲学博士（英语专业）、教育哲学博士（特殊教育专业）、教育文学硕士（教育与领导专业）、
          教育文学硕士（幼儿发展）、教育文学硕士（教育管理）、教育文学硕士（英语）、教育文学硕士（菲律宾语）、教育文学硕士（数学）、
          教育文学硕士（体育教育和运动）、教育文学硕士（社会科学）、教育文学硕士（特殊教育）、公共管理硕士（备注：有论文）、公共管理硕士（备注：不含论文）`,
        content_19 : '菲律宾理工大学：',
        content_20 : `工商管理博士（DBA）、工商管理硕士（MBA）、公共管理博士（DPA）、公共管理硕士（MPA）、经济学硕士（MAE）、
          应用统计学硕士、教育管理博士（DEM）、教育管理硕士、心理学硕士（MP）、体育运动硕士（MPES）、图书情报学硕士、工程管理博士、工程科学硕士（MSE）、工业工程与管理理学硕士（MSIEM）、建筑管理理学硕士、通信硕士（MC）、信息技术硕士（MIT）`,
        content_21 : '菲律宾卡威迪国立大学',
        content_22 : `农业哲学博士（作为科学专业）、 农业哲学博士（农业科学专业）、 农业哲学博士（农业系统专业）、 教育哲学博士（教育管理专业）、
          管理哲学博士（管理学专业）、教育文学硕士（生物学专业）、教育文学硕士（滑雪专业） 、教育文学硕士（课程与教育学专业）、 教育文学硕士（中学教育专业）、教育文学硕士（小学教育专业）、 教育文学硕士（数学专业）、 农业硕士（动物科学专业）、农业硕士（作物保护专业） 、管理学硕士（人力资源管理专业）、管理学硕士（组织管理专业）`,
        content_23 : '同学们在选择专业时可以此作为参考，同时需多方面综合考虑，选择最适合自己且受益最大的专业。'
      }
    },
    interaction_4 : {
      title   : '菲律宾布大孔子学院赴两所华校推广网络孔子学院',
      content : {
        content_1 :
          '据菲律宾《世界日报》消息，近日，菲律宾布拉卡大学孔子学院分别与菲律宾马尼拉华校普济学院及中西学院成功举办了“学好中国话，朋友遍天下”全球网络孔子学院推广活动。参加本次活动的两校师生分别约百余人。',
        content_2 :
          '活动伊始，普济学院校长蔡莉莎和中西学院的校长蔡蕊沓分别致辞。布拉卡大学孔子学院中方院长李阳博士及菲方院長CECILIA A.GERONIMO博士也相继对在场的师生就国家汉办的汉语推广项目和菲律宾孔子学院设立概况做了简短的介绍。',
        content_3 :
          '李阳向长久以来给予布拉卡大学孔子学院大力支持以及帮助的社会各界人士表示衷心的感谢。她鼓励在场的师生积极参与孔子学院的各项汉语学习活动，并表示，菲中友谊源远流长，孔子学院将尽力为菲律宾汉语教学与研究提供最直接、最有效的服务，为促进中菲友谊和文化交流再建新的桥梁。',
        content_4 :
          '在普济学院和中西学院两所华校的推广活动分别历时两小时。期间布拉卡大学孔子学院中方教师就孔子学院总部支持的网络孔院网站做了简明扼要且全面的介绍，并以视频短片的形式将全站58个频道，一万多个丰富的学习资源以浓缩的方式介绍给了现场师生，让在场的师生们切身体验了网络孔院这个高质量的汉语学习平台。',
        content_5 :
          '来自布拉卡大学的3位本士汉语教师生动且活跃的汉语推广活动更是让在场的师生受到了莫大的鼓舞。乔一老师“你住在哪儿”的话题迎来了在场师生的阵阵掌声。对于许多华裔的学生来说，乔一老师的汉语也许说得还不及他们，但是，乔一老师学习汉语的热情和毅力以及其取得的成绩却让每位师生钦佩、感动。',
        content_6 :
          '李兰老师囊括中国名人、服饰、饮食和风景名胜等多元素传统文化教学游戏让学生在寓教于乐的过程中领略了中华传统文化的博大精深。睿诺老师娴熟的珠算教学也让学生们再次温习了古老的中国数学运算技巧的魅力。',
        content_7 : '此次推广活动中，布拉卡大学孔子学院院长李阳还代表国家汉办、网络孔子学院总部为两所学校捐赠了书籍、音响资料及有声挂图等汉语教学与学习资源'
      }
    },
    interaction_5 : {
      title   : '菲律宾华校与中国学校开展校际联谊活动',
      content : {
        content_1 :
          '据南京市侨办消息，近日，菲律宾计顺菲华中学里热闹非凡，该校与江苏省南京市航空航天大学附属中学缔结友谊学校以来，接二连三地开展了多项学生互通交流活动，丰富了校园生活，促进了校园文化建设，更为孩子们开拓视野、提升自我开辟了一条崭新的道路。',
        content_2 : `两校结为友谊学校以来，尤为重视学生们的交流互通，想真正实践菲律宾学生与中国学生一对一的交流。
          因此，国小4年级全体同学与中学部分同学参加了“我给朋友写封信”的活动。他们采用抽签的形式，与南航附中的孩子们结对，彼此写信交流，相互认识。在信中，中国孩子介绍了自己的家乡、学习生活、老师朋友和家庭，还有的介绍自己喜欢的运动、热爱的活动、家乡的风味小吃。`,
        content_3 : `计顺菲华的孩子们读到这些热情洋溢的来信，无比激动，也快乐地给朋友们回信，在信里，他们表达了自己结识新朋友的愉快和欣喜，
          也向对方介绍了自己，自己的家庭和校园，介绍了美丽的千岛之国菲律宾，介绍了他们的家园马尼拉……还有许多孩子，
          在信里附上了自己的照片，自己设计的图画，甚至是亲手制作的小礼物。为了提升孩子们的语言能力，相互学习，
          相互借鉴，信件均采用中文、英文双语。通信，使中国孩子提高了自己的英文水平，也让菲律宾孩子也丰富了自己的华语知识。
          最可贵的是，孩子们终于体会到，华语是一个可以真真切切拿来传递思想、表情达意、展示自我的语言工具。他们在结识新朋友的过程中，不知不觉也接触了中国文化，感受到了世界的广大无边。这些来自异国，却根植于内心的文化深深冲击着他们的心灵，同时，更加深了他们对本土文化的理解与热爱。`
      }
    }
  }
}

export default {
  study : {
    study_intro : {
      content_1  : '一：留学原因',
      content_2  : '国内升学压力大，升学管道少。 ',
      content_3  : '国内英语学习环境受限。 ',
      content_4  : '父母想移民，让孩子先适应环境。 ',
      content_5  : '希望孩子能在自由发展的教育环境中成长。 ',
      content_6  : '培养孩子独立自主的个性及国际观、世界观的视野。 ',
      content_7  : '二：选择菲律宾的优势',
      content_8  : '免托福，手续简便、迅速，大专直升研究所，高中（职）、中专生可直升大学，学费低廉。 ',
      content_9  : '菲律宾是全球第三大英语系国家，各科系学生所修得的成绩单与学分美国及世界各国皆承认，是最快速、最简便、最好且唯一的跳板。 ',
      content_10 : '采用美式教学，所修得的文凭美国、加拿大皆承认，并可转赴美国、加拿大继续深造。 ',
      content_11 : '医技系、药学系、护理系、营养系、生物系、生命科学系、公卫系等毕业生进修的最佳选择。 ',
      content_12 : '学费低廉，有志者皆具经济能力前往深造。 ',
      content_13 : '拥有完善的英语教学环境，培养一流的英文能力。 ',
      content_14 : '每年有三次假期，大陆沿海城市至马尼拉飞行时间约3小时（往返便利）。 ',
      content_15 : '唯一最省钱的出国深造机会。 ',
      content_16 : '境外有马尼拉区口碑最佳、规模最大的服务机构，保障学生安全，让家长放心。 ',
      content_17 : '三：菲律宾大学简介',
      content_18 : `就读菲律宾大学、研究所的展望：在当今国际潮流中，高学历的硕士、博士人才已成为时势所需。
      前往菲律宾读大学的优势在于可免除国内考试制度的压力（以成绩决定就读科系），可依个人兴趣申请科系就读。免托福、亚洲唯一的英语系国家、学费低廉、生活费便宜、课程选修弹性化，并且其学历曾在国际上受到承认。此外，更能让学生在将来的就业市场上占有一席之地，享有高所得的居家生活。 `,
      content_19 : `在菲律宾大约有650所高等学校，其中包括7所公立大学、25所公立学院、35所私立大学以及超过550所私立学院，约有25个不同国籍的学生在菲律宾就读，包括中国、美国、韩国、中东地区等，这些学生占了大多数。
      一般来说，进入高等学校必须有高中毕业证书，各校独立招生均需考试和面试，但这并不保证可进入某特定学校，可能需要进一步的申请核准。 `,
      content_20 : '菲律宾大多数大学的开学日期一年有三次（4月、6月、11月中），若要入学者，请尽早于2个月以前提出申请，并确认申请日期，以便尽早入境菲律宾。 ',
      content_21 : '申请人资格',
      content_22 : '初中毕业者可先补读高中三年即可就读大学。 ',
      content_23 : '应届高中、职校、中专学生，免托福成绩，可直接升入大学。 ',
      content_24 : '具备大学、大专（三年制）毕业证书者可直接升入研究所；若为大学或大专肄业生，可申请补修学分后再升入研究所。 ',
      content_25 : '公私立教职员可利用暑假、寒假前往进修，取得学位（免托福、免请假、免离职，费用省）。 ',
      content_26 : '护校或护专毕业生可在菲律宾一边补修本科学历，一边培训美国境外护士考试，利用菲律宾优良的英语环境及低廉的费用，顺利通过考试后赴美国就业。 ',
      content_27 : '各校名额有限，证件申请亦需时间，请尽早于学期开始前3个月提出申请，以便保留名额，因各大学科系都有名额限制，且菲律宾教育部规定外国学生有10%的名额限制。 ',
      content_28 : '菲律宾学校学制介绍',
      content_29 :
        '学期时间：菲律宾大学（学期制）一年有2个学期及1个暑修，第一学期（6月10月）、第二学期（11月3月）、暑修（4月~5月），第一、二学期及暑修皆可申请入学，惟暑修因时间较短，上课时间为正常学期的一半，暑修规定每人只能修9-12个学分。 ',
      content_30 : `大陆与菲律宾的学制比较：由于菲律宾的学制为小学6年、中学（初中加高中）6年，共计12年；而大陆的小学5年、预备班1年、初中3年、高中3年，
      总计12年，因此大陆的高中、高职、中专毕业生均可直接申请大学。而且菲律宾部分高校认定三年以上的大专学历相当于本科毕业，因此大陆的大专毕业生在菲律宾被视为COLLEGE
      GRADUATE，这样大专及大学生可直接申请研究所（一般约1年6个月至2年可完成硕士课程）。 `
    },
    application_requirements : {
      content_1  : '申请人资格：',
      content_2  : '初中毕业者：完成三年高中课程后，即可申请就读大学。 ',
      content_3  : '应届高中、高职、中专学生：免托福成绩，可直接升入大学。 ',
      content_4  : '具备大学、大专（三年制）毕业证书者：可直接升入研究所；若为大学或大专肄业生，可申请补修学分后再升入研究所。 ',
      content_5  : '公私立教职员：可利用暑假、寒假前往进修，取得学位。 （免托福、免请假、免离职、费用省）',
      content_6  : '护校或护专毕业生：可到菲律宾一边补修本科学历，一边培训美国境外护士考试，利用菲律宾优良的英语环境及低廉的费用，顺利通过考试后赴美国就业。 ',
      content_7  : '各校名额有限，证件申请亦需时间，请尽早于学期开始前3个月提出申请，以便保留名额，因为各大学科系都有名额限制，且非国教育部规定各大学外国学生有10%的名额限制。 ',
      content_8  : '申请入学应备文件如下：（无护照者请先办理护照）',
      content_9  : '持最后学历之成绩单、学位证书：到当地学校管辖之公证处办理中英文公证（公证为一份正本一份副本）。请申请人优先办理此项。 ',
      content_10 : '申请人须到各地公安局办理无犯罪记录，然后到户籍管辖之公证处办理中英文公证（公证为一份正本一份副本），请申请人优先办理此项。 ',
      content_11 : '携带户口本到户籍管辖的公证处申请初始证明，并办理中英文公证（公证为一份正本一份副本）。请申请人优先办理此项。 （注明出生地及出生年月日）',
      content_12 : '彩色白底相片2吋6张。 （背面要写上与护照相同之中英文名字）',
      content_13 : '护照正本',
      content_14 : '体检表1份（含14寸X光片）。 （在菲律宾办理）申请人财力证明须以本人之帐户存款人民币5万元以上，开立金融机构之存款证明（英文版5份）。 ',
      content_15 : '支持书（英文版5份）',
      content_16 : '个人资料6份。 （由本公司处理）',
      content_17 : '校长或院长、教授或系主任的推荐信各2份。 （由本公司处理）',
      content_18 : '备注：',
      content_19 : '上述第一、第二、第三项文件在公证处公证之后，送外事办认证，再送菲律宾领事馆（上海、广州、区（门）或大使馆（北京））认证。 ',
      content_20 : '护照须到菲律宾领事馆或大使馆办理入境签证（本公司可代办或自行委托旅行社）。 ',
      content_21 : '以上两点完成后，将以上文件带齐即可赴菲律宾。 ',
      content_22 : '到菲律宾之后，由本公司协助在菲律宾体检并将入境签证转变成学生签证，正式取得学生居留权，学生即可持正式学生签证留在菲律宾就读。 '
    },
    supported_schools : {
      schools : {
        school_1  : '安琪大学',
        school_2  : '亚洲管理学院',
        school_3  : '马尼拉安德雷尔大学',
        school_4  : '宿雾医师群医学院',
        school_5  : '宿雾医学院',
        school_6  : '中央大学',
        school_7  : '中正学院',
        school_8  : '德拉萨大学',
        school_9  : '伊密里欧大学',
        school_10 : '远东大学',
        school_11 : '亚诺尼塔大学',
        school_12 : '马尼拉中心大学',
        school_13 : '奥莉维亚大学',
        school_14 : '基督大学',
        school_15 : '菲律宾国立师范大学',
        school_16 : '圣北达大学',
        school_17 : '圣斯伯斯汀大学',
        school_18 : '东南学院',
        school_19 : '宿雾西南大学',
        school_20 : '圣刘易斯大学',
        school_21 : '圣路克斯医学院',
        school_22 : '东方医学院',
        school_23 : '马尼拉大学',
        school_24 : '波比丘大学',
        school_25 : '圣汤玛士大学',
        school_26 : '东方大学',
        school_27 : '菲律宾国立大学',
        school_28 : '维萨亚斯大学',
        school_29 : '维京大学'
      },
      content_1  : '备注：以上所排列之学校是以英文字母排略，而非学校名次排列',
      content_2  : '文、法、商学院及研究所科系',
      content_3  : '「大学部科系」',
      content_4  : '建筑系',
      content_5  : '法文系',
      content_6  : '西班牙文系',
      content_7  : '英文系',
      content_8  : '日文系',
      content_9  : '企业管理系',
      content_10 : '饭店及餐厅管理系',
      content_11 : '公共行政管理系',
      content_12 : '经济系',
      content_13 : '发展经济系',
      content_14 : '观光系',
      content_15 : '基础教育系',
      content_16 : '中等教育系',
      content_17 : '特殊教育系',
      content_18 : '大众传播系',
      content_19 : '音乐系',
      content_20 : '「硕士班科系」',
      content_21 : '建筑系',
      content_22 : '英文系',
      content_23 : '法文系',
      content_24 : '西班牙文系',
      content_25 : '企业管理系',
      content_26 : '公共行政管理系',
      content_27 : '饭店及餐厅管理系',
      content_28 : '统计系',
      content_29 : '经济系',
      content_30 : '化学',
      content_31 : '心理',
      content_32 : '咨询',
      content_33 : '教育管理',
      content_34 : '图书馆系',
      content_35 : '合唱',
      content_36 : '音乐教育',
      content_37 : '音乐学',
      content_38 : '声乐',
      content_39 : '「博士班科系」',
      content_40 : '企业管理系',
      content_41 : '公共行政管理系',
      content_42 : '饭店及餐厅管理系',
      content_43 : '教育管理',
      content_44 : '统计系',
      content_45 : '经济系',
      content_46 : '理、工学院及研究所科系',
      content_47 : '「大学部科系」',
      content_48 : '水利资源系',
      content_49 : '工业工程系',
      content_50 : '环境科学系',
      content_51 : '气象学系',
      content_52 : '计算机科学系',
      content_53 : '化学工程系',
      content_54 : '计算机工程系',
      content_55 : '电子工程系',
      content_56 : '电子通讯工程系',
      content_57 : '机械工程系',
      content_58 : '运东科学系',
      content_59 : '「硕士班科系」',
      content_60 : '材料工程系',
      content_61 : '化学工程系',
      content_62 : '计算机科学系',
      content_63 : '电子工程系',
      content_64 : '环境工程系',
      content_65 : '工业工程系',
      content_66 : '机械工程系',
      content_67 : '水利资源系',
      content_68 : '应用数学',
      content_69 : '数学',
      content_70 : '生物',
      content_71 : '化学',
      content_72 : '物理',
      content_73 : '「博士班科系」',
      content_74 : '化学工程系',
      content_75 : '电子工程系',
      content_76 : '生物',
      content_77 : '化学',
      content_78 : '环境科学系'
    },
    study_costs : {
      plan : {
        university : {
          title : '大学部',
          year  : '一般四年，最快三年'
        },
        master : {
          title : '硕士班',
          year  : '一年六个月'
        },
        doctoral : {
          title : '博士班',
          year  : '二年(有些科系三年)'
        },
        estimated_cost : '预计花费',
        more           : '了解更多',
        tuition        : '学费{cost} CNY/学期',
        room_and_board : '食宿{cost} CNY/月',
        documents      : '证件{cost} CNY/年',
        donation       : '奉献金：USD {cost}(美金)'
      },
      content_1 : '以上费用不包含国内代办中介费、个人机票、水电费及零用钱。 ',
      content_2 : '房间为二人共享一室，若要一人一室，则每人每月需增加房租623CNY',
      content_3 : '学生所需证件(学位证书英文版，成绩单英文版、无犯罪纪录英文版、出生证明英文版)之公正处',
      content_4 : '公证及外事办认证、菲律宾大使馆认证、皆须在大陆地区办理',
      content_5 : '学生之护照及菲律宾签证皆须在大陆地区办理',
      content_6 : '上述证件费包含学生签证、体检、居留证、返菲证、签证延期等所有在学间须办理之证件费用。 ',
      content_7 : '以上费用视实际签约学校稍有变动'
    },
    authorization_certificate : {
      centro_escolar_university : '菲律宾中央大学',
      josé_rizal_university     : '菲律宾国父大学',
      ellimio_aguinaldo_college : '伊密里欧学院',
      university_of_manila      : '马尼拉大学',
      attorney                  : '​授权证书'
    }
  }
}

export default {
  country : {
    overview : {
      content_1 : '地理概況',
      content_2 :
        '菲律賓是位於赤道北端、亞洲東南部的群島國家。北隔巴士海峽與中國台灣省遙遙相望，南和西南隔蘇拉威西海、蘇祿海以及巴拉巴克海峽與印度尼西亞、馬來西亞相望，西濱南海，東臨太平洋，海岸線全長18,533公里。',
      content_3 : `在7,107個島嶼中，命名的有2,800個，主要島嶼有11個，分為北部的呂宋島、中部的米沙鄢群島、南部的棉蘭老島，以及西南部的巴拉望島和蘇祿群島。
          在11個主要島嶼中，呂宋島是最大的，面積為104,688平方公里，約占全國總面積的35%；其次是棉蘭老島，面積為94,630平方公里，
          約占全國總面積的32%。菲律賓各島的山地占全國土地總面積的四分之三以上，擁有50多座火山，其中10多座是活火山。`,
      content_4 : `菲律賓屬於熱帶海洋氣候，特點是高溫、多雨、濕度大和多台風。平均氣溫為27°C，平均濕度為76%；5月至10月為雨季，11月至翌年4月為旱季，
          最熱的月份為5月。菲律賓群島年平均降水量約為2,000毫米。`,
      content_5 : `菲律賓的金屬礦藏主要有銅、金、銀、鐵、鉻、鎳等20餘種，鋼的蕴藏量約為41億噸，金約1.01億噸，鎳約16億噸，鐵約30億噸，鉻約2,000萬噸。
          非金屬礦藏有20多種，其中石油儲量約3.6億桶，地熱資源預估有20.9億桶原油標準能源。`,
      content_6  : '森林面積1,250萬公頃，擁有紅木、樟木、桃花心木等名貴木材。菲律賓群島的水產資源豐富，魚類品種有名稱的就達2,400多種， 已開發的海水和淡水漁場面積為2,080平方公里。',
      content_7  : '人口',
      content_8  : '菲律賓約有9,470萬人口，並以每年2.32%的速度增長。聯合國在1998年發表的一份報告中將菲律賓列為世界上人口最稠密的國家之一。',
      content_9  : '馬來族占85%以上，包括他加祿人、伊洛戈人、邦班牙人、比薩亞人和比科爾人等，少數民族有穆斯林、華人、印尼人、阿拉伯人、印度人、西班牙人和美國人，還有少數土著民族。',
      content_10 : '語言',
      content_11 : '菲律賓有70多種語言，國語是以他加祿語為基礎的菲律賓語，通用語言為英語，官方語言為英語和菲律賓語。',
      content_12 : '宗教',
      content_13 : '居民多信奉天主教，少數人信奉基督教新教、伊斯蘭教和佛教等其他宗教。',
      content_14 : '首都',
      content_15 : `首都馬尼拉位於呂宋島西岸、馬尼拉灣畔，跨越帕西格河兩岸，是全國最大的城市及經濟、文化、交通中心。400多年前，
          西班牙殖民者在馬尼拉灣登陸，發現海濱長滿如絨毯般柔軟的細葉短草，便隨口起名“馬尼拉”，意思是“長滿尼拉草的地方”。
          西班牙統治長達350年，美國統治近50年，這對馬尼拉的發展過程留下了深刻的影響，因此有人稱它為“亞洲的紐約”。人口約165.5萬。`,
      content_16 : `自東向西注入馬尼拉灣的帕西格河橫貫整座城市，沿河兩岸為老城區，多為西班牙風格的建築，有聖奧古斯丁教堂、聖地亞哥堡等古跡。
          著名的羅哈斯大道北接港區，南連國際機場，貫穿全市最繁華的街區，全長10公里，大道一側臨海，另一側是形狀各異、色彩鮮明的樓群，
          尤其是中段以西的一塊填海造就的70公頃土地上，集中著國際會議中心、綜合文化中心、國際貿易展覽中心、椰子宮等馬尼拉最具代表性的宏大建築。`,
      content_17 : `馬尼拉最著名的名勝古跡包括長達10公里的羅哈斯濱海大道、面對馬尼拉灣的黎剎公園（原名倫尼踏公園）、
          坐落在馬尼拉灣的盆型建築菲律賓文化園；建於1571年的聖奧古斯丁天主教堂；建於1571年的宏偉華麗的馬尼拉卡思德拉爾天主教堂；
          建於1762年且頗負盛名的馬尼拉郊外拉斯皮納斯教堂；位於馬尼拉市北部的商業中心王彬街（即唐人街）以及共和國最高行政中心總統府所在地馬拉卡南宮。`,
      content_18 : '旅遊概況',
      content_19 : '菲律賓這座“千島之國”，自然景觀十分美麗，異國情調濃郁，加之東西方文化與古老民風民俗的融合，使這個島國蒙上了一層神秘的面紗，愈加吸引遊人。',
      content_20 : '棉蘭老河',
      content_21 : '全長400公里，是菲律賓第一大河。卡加延河是菲律賓第二大河，全長352公里，呂宋島上的內湖是全國最大的湖泊，長48公里，寬約40公里。',
      content_22 : '棉蘭老島的阿波火山',
      content_23 : '海拔2,953米，是菲律賓最高的火山。呂宋島東南部的馬榮火山，海拔2,416米，是菲律賓最大的火山。',
      content_24 : '呂宋島西南部的塔爾火山',
      content_25 : '，是地球上最低和最小的火山。',
      content_26 : '菲律賓群島的薩馬島和棉蘭老島',
      content_27 : '以東80公里的“菲律賓海溝”深度超過7,000米，最深處達10,497米，是世界上最深的海溝之一。',
      content_28 : '巴納韋高山梯田',
      content_29 : `是菲律賓的一個古代奇蹟，位於呂宋島北部伊富高省巴納韋鎮附近，
          這裡有2,000多年前菲律賓伊富高民族在海拔1,500米以上修建的古代稻梯田。梯田面積最大的為1/4公頃，最小的僅4平方米。
          用石塊修成的梯田，外壁最高約達4米，最低不到2米。盤山台階似的灌溉渠層層升高，總長度達1.9萬公里。`,
      content_30 : '塔爾湖',
      content_31 : `位於呂宋島西南部八打雁省境內的大雅台東南山腳下，湖長20多公里，寬約15公里，湖水碧波粼粼，湖的正中央就是聞名遐邇的“塔爾火山島”。
          這座火山是菲律賓最低、四周被湖水環繞的火山，最高處也只有海拔300米。火山中間有一火山湖，又稱“火山口”，
          面積約1平方公里，形成湖中有山、山中有湖的美麗景觀。在菲律賓50多座火山中，塔爾火山是其中地勢最低、最為活躍的火山，
          亦是世界上地勢最低的火山。四五百年間，塔爾火山已爆發40余次`
    },
    features : {
      title_1   : '[國名]',
      title_2   : '[國旗]',
      title_3   : '[國徽]',
      title_4   : '[國歌]',
      title_5   : '[國服]',
      title_6   : '[國花]',
      title_7   : '[國樹]',
      title_8   : '[國果]',
      content_1 : `菲律賓共和國 (Republic of the Philippines)。菲律賓在很早以前，是以呂宋、麻逸、蘇祿、胡洛等地的名稱聞名的。
          1521年葡萄牙航海者麥哲倫奉西班牙殖民主義者之命踏上這個群島時，正好是天主教宗教節日，於是就為群島起了一個有宗教意義的名稱——聖拉哈魯群島。
          後來因為麥哲倫干涉島上內爭被當地人民殺戮，這個名稱也就被人們遺忘。1542年，西班牙航海家洛佩茲繼麥哲倫之後第二個來到這個群島。
          為了在亞洲炫耀西班牙帝國的“功績”，便按照西班牙皇太子菲利普的名字，把群島命名為菲律賓群島。1898年6月，菲律賓人民推翻西班牙殖民者的統治，
          宣布獨立，將國名改為菲律賓共和國。1946年7月，菲律賓擺脫美國的殖民統治，宣布獨立，國名仍稱為“菲律賓共和國”。`,
      content_2 : '[國慶] 6月12日 (1898年)',
      content_3 : `菲律賓共和國國旗是在反抗西班牙殖民統治、爭取自由和獨立的鬥爭中制定的。呈橫長方形，長與寬之比為2:1。
          靠旗杆一側為白色等邊三角形，中間是放射著八束光芒的黃色太陽，三角形的每個角落各有一顆黃色的五角星。旗面右邊是紅藍兩色的直角梯形，
          兩色的上下位置可以調換。平時藍色在上，戰時紅色在上。藍色象徵忠誠、正直，紅色象徵勇氣，白色等邊三角形象徵和平和純潔。三角形裡的太陽代表自由，
          表示陽光普照全國。八道較長的光線代表1896年最先拿起武器反抗西班牙統治的八個省，其餘的光線代表其他各省。 三顆五角星象徵菲律賓群島的三大區域——呂宋、米沙鄢和棉蘭老。`,
      content_4 : `菲律賓共和國的國徽呈盾形，中央是太陽放射光芒圖案，三顆五角星在盾面上部，其寓意同國旗。左下方為藍地黃色的鷹，
          右下方為紅地黃色獅子，盾徽下面的白色綬帶上用英文寫著“菲律賓共和國”。盾形國徽的圖案代表菲律賓的三個歷史時期，
          即西班牙殖民統治時期、美國殖民統治時期和菲律賓共和國時期。國徽的上方和中部代表菲律賓共和國時期，底色都是白色，
          上方有三個金黃色的五角星，代表菲律賓群島的三大區域——呂宋、米沙鄢和棉蘭老。中部是有八道金黃色輻射線的太陽，表示陽光普照全國。
          國徽的左下方代表美國殖民統治時期，藍色的底面上繪有一只向左看的金黃色的美國禿鷹，左爪握著橄欖枝，表示和平；右爪握著三支矛，
          表示隨時準備戰鬥，以保衛和平。國徽的右下方代表西班牙殖民統治時期，紅色的底面上繪有一只躍立的金黃色獅子，
          這是採用當時西班牙王國國旗上的獅子圖形。獅子和鷹圖案分別為在西班牙和美國殖民統治時期菲律賓的標誌，象徵菲律賓擺脫殖民統治、 獲得獨立的歷史進程。`,
      content_5 : `菲律賓共和國的國歌是《菲律賓民族進行曲》。歌詞作者為何塞·帕爾馬。1898年美國侵略軍侵占馬尼拉後，
          菲律賓人民在抗戰派領袖阿·馬比尼和安·盧納將軍的領導下繼續英勇抗戰。當時，何塞·帕爾馬在盧納將軍創辦的《獨立報》報社當記者，
          親身經歷了中呂宋激烈的抗美戰爭。他於1899年利用原菲律賓著名鋼琴師和作曲家胡連·菲利佩於1898年6月譜成的《馬達洛菲律賓進行曲》的曲調，
          以西班牙文填詞，成為菲律賓國歌。他創作的菲律賓國歌歌詞，充滿了反抗侵略、嚮往自由和熱愛祖國的激情。`,
      content_6 : `菲律賓男子的國服叫“巴隆他加祿”襯衣。這是一種絲質緊身襯衣，長可及膝，領口如同一般可以繫領帶的襯衫，長袖，袖口如同西服上裝。
          前領口直到下襟兩側，都有抽絲鏤空圖案，花紋各異，頗為大方。據說，在西班牙人統治時期，為了便於從遠處區別西班牙人和菲律賓人，
          殖民者下令所有菲律賓人必須把襯衣穿在外面，不許把襯衣下擺紮在褲內。後來，菲律賓人開始在襯衣上刺繡各種圖案，以此表示菲律賓人的自豪。
          50年代初，這種服裝被正式推為菲律賓男子的國服，成為外交場合、慶祝活動和宴會的正式禮服。菲律賓女子的國服叫“特爾諾”。
          這是一種圓領短袖連衣裙，由於它兩袖挺直，兩邊高出肩稍許，宛如蝴蝶展翅，所以也叫“蝴蝶服”。這種服裝結合了許多西歐國家，
          特別是西班牙婦女服裝的特點，並經過三四百年的沿革，而成為菲律賓婦女的國服。`,
      content_7 : `菲律賓國花是被稱為“桑巴吉塔”的茉莉花。這是一種灌木類植物，一般高一米多，花色潔白、香味濃郁，有單瓣和雙瓣兩種。
          據說，古代菲律賓男子向他心愛的姑娘求婚時，一般都贈送茉莉花花環。如果姑娘將花環掛在脖子上，就意味著接受了他的愛。
          然後，他們在月光下用他加祿語誓約：“桑巴吉塔”（我答應永遠愛你）。
          因此，茉莉花在菲律賓又稱之為“誓愛花”。“桑巴吉塔”是忠於祖國、忠於愛情的象徵。菲律賓青年常常將它作為獻給愛人的禮物，
          向對方表達“堅貞於愛情的心聲”。每到鮮花盛開的5月，姑娘們都佩帶上茉莉花環，唱起贊歌，互相祝願。在國際交往中， 菲律賓人也常把茉莉花環獻給外國貴賓，以表示純真的友誼。`,
      content_8 : `菲律賓的國樹是納拉樹，它是紫檀木的一種。這種樹高大挺拔，終年常綠，迎著太陽開放出金光燦爛的花朵。
          木質堅硬細緻，是製作高級家具和樂器的良好材料。納拉樹的樹皮在受傷時會滲出一種猩紅色的液體。菲律賓人說，這象徵自己民族血管裡流動著的，
          而又隨時準備為捍衛獨立灑在祖國大地上的鮮血。這種樹可作為染料和製藥原料。菲律賓人民選擇納拉樹作為國樹， 以象徵本民族堅強不屈、樂於獻身的高尚品質。`,
      content_9  : '香飄遐邇、名聞世界的水果——芒果，是菲律賓的國果。它形呈橢圓，甜中帶有微酸，味道有點兒像桃子。 成熟後呈黃色，異香撲鼻。芒果是菲律賓人民最喜歡的熱帶水果。',
      content_10 : '[國石] 珍珠',
      content_11 : '[國鳥] 菲律賓鷹'
    },
    system : {
      content_1 : '憲法',
      content_2 : '菲律賓現行憲法於1987年2月2日通過，2月11日由總統正式宣布生效',
      content_3 : '憲法規定',
      content_4 :
        '實行行政、立法、司法三權分立的政體。總統內閣制，總統是國家元首。政府首腦兼武裝部隊總司令。擁有行政權，由總統直接選舉產生。任期為6年，不得連任。總統無權實施戒嚴法，無權頒國公，不得任意拘捕反對派。禁止軍事干預政治，保障人權，政教分離。進行民主選舉。',
      content_5  : '國會',
      content_6  : '國會是最高的立法機構，由參眾兩院組成，最高法院擁有司法權。',
      content_7  : '行政區別',
      content_8  : '全國劃分為13個大區，73個省，2個特區和60個市。',
      content_9  : '司法機構',
      content_10 : `奉行和平、中立、互尊的外交政策。在平等、和平、互尊的基礎上發展同所有國家的關係，
          注重與周邊國家的關係。奉行對美、日友好關係。積極發展同東盟及其他國際組織間的關係，加強同第三世界國家的關係，是東盟組織成員國，
          積極推動國家的三大目標是，推動國家發展，保護海外菲人。菲律賓開發效力，積極參與國際和區域事務，努力改變和推動菲律賓國內外上的形象。
          根據1996年公布，同124個國家建立外交關係。`,
      content_11 : '產業',
      content_12 : `第三產業比重較大，產值約占 GDP 的一半，第一、二產業在國民經濟中也占有相當重要的地位，六十年代後期採取經濟開放政策，積極吸引外資，經濟發展取得一定成效，1982
          年被世界銀行列為“中等收入國家”。此後受西方經濟衰退和自身政局動蕩影響，經濟呈下滑趨勢，90
          年代初拉莫斯總統執政後，為振興經濟採取了一系列積極措施，經濟開始全面復甦，並保持較高增長速度。1997 年亞洲金融危機期間，菲經濟受到的影響相對較小，1999
          年，菲經濟逐漸開始恢復，2000 年，由於政局動蕩，菲金融形勢一度惡化，匯率創歷史新低，股市亦跌至兩年來最低水平。2001
          年阿羅約總統執政後，推進經濟改革，重點提高農業生產力，受全球經濟減速和政局動蕩的影響，菲僅保持緩慢增長，經濟走勢低迷，外貿下滑，外來投資減少，政府債務負擔加重，貧困問題仍較突出。`,
      content_13 : '礦業',
      content_14 : `主要有銅、金、銀、鐵、鉻、鎳等 20 餘種，銅蘊藏量約 37.16 億噸，金 1.36 億噸，鎳 1.27 億噸，有豐富的地熱資源，預計有 20.9 億桶原油標準能源。1976
          年以來在巴拉望島西北部海域發現石油，儲藏量為 3.5 億桶。森林面積 1250 萬公頃，覆蓋率約 40%，工礦業總產值占國民生產總值 31.9%，從業人口占總勞力
          15.6%。製造業約占工礦業總產值的 78.5%，建築業約占 17.5%，礦產業約占 3%，2001 年工礦業產值為 248 億美元，比 2000 年增長 1.9%。`,
      content_15 : '農業',
      content_16 :
        '菲律賓是個農業國，農業人口占總人口的2/3以上，主要糧食作物是稻谷和玉米。椰子、甘蔗、馬尼拉麻和煙草是菲律賓的四大經濟作物，值得菲律賓人引以自豪的當屬水果生產，菲律賓盛產椰子，香蕉、芒果、菠蘿，其中椰子產量和出口量均占全世界總產量和出口量的六成以上。',
      content_17 : '工業',
      content_18 : '菲律賓的工業以農、林產品的加工為主，另有一些紡織、水泥、汽車裝配等工業。銅是主要礦產，有豐富的地熱資源，預計有 20.9 億桶原油標準能源',
      content_19 : '交通',
      content_20 : `交通運輸以公路運輸為主，鐵路不發達，均集中在呂宋島，航空運輸主要由國家航空公司經營，全國各主要島嶼間都有航班，全國有機場有 300
          多個，主要機場有尼諾·阿基諾國際機場，宿霧市的馬克丹國際機場和達沃機場等。`,
      content_21 : '港口',
      content_22 : '主要港口為馬尼拉、宿霧、怡朗、三寶顏等。',
      content_23 : '[貨幣名稱]比索(Peso)',
      content_24 : `憲法規定，中小學實行義務教育。政府重視教育，2002 年教育預算為 1053 億比索，占政府預算開支的 13.48%，鼓勵私人辦學，為私立學校提供長期低息貸款，並免徵財產稅。1994
          年全國識字率為 93.5%。1996/1997 學年，全國有小學 37721 所，中學 63695 所，高等學院 1316 所；在校生中，小學生 1150.48 萬，中學生 488.35 萬，大學生 222.09
          萬。著名高等院校有菲律賓大學、安德雷爾大學、東方大學、遠東大學、聖托馬斯大學等。`,
      content_25 : '菲律賓國民教育實行從學前教育到高等教育的一套完善教育體制，即：',
      content_26 : '學前教育 (2-3 年)',
      content_27 : '小學教育(7 年)',
      content_28 : '中學教育 (4 年)',
      content_29 : '普通大學生課程 (3-5 年)',
      content_30 : '研究生課程 (1-5 年)',
      content_31 : `所有小學、中學均設有全國通考制度。在菲律賓，教育是政府的責任，政府對小學 (7 年) 和中學 (4 年) 的 11 年教育實行免費教育，但不是強制性義務教育。從兒童按 7
          歲入小學算起，一直到大學畢業取得學士學位時的年齡通常在 22 歲左右。`,
      content_32 : '高等教育機構可分為兩大組成部分：',
      content_33 : '公立高等教育機構 —— 國立大學、國立學院、國立高等學院或研究所',
      content_34 : '私立高等教育機構 —— 私立大學、私立學院、外國大學分校',
      content_35 : '在高等教育方面，私立教育機構扮演了非常重要的角色，為國內外學生提供了廣泛的教育機會。目前有超過 20,000 名外國學生分別在菲律賓國立或私立高等學府就讀。'
    }
  }
}

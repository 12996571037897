export default {
  other_institution    : '其他學校',
  university_of_manila : {
    title   : '馬尼拉大學',
    content : {
      content_1 : `八十年以前，有五位對於菲律賓年輕人充滿理想的教育家，他們共同的目標在於建立一個能夠成功塑造年輕人智力及品德的學習機構。
      於是本著不屈不撓的精神， 他們在公元1913年建立了第一個教育機構 INSTITUTO DE MANILA，為當地人民提供了更好的小學及中學教育設備。 
      為了因應國家發展及人民教育的提升，這些教育家一致希望能將教育工作擴大，因此在公元1921年將 INSTITUTO DE MANILA 提升為馬尼拉大學 (The University of Manila)。 
      馬尼拉大學最初由法律學院、文學院、哲學及語文學院、教育學院及企業管理學院所構成，並於幾年內增加了研究所部門、工學院、音樂學會及國際事務學院，使其早期即成為一所具備各種課程提供的綜合大學。 
      本著教育理應順應潮流及不斷求新進步的理念，該校於80年代加入了觀光事業、飯店及餐飲管理、計算機科技等課程；在90年代，馬尼拉大學更規劃了教育博士、 公共行政管理博士及企管碩士等課程，為企業及政府部門之高階主管提供了進一步學習的管道。`,
      content_2 :
        '八十年來，馬尼拉大學為菲律賓及亞洲的年輕人提供了第二個家，這個家透過品性教育給予他們更好的環境去學習未來工作的技能， 正如馬尼拉大學的口號所言「對優秀的學術完全的投入」，是對教育事業希望的重申及信念的再造。',
      content_3 : '馬尼拉大學企業管理學院於公元1992年成立，其主要目標有四點：',
      content_4 : '訓練培養未來的企業領導者及行政人員，使其做好準備並具備處理商業及工業等各種有關於經濟事務的能力。',
      content_5 : '幫助穩定國家經濟。',
      content_6 : '使學生具備必要的知識並熟練掌握總體經濟學，練習如何成為一個有效率的企業管理領導者。',
      content_7 : '開發學生面對企業問題時所需的分析組織及獨立判斷之能力。',
      content_8 :
        '因為意識到國家的發展取決於經濟，而在國家發展初期又常會缺乏企業及經濟人才，馬尼拉大學企業管理學院長久以來一直極力於培養對經濟發展有貢獻的企業管理人才， 其主要的課程領域如下：會計學、銀行及金融、管理、銷售及經濟學，能透過如此的教育來幫助國家的經濟發展。',
      content_9 : `馬尼拉大學教育學院於公元1923年成立，其主要目標在於提供學生持續的學習並研發更好的教學方法，指導如何用廣泛的聲音及視覺工具來幫助這些教學課程， 因此它不斷地派遣教師赴國外考察學習新的教學技術並將其帶入此學院加以發揚光大，希望讓此學院的學生皆能成為一流的教育學者。 
      馬尼拉大學教育學院目前在大學部所提供的學位課程有英文教育學士、菲律賓語教育學士、數學教育學士、科學教育學士、心理諮商及指導教育學士、計算機科學教育學士及圖書館科學教育學士等。 這些教育教學課程除了對學生個人和社會的生活文化改進有所助益外，更著重強調如何教導學生具備教師應有的教育、文化和社會能力。`,
      content_10 : `馬尼拉大學工學院於公元1945年成立，第二次世界大戰結束四個月後建立，由於第二次世界大戰對國家的整體建設造成相當大的破壞，因此急需一群高級工業工程人才來參與戰後國家工業重建工作， 
      以帶動國家的經濟發展。馬尼拉大學有鑑於此，立即配合國家政策需要，邀集相關教育學者及工程師來開辦工學院，目的是透過這些專業教育課程，訓練出具備知識學養及技能的工程師。`,
      content_11 : '馬尼拉大學國際事務學院於公元1946年成立，是菲律賓國家第一個成立的國際事務學院，其主要目標有三點：',
      content_12 : '給予未來有志從事於外交國際事務的學生適當及完整的課程訓練，進而培養其成為傑出的外交主管及行政人員。',
      content_13 : '教導學生必要的外交知識並熟練國際禮儀，以具備處理國際事務的能力。',
      content_14 : '訓練培養商業機構、國家機關、大眾傳播機構、銀行機關及綜合大學所需的外交人才。',
      content_15 : `馬尼拉大學國際事務學院相信，好的外交手腕對國家世界的和平及人類的進步發展有相當大的決定因素，
      因此它不斷地教導灌輸學生這個信念。在課程上除了重視基本的外交及國際事務技能的學習，訓練學生相關禮節及個性發展外，更著重於外交及國際事務相關經驗及專業的傳承教授，期待學生在進入各公私立機關後，皆能本此信念在其崗位為全人類的福祉共同奮鬥。`,
      content_16 :
        '五十年來，馬尼拉大學國際事務學院不僅培育出多位外交國際事務教學與研究人才、投身於各學術機構的教育工作，更陶養出實際的外交國際事務參與者，以及無數在各行各業有突出表現的工作者，老師與學生的表現，有目共睹。'
    }
  },
  adamson_university : {
    title   : '亞當森大學',
    content : {
      content_1 : '亞當森大學Adamson University 創立於 1932 年，創辦之初是為了發展化學工業的需求，尤其針對菲律賓在化學領域的科學和技術。',
      content_2 : `1941 年 2 月，學校建立大學教育系。1964 年 12 月，作為一個天主教大學加入 Adamson-Ozanam
                教育機構，專門致力於基督教教學的發展，廣泛擴展學校，大量招生，尤其是針對窮人，並在世界性的基督教教學學術上有著傑出貢獻。`,
      content_3  : '如今，Adamson University 已經成為一個涉及領導機構研究生教育、法律、藝術、科學、工程、醫藥、建築、工商管理、師範教育、中學、小學、學前教育等等的綜合性大學。',
      content_4  : '研究院',
      content_5  : '教育碩士主修監督與管理',
      content_6  : '教育碩士主修計算機輔助教學',
      content_7  : '工商管理碩士',
      content_8  : '化學碩士',
      content_9  : '工程管理碩士',
      content_10 : '信息技術碩士',
      content_11 : '​工程碩士',
      content_12 : '藥劑學碩士',
      content_13 : '神學碩士主修牧師學',
      content_14 : '神學碩士主修牧師學教條',
      content_15 : '神學碩士主修牧師學研究與宗教教育',
      content_16 : '教育博士',
      content_17 : '管理學博士',
      content_18 : '研究院',
      content_19 : '建築學學士',
      content_20 : '商學院',
      content_21 : '會計理學士',
      content_22 : '工商管理學士主修銀行及財經',
      content_23 : '工商管理學士主修管理',
      content_24 : '工商管理學士主修營銷',
      content_25 : '辦公室型這學士主修辦公管理',
      content_26 : '辦公室行政學士主修電腦教育',
      content_27 : '兩年課程電腦文秘',
      content_28 : '教育學院',
      content_29 : '初級教育學士主修電腦技術教育',
      content_30 : '初級教育學士主修體育',
      content_31 : '初級教育學士主修宗教教育',
      content_32 : '初級教育學士主修英語',
      content_33 : '初級教育學士主修社會研究',
      content_34 : '中等教育學士主修電腦技術教育',
      content_35 : '中等教育學士主修體育',
      content_36 : '中等教育學士主修宗教教育',
      content_37 : '中等教育學士主修英語',
      content_38 : '中等教育學士主修社會研究',
      content_39 : '工學院',
      content_40 : '陶瓷工程學學士',
      content_41 : '化學工程學學士',
      content_42 : '土木工程學學士',
      content_43 : '計算機工程學學士',
      content_44 : '電機工程學學士',
      content_45 : '電子與通訊工程學學士',
      content_46 : '地質學學士',
      content_47 : '工業工程學學士',
      content_48 : '機械工程學學士',
      content_49 : '採礦',
      content_50 : '陶瓷工程學學士(特別課程5年制)',
      content_51 : '法學院',
      content_52 : '法律學士',
      content_53 : '文學院',
      content_54 : '經濟學士',
      content_55 : '大眾傳播學士',
      content_56 : '哲學學士',
      content_57 : '政治學學士',
      content_58 : '護理學院',
      content_59 : '護理學學士',
      content_60 : '醫藥學院',
      content_61 : '藥劑學學士',
      content_62 : '藥劑技師',
      content_63 : '理工學院',
      content_64 : '生物學學士',
      content_65 : '化學學士',
      content_66 : '數學與計算機應用專業理學士',
      content_67 : '心理學學士',
      content_68 : '電腦技術學學士',
      content_69 : '計算機科學理學士',
      content_70 : '信息技術學學士',
      content_71 : '信息管理學士',
      content_72 : '[入學資格及相關日期]',
      content_73 : '大學部：',
      content_74 : '開學日期：',
      content_75 : '第一學季：六月十月',
      content_76 : '第二學季：十一月三月',
      content_77 : '第三季：四月~五月',
      content_78 : '申請資格：高中畢業或同等學歷。',
      content_79 : '入學考報名日期：開學日期前二個月截止。',
      content_80 : '考試日期：一般為開學日期前一個月。',
      content_81 : '考試科目：數學、英文、自然科學、智力測驗(特殊科目另外加考)，本公司可協助輔導通過。',
      content_82 : '碩士班：',
      content_83 : '開學日期：',
      content_84 : '第一學季：六月十月',
      content_85 : '第二學季：十一月三月',
      content_86 : '第三學季：四月~五月',
      content_87 : '申請資格：大學畢業或同等學歷。大專畢業生須補本科課程再讀碩士。 有些科系須有三年相關工作經驗(商學院、工學院)。',
      content_88 : '入學考報名日期：開學日期前二個月截止。',
      content_89 : '考試日期：一般為開學日期前一個月。',
      content_90 : '考試科目：數學、英文、智力測驗 (特殊科目另外加考)，本公司可協助輔導通過。',
      content_91 : '博士班：',
      content_92 : '個別辦理'
    }
  },
  ellimio_aguinaldo_college : {
    title   : '伊密里歐學院',
    content : {
      content_1 : `EMILIO AGUINALDO COLLEGE（以下簡稱 EAC）是由 YAMAN LAHI FOUNDATION, INC. 紀念 EMILIO AGUINALDO 將軍於 1973 年所投資創立，
          現有培養醫師的醫學系、牙醫學系之外，另有藥學系、醫事技術學系、物理治療學系、職能治療學系、護理助產學系等，是一所設備新穎、師資優越的現代化完備醫學教育體系。`,
      content_2 : `YAMAN LAHI FOUNDATION, INC. 財團是西班牙在菲律賓資金雄厚的財團之一，投資的領域甚廣， 尤其是房地產獲得的利益最豐，轉投資於教育方面資金頗巨，EMILIO AGUINALDO
          COLLEGE（EAC）是最成功的一部分，除了綜合學院校舍外，另有附設醫院及觀光飯店，形成最現代化的校區。`,
      content_3 : `EMILIO 醫學院（EAC）的師資來自國立菲律賓大學醫學院退休教授，在醫學界具領導地位，
          再由教授推薦得意門生，以及歐美留學的學者擔任教職，醫學界老中青三代具備，薪火相傳又能掌握最新的醫學新知，將來形成醫學研究的重鎮。`,
      content_4 :
        'EMILIO的師資大部分來自國立菲律賓大學轉任或退休而來，並有許多教授曾經留學歐美國家，在教育界具有領導的地位。再由資深名譽的教授推薦新進的教授，薪火相傳又能互相獲得新知，成為教育界的佳話。',
      content_5 : `EAC 領導高層本著教育的高素質要求，讓學生在求學之外更能鍛鍊堅強的體魄，於 2001 年斥資 2
          億元人民幣新建一棟體育大樓，內部設施涵蓋有游泳池、籃球場、網球場等各式各樣的體育設施，共有 11 層樓，總面積超過 3 萬平方米，是菲律賓學校內數一數二的體育館。`,
      content_6 : `EAC 坐落於 Taft Avenue, United Nation Ave, San Narcelino, Padre Faura 及 Gen Luna St
          之間，臨近國立菲律賓總醫院、德拉薩大學、世界衛生組織亞洲總部等等，菲律賓警政署亦在其對面。步行 5 分鐘就可到達 Roubison
          Mall，是馬尼拉大學城的中心精華地帶，治安良好，環境特佳的重點地區。`,
      content_7  : '[伊密里歐大學部學位介紹]',
      content_8  : '[伊密里歐大學學院研究學位介紹]',
      content_9  : '[入學資格及相關日期]',
      content_10 : '大學部：',
      content_11 : '開學日期：',
      content_12 : '第一學期：六月十月',
      content_13 : '第二學期：十一月三月',
      content_14 : '暑修：四月~五月',
      content_15 : '申請資格：高中畢業或同等學歷。',
      content_16 : '入學考報名日期：開學日期前一個月截止。',
      content_17 : '考試日期：一般為開學日期前一個月。',
      content_18 : '考試科目：數學、英文、自然科學、智力測驗 (特殊科目另外加考)，本公司可協助輔導通過。',
      content_19 : '碩士班：',
      content_20 : '開學日期：',
      content_21 : '第一學期：六月十月',
      content_22 : '第二學期：十一月三月',
      content_23 : '署修：四月~五月',
      content_24 : '申請資格：',
      content_25 : '大學畢業或同等學歷。',
      content_26 : '大專（三年制）畢業。',
      content_27 : '大學肄業或大專二年制畢業者，須補修學分。',
      content_28 : '有些科系須有三年相關工作經驗（商學院、工學院）。',
      content_29 : '入學考報名日期：開學日期前一個月截止。',
      content_30 : '考試日期：一般為開學日期前一個月。',
      content_31 : '考試科目：數學、英文、智力測驗（特殊科目另外加考），本公司可協助輔導通過。',
      content_32 : '博士班：',
      content_33 : '個別辦理'
    }
  },
  university_of_the_east : {
    title   : '東方大學',
    content : {
      content_1 :
        '東方大學成立於第二次世界大戰之後，那一年正好是菲律賓獲得聯合國的承認。東方大學用了半世紀的時間，致力推動高質量教學的發展方針，使得東方大學，許多國內執照考試通過率方面都有很好的成績，尤其是他的醫學院，與菲律賓國立大學、聖多湯瑪士大學的醫學院並列為菲律賓最佳的醫學院。',
      content_2  : '在1954年東方大學創立了另一個Caloocan校區，提供更多更好的課系給越來越多的學子。並且在1955年，在奎松市創立了他們現在引以為豪的醫學院及自己的附設醫院。',
      content_3  : '經過了這麼多年，東方大學始終把持著當初創校的宗旨，「不以盈利只為教育」的神聖使命繼續在努力，培育出更多各領域的菁英在為國家社會做貢獻。',
      content_4  : '東方大學的立校根本有三點：',
      content_5  : '提供的課程必須時時創新，趕上21世紀現代化國際步伐的需要。',
      content_6  : '以最先進的優良儀器設備、高格調的品德修養及強烈的社會進步使命作為努力方向。',
      content_7  : '創造一個可以自由發表、深入研究的環境，讓學生擁有無限的發展空間。',
      content_8  : '[課程介紹]',
      content_9  : '工商管理博士',
      content_10 : '主修：金融管理、人力資源管理',
      content_11 : '教育學博士',
      content_12 : '主修：教育管理與領導',
      content_13 : '牙醫學博士',
      content_14 : '2級水平',
      content_15 : '工商管理碩士',
      content_16 : '施工管理碩士',
      content_17 : '教學文學碩士',
      content_18 : '主修：生物科學、英語、菲律賓語、歷史、數學、體育、政治學',
      content_19 : '教育學文學碩士',
      content_20 : '主修：教育管理和領導、圖書館科學、英語作為第二語言、指導計劃A（含論文）、無論文的計劃B：人力資源管理、財政管理、或國家政策和計劃管理',
      content_21 : '理科碩士主修環境科學',
      content_22 : '圖書館與資訊研究碩士',
      content_23 : '法學學士',
      content_24 : '廣播傳播學士',
      content_25 : '新聞學學士',
      content_26 : '文學學士',
      content_27 : '主修：經濟學、英語、菲律賓語、歷史、國際研究（主修：美國研究、中國研究）、法務管理、政治學、社會學',
      content_28 : '理科學士',
      content_29 : '主修：生物、圖書館與資訊研究、數學、物理學、心理學、統計學',
      content_30 : '會計學與會計電腦化系統學士',
      content_31 : '會計學學士',
      content_32 : '工商管理學士',
      content_33 : '主修：金融管理、商業經濟、工商管理、市場營銷管理、管理會計',
      content_34 : '工商管理學士特別課程',
      content_35 : '土木工程學士',
      content_36 : '計算機工程學士',
      content_37 : '電機工程學士',
      content_38 : '電子與信息工程學士',
      content_39 : '工業工程學士',
      content_40 : '機械工程學士',
      content_41 : '計算機科學學士',
      content_42 : '信息技術學士',
      content_43 : '信息管理學士',
      content_44 : '中等教育學士',
      content_45 : '主修：生物、化學、物理、綜合科學、英語、菲律賓語、歷史、數學和體育（PEHM，包括體育、健康、教育和音樂）、指導與諮詢、計算機教育',
      content_46 : '初級教育學士',
      content_47 : '按專業分為：英語、菲律賓語、家政學與技術、數學、體育、科學與健康、社會研究、早期兒童教育、指導與諮詢初級階段',
      content_48 : '營養學學士',
      content_49 : '信息技術學文學士',
      content_50 : '會計學與會計電腦化系統學士',
      content_51 : '會計學學士',
      content_52 : '工商管理學士',
      content_53 : '主修：財務管理，商業經濟，工商管理，市場營銷管理，管理會計',
      content_54 : '土木工程學士',
      content_55 : '計算機工程學士',
      content_56 : '電機工程學士',
      content_57 : '電子與通信工程學士',
      content_58 : '機械工程學士',
      content_59 : '文科學士主修廣告學',
      content_60 : '理科學士主修室內設計',
      content_61 : '文科學士主修繪畫',
      content_62 : '工業設計文學士',
      content_63 : '醫學博士',
      content_64 : '護理學碩士',
      content_65 : '公共衛生學碩士',
      content_66 : '主修：健康設施管理，流行病學',
      content_67 : '臨床醫學碩士',
      content_68 : '主修：腸胃，生殖，耳鼻喉',
      content_69 : '內科學碩士',
      content_70 : '主修：糖尿病學',
      content_71 : '護理學學士',
      content_72 : '物理治療學學士',
      content_73 : '[入學資格及相關日期]',
      content_74 : '大學部：',
      content_75 : '開學日期：',
      content_76 : '第一學季：六月~十月',
      content_77 : '第二學季：十一月~三月',
      content_78 : '第三學季：四月~五月',
      content_79 : '申請資格：高中畢業或同等學歷。',
      content_80 : '入學考報名日期：開學日期前四個月截止。',
      content_81 : '考試日期：一般為開學日期前一~二個月。',
      content_82 : '考試科目：數學、英文、自然科學、智力測驗（特殊科目另外加考），本公司可協助輔導通過。',
      content_83 : '碩士班：',
      content_84 : '開學日期：',
      content_85 : '第一學季：六月~十月',
      content_86 : '第二學季：十一月~三月',
      content_87 : '第三學季：四月~五月',
      content_88 : '申請資格：',
      content_89 : '大學畢業或同等學歷。',
      content_90 : '大專畢業生須補本科課程再讀碩士。',
      content_91 : '有些科系須有三年相關工作經驗（商學院、工學院）。',
      content_92 : '入學考報名日期：開學日期前四個月截止。',
      content_93 : '考試日期：一般為開學日期前一~二個月。',
      content_94 : '考試科目：數學、英文、智力測驗（特殊科目另外加考），本公司可協助輔導通過。',
      content_95 : '博士班：',
      content_96 : '個別辦理'
    }
  },
  philippine_normal_university : {
    title   : '菲律賓師範大學',
    content : {
      content_1 : '學院簡介',
      content_2 :
        '菲律賓國立師範大學由美國人，創建于1901年9月1日，並安置于馬尼拉中心的TAFT街，作為一個培養老師的初級學院，最初提供了2年制的中等教育課程。1949年后，提升成菲律賓師范的學院，并且修畢學士、家長教師等的專業課程。1953年，建立了研究生院，主修包括文學碩士（MA）的專業課程，在1972年7月開始教育學、哲學博士課程做準備。',
      content_3 :
        '短短數年間不斷擴充學術計劃，及扩展多區域的服務，PNUE享有菲律賓教育高等領域相當足够的重視，學校在師范教育上得以立足。主要項目與最有優勢課程為英語課本、公立小學計劃、特殊教育課程，全國性基礎課程及美國和平組織合格的私人教育組織。近期在Agusan del Sur、Isabela和Negros設立了分部。',
      content_4 :
        '1991年12月26日，學院改建為菲律賓國立師范大學，並在Quezon City（呂宋島西南部城市）開办了第二個分院。2001年9月1日，是學院的百年紀念日，21世紀學院將沿着百年歷史繼續前進。',
      content_5   : '學士學位課程',
      content_6   : '學士學位課程：',
      content_7   : '幼兒期教育學士（BECED）',
      content_8   : '初級教育學士（BEED）',
      content_9   : '中心課程：',
      content_10  : '初級英語',
      content_11  : '初級菲律賓語',
      content_12  : '初級數學',
      content_13  : '重點科學和健康',
      content_14  : '音樂、藝術和休閒教育',
      content_15  : '中學教育學士（BSEd）',
      content_16  : '主修：',
      content_17  : '生物學',
      content_18  : '化學',
      content_19  : '英語',
      content_20  : '菲律賓語',
      content_21  : '自然科學',
      content_22  : '歷史',
      content_23  : '家政學',
      content_24  : '數學',
      content_25  : '音樂教育',
      content_26  : '體育',
      content_27  : '物理',
      content_28  : '物理和技術',
      content_29  : '社會科學',
      content_30  : '講話和戲劇藝術',
      content_31  : '教育評 · 理學士 (BS)',
      content_32  : '主修：',
      content_33  : '生物學',
      content_34  : '生物學教學',
      content_35  : '化學教學',
      content_36  : '家政學和技術教育',
      content_37  : '信息技術教育',
      content_38  : '數學',
      content_39  : '數學教學',
      content_40  : '營養學教學',
      content_41  : '物理',
      content_42  : '物理教學',
      content_43  : '教育學文、理學士主修：文學',
      content_44  : '圖書館信息技術教育學學士（BLIS）',
      content_45  : '心理學學士（BSP）',
      content_46  : '教育心理學',
      content_47  : '指導與諮詢',
      content_48  : '信息技術教育學士（BSITE）',
      content_49  : '碩士學位課程：',
      content_50  : '藝術教育碩士（MAED）',
      content_51  : '主修：',
      content_52  : '雙語教育',
      content_53  : '兒童研究',
      content_54  : '學院教學',
      content_55  : '戲曲教育和劇院藝術',
      content_56  : '教育管理',
      content_57  : '初級教育',
      content_58  : '英語藝術學科',
      content_59  : '英文教學',
      content_60  : '指導與諮詢',
      content_61  : '衛生教育',
      content_62  : '家政學',
      content_63  : '歷史研究',
      content_64  : '健康與家政教育',
      content_65  : '語言測試',
      content_66  : '測試與評估',
      content_67  : '音樂教育',
      content_68  : '非正式教育',
      content_69  : '體育',
      content_70  : '科學教育',
      content_71  : '社會科學教學',
      content_72  : '圖書館信息科學',
      content_73  : '文學',
      content_74  : '數學教育',
      content_75  : 'Pagtuturo ng Filipino',
      content_76  : '閱讀（英語）',
      content_77  : '學校心理學',
      content_78  : '特殊教育',
      content_79  : '價值教育',
      content_80  : '科學教育',
      content_81  : '教育技術碩士',
      content_82  : '化學教育碩士',
      content_83  : '語言學碩士',
      content_84  : '科學教育碩士',
      content_85  : '生物',
      content_86  : '化學',
      content_87  : '自然科學',
      content_88  : '物理',
      content_89  : '數學教學碩士',
      content_90  : '教學研究碩士 (MAT)',
      content_91  : '價值教育',
      content_92  : '文學',
      content_93  : '博士學位課程：',
      content_94  : '教育學博士',
      content_95  : '哲學博士',
      content_96  : '應用語言學',
      content_97  : '教育',
      content_98  : '教育管理',
      content_99  : '教育經營',
      content_100 : '指導與諮詢',
      content_101 : '語言學',
      content_102 : '文學',
      content_103 : '入學資格及相關日期',
      content_104 : '大學部：',
      content_105 : '開學日期：',
      content_106 : '第一學期：六月~十月',
      content_107 : '第二學期：十一月~三月',
      content_108 : '第三學期：四月~五月',
      content_109 : '申請資格：高中畢業或同等學歷。',
      content_110 : '入學考報名日期：開學日期前六個月截止。',
      content_111 : '考試日期：一般為開學日期前三~四個月。',
      content_112 : '考試科目：數學、英文、自然科學、智力測驗（特殊科目另外加考），本公司可協助輔導通過。',
      content_113 : '碩士班：',
      content_114 : '開學日期：',
      content_115 : '第一學期：六月~十月',
      content_116 : '第二學期：十一月~三月',
      content_117 : '第三學期：四月~五月',
      content_118 : '申請資格：',
      content_119 : '大學畢業或同等學歷。',
      content_120 : '大專畢業生須補本科課程再讀碩士。',
      content_121 : '有些科系須有三年相關工作經驗（商學院、工學院）。',
      content_122 : '入學考報名日期：開學日期前六個月截止。',
      content_123 : '考試日期：一般為開學日期前三~四個月。',
      content_124 : '考試科目：數學、英文、智力測驗（特殊科目另外加考），本公司可協助輔導通過。',
      content_125 : '博士班：',
      content_126 : '​各別辦理'
    }
  },
  de_la_salle_university : {
    title   : '德拉薩大學',
    content : {
      content_1 :
        '德拉薩大學創立於公元1911年，位於馬尼拉的市區，是菲律賓一流的貴族基督學校。創辦初期為125個不同年齡及程度的學生授課，且在公元1915年有第一批的商業中學學生畢業。公元1925年，德拉薩大學被允許授課文學士及文學碩士課程，且於公元1930年被允許授課教育科學學士及教育科學碩士課程。',
      content_2 :
        '在第二次世界大戰時，日本軍隊占領了此大學並作為防守駐扎地。戰爭期間雖然仍繼續授課，但課程卻大量減少，且當時軍方不斷轟炸，導致學校的體育館、圖書館、實驗室嚴重損壞。公元1945年7月，戰爭結束後，德拉薩大學重新建立其校園，雖然缺乏人力資源及設備，但在學期末時仍有60個學生畢業於中學部。',
      content_3 :
        '德拉薩大學為加強其高等教育課程，先後在公元1947年成立大學部工學院；公元1953年成立藝術與科學學院；公元1959年成立教育學系；公元1960年成立企業管理研究所；公元1963年成立教育研究所；公元1973年成立工業技術學院（後來合併於工學院）；公元1980年成立專業發展部門；公元1981年成立計算機信息學院等。',
      content_4 :
        '公元1981年至1982年是德拉薩大學的一個重要的里程碑，因為它將原本學期制的課程（一年兩個學期）改為學季制課程（一年三個學季），其開學日期分別在每年一月、六月及九月，而暑假則是在四月至五月。每個學季皆可入學且上課時間約十三週，此學制的改革讓有意至此修讀的學生不會浪費太多的等待入學時間。',
      content_5 : '德拉薩大學的學費要比一般的學校貴，本科段學費比一般學校增加80%，碩士段學費比一般學校增加20%，基本都在14000元人民幣左右。',
      content_6 :
        '德拉薩大學語言中心為提供學生精進英語能力的機會，特地根據學生的需求及能力安排了不同階段及課程的英文密集短期訓練班，採小班制教學使老師能針對個別的學生提供必要的協助。上課重點在於讓學生能熱烈參與課程並彼此互動學習，學生可在入學前先至此中心加強英文能力，為日後的正式學程奠定英文基礎。',
      content_7 :
        '德拉薩大學語言中心依據學生需求共安排了三個不同階段的英文訓練，每個階段都有數種課程，且每個課程亦根據學生英文程度而區分為八個等級。單個課程上課時間為每天二小時，為期三週共三十小時。學生每個月可選擇三種以內的課程並經語言中心評鑑其英文能力等級後分級上課。',
      content_8  : '兹分述各階段課程如下：',
      content_9  : '[德拉薩大學大學部學位介紹]',
      content_10 : '[德拉薩大學研究所學位介紹一]',
      content_11 : '[德拉薩大學研究所學位介紹二]',
      content_12 : '[德拉薩大學研究所學位介紹三]',
      content_13 : '[入學資格及相關日期]',
      content_14 : '大學部：',
      content_15 : '開學日期：',
      content_16 : '第一學季：六月~九月',
      content_17 : '第二學季：九月~十二月',
      content_18 : '第三學季：一月~三月',
      content_19 : '申請資格：高中畢業或同等學歷。',
      content_20 : '入學考報名日期：開學日期前四個月截止。',
      content_21 : '考試日期：一般為開學日期前一~二個月。',
      content_22 : '考試科目：數學、英文、自然科學、智力測驗（特殊科目另外加考），本公司可協助輔導通過。',
      content_23 : '碩士班：',
      content_24 : '開學日期：',
      content_25 : '第一學季：六月~九月',
      content_26 : '第二學季：九月~十二月',
      content_27 : '第三學季：一月~三月',
      content_28 : '申請資格：',
      content_29 : '大學畢業或同等學歷。',
      content_30 : '大專畢業生須補本科課程再讀碩士。',
      content_31 : '有些科系須有三年相關工作經驗（商學院、工學院）。',
      content_32 : '入學考報名日期：開學日期前兩個月截止。',
      content_33 : '考試日期：一般為開學日期前一個月。',
      content_34 : '考試科目：數學、英文、智力測驗（特殊科目另外加考），本公司可協助輔導通過。',
      content_35 : '博士班：',
      content_36 : '個別辦理'
    }
  }
}

export default {
  overseas_services : {
    services : {
      service_1       : '留学咨询与规划',
      service_2       : '毕业证书（学位证书）、成绩单、无犯罪记录邀请公证处公证',
      service_3       : '毕业证书（学位证书）、成绩单、无犯罪记录送外交部认证',
      service_4       : '毕业证书（学位证书）、成绩单、无犯罪记录邀请大使馆认证',
      service_5       : '代为办理赴菲律宾之入境签证',
      service_6       : '代订机票',
      service_7       : '代为报名学校入学考试及口试',
      service_8       : '代为办理申请学校之所有相关事务',
      service_9       : '新生到达安排接机',
      service_10      : '专人接送至学校参加入学考试及口试',
      service_11      : '办理银行存款证明',
      service_12      : '办理财力支持书',
      service_13      : '协助办理保险，由甲方决定是否办理',
      service_14      : '专人带领新生了解学校及生活环境',
      service_15      : '专人接送日常生活用品采购',
      service_16      : '语言中心测试、注册及证件办理',
      service_17      : '专人接送安排学生体验',
      service_18      : '专人接送办理外国学生居留证',
      service_19      : '专人接送办理学生签证',
      service_20      : '专人接送办理返菲证',
      service_21      : '提供热门旅游路线服务',
      service_22      : '不定期举办学生聚会',
      service_23      : '银行开户服务',
      service_24      : '安排住宿，签订住宿契约书',
      service_24_desc : '乙方宿舍',
      service_25      : '安排宗教活动，由甲方决定是否需要',
      service_26      : '协助注册及选课',
      service_27      : '宿舍安全及卫生维护',
      service_27_desc : '乙方宿舍',
      service_28      : '第一年的学生签证延期',
      service_29      : '协助医疗',
      service_30      : '协助学生到银行汇款及换汇（一年3-5次）',
      service_31      : '第二年起每半年学生签证延期',
      service_32      : '每次回国返菲证办理',
      service_33      : '学成后毕业证书、成绩单送菲律宾教育部认证',
      service_34      : '学成后毕业证书、成绩单送菲律宾总统府认证',
      service_35      : '学成后毕业证书、成绩单送菲律宾外交部认证',
      service_36      : '学成后毕业证书、成绩单送中国大使馆认证',
      service_37      : '学成后代办出国人员回国证明'
    },
    content_1 : '立誉留学顾问有限公司',
    content_2 : `菲律宾立誉留学顾问有限公司是由台湾、菲律宾、中国、韩国等四个地区的留学专业人士联合创办的专业留学服务机构，
  总部设在菲律宾首都马尼拉，在台湾台北、台中、高雄，韩国首尔、釜山，中国上海等地设有办事处。
  公司在马尼拉拥有五栋学生宿舍大楼（含学生餐厅）、两所语言学校、一家贸易公司、一家旅行社及两间韩国餐厅。
  公司拥有十七名以上专业留学咨询顾问及一百多名教师和服务人员，提供从留学前的选校选专业辅导、留学中的课业辅导和生活协助，
  到毕业后的学历认证及就业辅导等，竭力为我们的学生提供最完整的高品质服务。自1998年成立以来，
  凭借良好的信誉、完善的服务和科学的管理，获得菲律宾各院校、海外合作机构和留学生家长的好评，是每位留学生的最佳选择。 `,
    content_3  : '我们已成功办理来自台湾、中国和韩国的学生超过10,000人（含短期培训），分别就读于菲律宾各类院校，学成回国后均获得相当好的就业机会及发展。 ',
    content_4  : '专业办理：',
    content_5  : '菲律宾医学院、牙医学院保证入学。 ',
    content_6  : '各科系大学、研究所、博士班留学。 ',
    content_7  : '四大名校保证入学。 ',
    content_8  : '护士培训转赴美国就业。 ',
    content_9  : '在职人士进修专班。 ',
    content_10 : '语言学校、短期游学。 ',
    content_11 : '菲律宾留学考察行程。 ',
    content_12 : '专业辅导：',
    content_13 : '最多的成功案例及经验累积。 ',
    content_14 : '专业的照顾及辅导系统。 ',
    content_15 : '最专业、最完整的长期辅导及服务。 ',
    content_16 : '有关所有费用、奉献金、证件费用等，简协助学生亲自缴付，并有学校或政府机关收据为凭。 ',
    content_17 : '境外服务费为同业间最合理的收费。 ',
    content_18 : '服务内容：',
    content_19 : '【作业流程及服务项目】',
    content_20 : '由于当地学校并不会安排接机、寄宿家庭，也没有学生会或学长们的协助工作，使我们公司需要增加数倍的人力、物力及财力，才能使我们的服务达到每位学生及家长的要求。 ',
    content_21 : '以下是我们的服务项目：',
    content_22 : '备注：申请学生签证流程需要两个月的时间，文件认证需要一个月时间，请申请者务必提前办理，以免错过入学良机。 '
  }
}

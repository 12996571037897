export default {
  overseas_services : {
    services : {
      service_1       : '留學諮詢與規劃',
      service_2       : '畢業證書（學位證書）、成績單、無犯罪記錄邀請公證處公證',
      service_3       : '畢業證書（學位證書）、成績單、無犯罪記錄送外交部認證',
      service_4       : '畢業證書（學位證書）、成績單、無犯罪記錄邀請大使館認證',
      service_5       : '代為辦理赴菲律賓之入境簽證',
      service_6       : '代訂機票',
      service_7       : '代為報名學校入學考試及口試',
      service_8       : '代為辦理申請學校之所有相關事務',
      service_9       : '新生到達安排接機',
      service_10      : '專人接送至學校參加入學考試及口試',
      service_11      : '辦理銀行存款證明',
      service_12      : '辦理財力支持書',
      service_13      : '協助辦理保險，由甲方決定是否辦理',
      service_14      : '專人帶領新生了解學校及生活環境',
      service_15      : '專人接送日常生活用品採購',
      service_16      : '語言中心測試、註冊及證件辦理',
      service_17      : '專人接送安排學生體驗',
      service_18      : '專人接送辦理外國學生居留證',
      service_19      : '專人接送辦理學生簽證',
      service_20      : '專人接送辦理返菲證',
      service_21      : '提供熱門旅遊路線服務',
      service_22      : '不定期舉辦學生聚會',
      service_23      : '銀行開戶服務',
      service_24      : '安排住宿，簽訂住宿契約書',
      service_24_desc : '乙方宿舍',
      service_25      : '安排宗教活動，由甲方決定是否需要',
      service_26      : '協助註冊及選課',
      service_27      : '宿舍安全及衛生維護',
      service_27_desc : '乙方宿舍',
      service_28      : '第一年的學生簽證延期',
      service_29      : '協助醫療',
      service_30      : '協助學生到銀行匯款及換匯（一年3-5次）',
      service_31      : '第二年起每半年學生簽證延期',
      service_32      : '每次回國返菲證辦理',
      service_33      : '學成後畢業證書、成績單送菲律賓教育部認證',
      service_34      : '學成後畢業證書、成績單送菲律賓總統府認證',
      service_35      : '學成後畢業證書、成績單送菲律賓外交部認證',
      service_36      : '學成後畢業證書、成績單送中國大使館認證',
      service_37      : '學成後代辦出國人員回國證明'
    },
    content_1 : '立譽留學顧問有限公司',
    content_2 : `菲律賓立譽留學顧問有限公司是由台灣、菲律賓、中國、韓國等四個地區的留學專業人士聯合創辦的專業留學服務機構，
          總部設在菲律賓首都馬尼拉，在台灣台北、台中、高雄，韓國漢城、釜山，中國上海等地設有辦事處。
          公司在馬尼拉擁有五棟學生宿舍大樓（含學生餐廳）、兩所語言學校、一家貿易公司、一家旅行社及兩間韓國餐廳。
          公司擁有十七名以上專業留學諮詢顧問及一百多名教師和服務人員，提供從留學前的選校選專業輔導、留學中的課業輔導和生活協助，
          到畢業後的學歷認證及就業輔導等，竭力為我們的學生提供最完整的高品質服務。自1998年成立以來，
          憑藉良好的信譽、完善的服務和科學的管理，獲得菲律賓各院校、海外合作機構和留學生家長的好評，是每位留學生的最佳選擇。`,
    content_3  : '我們已成功辦理來自台灣、中國和韓國的學生超過10,000人（含短期培訓），分別就讀於菲律賓各類院校，學成回國後均獲得相當好的就業機會及發展。',
    content_4  : '專業辦理：',
    content_5  : '菲律賓醫學院、牙醫學院保證入學。',
    content_6  : '各科系大學、研究所、博士班留學。',
    content_7  : '四大名校保證入學。',
    content_8  : '護士培訓轉赴美國就業。',
    content_9  : '在職人士進修專班。',
    content_10 : '語言學校、短期遊學。',
    content_11 : '菲律賓留學考察行程。',
    content_12 : '專業輔導：',
    content_13 : '最多的成功案例及經驗累積。',
    content_14 : '專業的照顧及輔導系統。',
    content_15 : '最專業、最完整的長期輔導及服務。',
    content_16 : '有關所有費用、奉獻金、證件費用等，簡協助學生親自繳付，並有學校或政府機關收據為憑。',
    content_17 : '境外服務費為同業間最合理的收費。',
    content_18 : '服務內容：',
    content_19 : '【作業流程及服務項目】',
    content_20 : '由於當地學校並不會安排接機、寄宿家庭，也沒有學生會或學長們的協助工作，使我們公司需要增加數倍的人力、物力及財力，才能使我們的服務達到每位學生及家長的要求。',
    content_21 : '以下是我們的服務項目：',
    content_22 : '備註：申請學生簽證流程需要兩個月的時間，文件認證需要一個月時間，請申請者務必提前辦理，以免錯過入學良機。'
  }
}

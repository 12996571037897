export default {
  study_abroad_daily : {
    sub_1 : {
      title   : '东南亚第二！菲第3季度经济加速增长！',
      bref    : '本国经济在今年第3季度加速增长，尽管通货膨胀高居不下。',
      content : {
        title_1    : '[菲律宾世界日报专讯]',
        content_1  : '菲律宾统计署(PSA)周四报告，本国经济在今年第3季度，也就是马科斯执政的第1季度加速增长，尽管通货膨胀高居不下。',
        content_2  : '以国内生产总值(GDP)-特定时期生产的商品和服务的总价值衡量的经济在7月至9月期间增长了7.6%，高于2022年第2季度上调后的7.5%的增长。',
        content_3  : '这也高于2021年第3季度录得的7%的GDP增长。',
        content_4  : '社会经济规划部长兼国家经济和发展署署长巴利沙干在新闻发布会上说，第3季度的经济增长也超过了分析师们平均预测的6.3%。',
        content_5  : '他说2022年本国经济增长有望实现政府目标的6.5%至7.5%。',
        content_6  : '他说：“鉴于最新的GDP结果，我们的经济需要在第4季度增长6.3%至6.9%。”',
        content_7  : '该本国首席经济学家说，7月至9月期间7.6%的增长让本国在东南亚国家联盟(ASEAN)地区排名第2，仅次于越南的13.7%，高于印度尼西亚的5.7%。',
        content_8  : '第3季度增长的主要贡献者如下：',
        content_9  : '批发和零售贸易、机动车和摩托车维修：9.1%',
        content_10 : '金融和保险活动：7.7%',
        content_11 : '建筑：12.2%',
        content_12 : '与此同时，所有主要经济部门，即农业、林业和渔业；工业和服务业，在那季度都出现了正转折，分别增长了2.2%、5.8%和9.1%。',
        content_13 : '在需求方面，家庭最终消费支出(HFCE)增长了8%。',
        content_14 :
          '他说：“我还想强调一下我们的教育部门。在疫情期间进行了2年的间歇性远程学习后，学校于8月重新开学，提供面对面授课。这种回归对于防止我们未来劳动力的生产力进一步下降至关重要。有了这些社会经济收益，很明显，菲律宾正在从新冠肺炎疫情造成的经济创伤中完全恢复过来。”'
      }
    },
    sub_2 : {
      title   : '菲律宾电信公司与中国电信等签海底光缆协议',
      bref    : '一项价值3亿美元的在东南亚建设海底电缆系统的投资协议',
      content : {
        title_1   : '[世界日报]',
        content_1 : '亚耶拉集团旗下的Globe电信周二表示，它已与包括迪托电信（DITO）在内的几家亚洲电信公司组成一个财团， 以达成一项价值3亿美元的在东南亚建设海底电缆系统的投资协议。',
        content_2 : 'Globe电信在一份声明中表示，该海底电缆系统旨在提高东南亚地区的网速并提升本地网速。',
        content_3 : '除了Globe和迪托电信，该财团的成员还包括中国电信全球有限公司（CTG）、新加坡电信有限公司（Singtel）和文莱统一国家网络公司（UNN）。',
        content_4 : 'Globe指出，计划中的亚洲连接电缆（ALC）将长约6000公里，以连接香港和新加坡为主线，并在菲律宾、文莱和中国海南设有分支。',
        content_5 : '该公司透露，该系统供货商是HMN技术有限公司（HMN Tech），预计将在2025年第三季度完成亚洲连接电缆的建设。',
        content_6 : 'Globe电信表示，亚洲连接电缆有望在亚洲地区提供额外的网速，并提高国际网络的弹性。',
        content_7 : '该公司说：“系统中至少有八对光纤，最小主线设计网速为每秒18 TB，将为该地区现有网络增加更多容量和多样性。”'
      }
    },
    sub_3 : {
      title   : '温州机场正式开通温州往返马尼拉国际货运航线',
      bref    : '一架中州航空波音全货机从温州龙湾国际机场起飞前往马尼拉尼诺伊·阿基诺国际机场',
      content : {
        content_1 : '一架中州航空波音737-800全货机从温州龙湾国际机场起飞前往菲律宾马尼拉尼诺伊·阿基诺国际机场，标志着温州机场正式开通温州往返马尼拉国际货运航线。',
        content_2 : '截至目前，该航线是温州机场开通的第六条国际货运航线，也是温州首条通往东南亚的国际全货机航线。',
        content_3 : `据悉，该条航线由中州航空投放在温州机场的驻场运力波音737-800执飞，载运量为20吨。
          该航线充分利用夜间时刻完成温州往返马尼拉的去返程，以最高航线时效保障国际出入境外贸产品运输。通过该航线，温州的普通货物、跨境电商9610货物以及市场采购类产品将行销菲律宾，有效拉动温州对菲贸易量；同时，菲律宾海域的东南亚海鲜产品也将以最快的速度送达温州市民的餐桌。`,
        content_4 :
          '下一步，温州机场将持续大力推进温州航空物流发展，以航线开辟、运力引进为基础，以跨境电商和生鲜运输为特色，带动航空物流上下游关联产业发展，为温州航空物流园引入大型物流企业夯实基础。此条航线的开通，是温州机场助推地方经济发展、自觉践行党的二十大精神的生动体现，为航空物流产业的系统性发展迈出了坚实的一步。'
      }
    }
  }
}

export default {
  study_abroad_daily : {
    sub_1 : {
      title   : '東南亞第二！菲第3季經濟加速成長！ ',
      bref    : '本國經濟在今年第3季加速成長，儘管通貨膨脹高居不下。 ',
      content : {
        title_1    : '[菲律賓世界日報專訊]',
        content_1  : '菲律賓統計署(PSA)週四報告，本國經濟在今年第3季度，也就是馬可斯執政的第1季度加速增長，儘管通貨膨脹高居不下。 ',
        content_2  : '以國內生產毛額(GDP)-特定時期生產的商品和服務的總價值衡量的經濟在7月至9月期間增長了7.6%，高於2022年第2季度上調後的7.5%的增長。 ',
        content_3  : '這也高於2021年第3季所錄得的7%的GDP成長。 ',
        content_4  : '社會經濟規劃部長兼國家經濟和發展署署長巴利沙幹在新聞發布會上說，第3季度的經濟成長也超過了分析師們平均預測的6.3%。 ',
        content_5  : '他說2022年本國經濟成長可望實現政府目標的6.5%至7.5%。 ',
        content_6  : '他說：「鑑於最新的GDP結果，我們的經濟需要在第4季成長6.3%至6.9%。」',
        content_7  : '該本國首席經濟學家說，7月至9月期間7.6%的增長讓本國在東南亞國家聯盟(ASEAN)地區排名第2，僅次於越南的13.7%，高於印尼的5.7%。 ',
        content_8  : '第3季成長的主要貢獻者如下：',
        content_9  : '批發和零售貿易、機動車和摩托車維修：9.1%',
        content_10 : '金融與保險活動：7.7%',
        content_11 : '建築：12.2%',
        content_12 : '同時，所有主要經濟部門，即農業、林業和漁業；工業和服務業，在那季度都出現了正轉折，分別增長了2.2%、5.8%和9.1%。 ',
        content_13 : '在需求方面，家庭最終消費支出(HFCE)成長了8%。 ',
        content_14 :
          '他說：「我還想強調一下我們的教育部門。在疫情期間進行了2年的間歇性遠距學習後，學校於8月重新開學，提供面對面授課。這種回歸對於防止我們未來勞動力的生產力進一步下降至關重要。'
      }
    },
    sub_2 : {
      title   : '菲律賓電信公司與中國電信等簽署海底光纜協議',
      bref    : '一項價值3億美元的在東南亞建設海底電纜系統的投資協議',
      content : {
        title_1   : '[世界日報]',
        content_1 : '亞耶拉集團旗下的Globe電信週二表示，它已與包括迪托電信（DITO）在內的幾家亞洲電信公司組成一個財團， 以達成一項價值3億美元的在東南亞建設海底電纜系統的投資協議。 ',
        content_2 : 'Globe電信在聲明中表示，該海底電纜系統旨在提高東南亞地區的網路速度並提升本地網路速度。 ',
        content_3 : '除了Globe和迪托電信，該財團的成員還包括中國電信全球有限公司（CTG）、新加坡電信有限公司（Singtel）和汶萊統一國家網路公司（UNN）。 ',
        content_4 : 'Globe指出，計劃中的亞洲連接電纜（ALC）將長約6000公里，以連接香港和新加坡為主線，並在菲律賓、汶萊和中國海南設有分支。 ',
        content_5 : '該公司透露，該系統供貨商是HMN技術有限公司（HMN Tech），預計將在2025年第三季完成亞洲連接電纜的建設。 ',
        content_6 : 'Globe電信表示，亞洲連接電纜預計在亞洲地區提供額外的網路速度，並提高國際網路的彈性。 ',
        content_7 : '該公司說：「系統中至少有八對光纖，最小主線設計網速為每秒18 TB，將為該地區現有網路增加更多容量和多樣性。」'
      }
    },
    sub_3 : {
      title   : '溫州機場正式開通溫州往返馬尼拉國際貨運航線',
      bref    : '一架中州航空波音全貨機從溫州龍灣國際機場起飛前往馬尼拉尼諾伊·阿基諾國際機場機場',
      content : {
        content_1 : '一架中州航空波音737-800全貨機從溫州龍灣國際機場起飛前往菲律賓馬尼拉尼諾伊·阿基諾國際機場，標誌著溫州機場正式開通溫州往返馬尼拉國際貨運航線。 ',
        content_2 : '截至目前，該航線是溫州機場開通的第六條國際貨運航線，也是溫州首條通往東南亞的國際全貨機航線。 ',
        content_3 : `據悉，該條航線由中州航空投放在溫州機場的駐場運力波音737-800執飛，載運量為20噸。
  該航線充分利用夜間時刻完成溫州往返馬尼拉的去回程，以最高航線時效保障國際出入境外貿產品運輸。透過該航線，溫州的普通貨物、跨境電商9610貨物以及市場採購類產品將行銷菲律賓，有效拉動溫州對菲貿易量；同時，菲律賓海域的東南亞海鮮產品也將以最快的速度送達溫州市民的餐桌。 `,
        content_4 :
          '下一步，溫州機場將持續大力推動溫州航空物流發展，以航線開闢、運力引進為基礎，以跨境電商和生鮮運輸為特色，帶動航空物流上下游關聯產業發展，為溫州航空物流園引進大型物流企業夯實基礎。此航線的開通，是溫州機場助推地方經濟發展、自覺實踐黨的二十大精神的生動體現，為航空物流產業的系統性發展邁出了堅實的一步。 '
      }
    }
  }
}

export default {
  other_institution    : '其他学校',
  university_of_manila : {
    title   : '马尼拉大学',
    content : {
      content_1 : `八十年以前，有五位对于菲律宾年轻人充满理想的教育家，他们共同的目标在于建立一个能够成功塑造年轻人智力及品德的学习机构。
 于是本着不屈不挠的精神， 他们在公元1913年建立了第一个教育机构 INSTITUTO DE MANILA，为当地人民提供了更好的小学及中学教育设备。
 为了因应国家发展及人民教育的提升，这些教育家一致希望能将教育工作扩大，因此在公元1921年将 INSTITUTO DE MANILA 提升为马尼拉大学 (The University of Manila)。
 马尼拉大学最初由法律学院、文学院、哲学及语文学院、教育学院及企业管理学院所构成，并于几年内增加了研究所部门、工学院、音乐学会及国际事务学院，使其早期即成为一所具备各种课程提供的综合大学。
 本着教育理应顺应潮流及不断求新进步的理念，该校于80年代加入了观光事业、饭店及餐饮管理、计算机科技等课程；在90年代，马尼拉大学更规划了教育博士、 公共行政管理博士及企管硕士等课程，为企业及政府部门之高阶主管提供了进一步学习的管道。 `,
      content_2 :
        '八十年来，马尼拉大学为菲律宾及亚洲的年轻人提供了第二个家，这个家透过品性教育给予他们更好的环境去学习未来工作的技能， 正如马尼拉大学的口号所言「对优秀的学术完全的投入」，是对教育事业希望的重申及信念的再造。 ',
      content_3 : '马尼拉大学企业管理学院于公元1992年成立，其主要目标有四点：',
      content_4 : '训练培养未来的企业领导者及行政人员，使其做好准备并具备处理商业及工业等各种有关于经济事务的能力。 ',
      content_5 : '帮助稳定国家经济。 ',
      content_6 : '使学生具备必要的知识并熟练掌握总体经济学，练习如何成为一个有效率的企业管理领导者。 ',
      content_7 : '开发学生面对企业问题时所需的分析组织及独立判断之能力。 ',
      content_8 :
        '因为意识到国家的发展取决于经济，而在国家发展初期又常会缺乏企业及经济人才，马尼拉大学企业管理学院长久以来一直极力于培养对经济发展有贡献的企业管理人才， 其主要的课程领域如下：会计学、银行及金融、管理、销售及经济学，能透过如此的教育来帮助国家的经济发展。 ',
      content_9 : `马尼拉大学教育学院于公元1923年成立，其主要目标在于提供学生持续的学习并研发更好的教学方法，指导如何用广泛的声音及视觉工具来帮助这些教学课程， 因此它不断地派遣教师赴国外考察学习新的教学技术并将其带入此学院加以发扬光大，希望让此学院的学生皆能成为一流的教育学者。
 马尼拉大学教育学院目前在大学部所提供的学位课程有英文教育学士、菲律宾语教育学士、数学教育学士、科学教育学士、心理咨商及指导教育学士、计算机科学教育学士及图书馆科学教育学士等。 这些教育教学课程除了对学生个人和社会的生活文化改进有所助益外，更着重强调如何教导学生具备教师应有的教育、文化和社会能力。 `,
      content_10 : `马尼拉大学工学院于公元1945年成立，第二次世界大战结束四个月后建立，由于第二次世界大战对国家的整体建设造成相当大的破坏，因此急需一群高级工业工程人才来参与战后国家工业重建工作，
 以带动国家的经济发展。马尼拉大学有鉴于此，立即配合国家政策需要，邀集相关教育学者及工程师来开办工学院，目的是透过这些专业教育课程，训练出具备知识学养及技能的工程师。 `,
      content_11 : '马尼拉大学国际事务学院于公元1946年成立，是菲律宾国家第一个成立的国际事务学院，其主要目标有三点：',
      content_12 : '给予未来有志从事于外交国际事务的学生适当及完整的课程训练，进而培养其成为杰出的外交主管及行政人员。 ',
      content_13 : '教导学生必要的外交知识并熟练国际礼仪，以具备处理国际事务的能力。 ',
      content_14 : '训练培养商业机构、国家机关、大众传播机构、银行机关及综合大学所需的外交人才。 ',
      content_15 : `马尼拉大学国际事务学院相信，好的外交手腕对国家世界的和平及人类的进步发展有相当大的决定因素，
 因此它不断地教导灌输学生这个信念。在课程上除了重视基本的外交及国际事务技能的学习，训练学生相关礼节及个性发展外，更着重于外交及国际事务相关经验及专业的传承教授，期待学生在进入各公私立机关后，皆能本此信念在其岗位为全人类的福祉共同奋斗。 `,
      content_16 :
        '五十年来，马尼拉大学国际事务学院不仅培育出多位外交国际事务教学与研究人才、投身于各学术机构的教育工作，更陶养出实际的外交国际事务参与者，以及无数在各行各业有突出表现的工作者，老师与学生的表现，有目共睹。 '
    }
  },
  adamson_university : {
    title   : '亚当森大学',
    content : {
      content_1 : '亚当森大学Adamson University 创立于 1932 年，创办之初是为了发展化学工业的需求，尤其针对菲律宾在化学领域的科学和技术。 ',
      content_2 : `1941 年 2 月，学校建立大学教育系。 1964 年 12 月，作为一个天主教大学加入 Adamson-Ozanam
    教育机构，专门致力于基督教教学的发展，广泛扩展学校，大量招生，尤其是针对穷人，并在世界性的基督教教学学术上有着杰出贡献。 `,
      content_3  : '如今，Adamson University 已经成为一个涉及领导机构研究生教育、法律、艺术、科学、工程、医药、建筑、工商管理、师范教育、中学、小学、学前教育等等的综合性大学。 ',
      content_4  : '研究院',
      content_5  : '教育硕士主修监督与管理',
      content_6  : '教育硕士主修计算机辅助教学',
      content_7  : '工商管理硕士',
      content_8  : '化学硕士',
      content_9  : '工程管理硕士',
      content_10 : '信息技术硕士',
      content_11 : '​工程硕士',
      content_12 : '药剂学硕士',
      content_13 : '神学硕士主修牧师学',
      content_14 : '神学硕士主修牧师学教条',
      content_15 : '神学硕士主修牧师学研究与宗教教育',
      content_16 : '教育博士',
      content_17 : '管理学博士',
      content_18 : '研究院',
      content_19 : '建筑学学士',
      content_20 : '商学院',
      content_21 : '会计理学士',
      content_22 : '工商管理学士主修银行及财经',
      content_23 : '工商管理学士主修管理',
      content_24 : '工商管理学士主修营销',
      content_25 : '办公室型这学士主修办公管理',
      content_26 : '办公室行政学士主修电脑教育',
      content_27 : '两年课程电脑文秘',
      content_28 : '教育学院',
      content_29 : '初级教育学士主修电脑技术教育',
      content_30 : '初级教育学士主修体育',
      content_31 : '初级教育学士主修宗教教育',
      content_32 : '初级教育学士主修英语',
      content_33 : '初级教育学士主修社会研究',
      content_34 : '中等教育学士主修电脑技术教育',
      content_35 : '中等教育学士主修体育',
      content_36 : '中等教育学士主修宗教教育',
      content_37 : '中等教育学士主修英语',
      content_38 : '中等教育学士主修社会研究',
      content_39 : '工学院',
      content_40 : '陶瓷工程学学士',
      content_41 : '化学工程学学士',
      content_42 : '土木工程学学士',
      content_43 : '计算机工程学学士',
      content_44 : '电机工程学学士',
      content_45 : '电子与通讯工程学学士',
      content_46 : '地质学学士',
      content_47 : '工业工程学学士',
      content_48 : '机械工程学学士',
      content_49 : '采矿',
      content_50 : '陶瓷工程学学士(特别课程5年制)',
      content_51 : '法学院',
      content_52 : '法律学士',
      content_53 : '文学院',
      content_54 : '经济学士',
      content_55 : '大众传播学士',
      content_56 : '哲学学士',
      content_57 : '政治学学士',
      content_58 : '护理学院',
      content_59 : '护理学学士',
      content_60 : '医药学院',
      content_61 : '药剂学学士',
      content_62 : '药剂技师',
      content_63 : '理工学院',
      content_64 : '生物学学士',
      content_65 : '化学学士',
      content_66 : '数学与计算机应用专业理学士',
      content_67 : '心理学学士',
      content_68 : '电脑技术学学士',
      content_69 : '计算机科学理学士',
      content_70 : '信息技术学学士',
      content_71 : '信息管理学士',
      content_72 : '[入学资格及相关日期]',
      content_73 : '大学部：',
      content_74 : '开学日期：',
      content_75 : '第一学季：六月十月',
      content_76 : '第二学季：十一月三月',
      content_77 : '第三季：四月~五月',
      content_78 : '申请资格：高中毕业或同等学历。 ',
      content_79 : '入学考报名日期：开学日期前二个月截止。 ',
      content_80 : '考试日期：一般为开学日期前一个月。 ',
      content_81 : '考试科目：数学、英文、自然科学、智力测验(特殊科目另外加考)，本公司可协助辅导通过。 ',
      content_82 : '硕士班：',
      content_83 : '开学日期：',
      content_84 : '第一学季：六月十月',
      content_85 : '第二学季：十一月三月',
      content_86 : '第三学季：四月~五月',
      content_87 : '申请资格：大学毕业或同等学历。大专毕业生须补本科课程再读硕士。 有些科系须有三年相关工作经验(商学院、工学院)。 ',
      content_88 : '入学考报名日期：开学日期前二个月截止。 ',
      content_89 : '考试日期：一般为开学日期前一个月。 ',
      content_90 : '考试科目：数学、英文、智力测验 (特殊科目另外加考)，本公司可协助辅导通过。 ',
      content_91 : '博士班：',
      content_92 : '个别办理'
    }
  },
  ellimio_aguinaldo_college : {
    title   : '伊密里欧学院',
    content : {
      content_1 : `EMILIO AGUINALDO COLLEGE（以下简称 EAC）是由 YAMAN LAHI FOUNDATION, INC. 纪念 EMILIO AGUINALDO 将军于 1973 年所投资创立，
    现有培养医师的医学系、牙医学系之外，另有药学系、医事技术学系、物理治疗学系、职能治疗学系、护理助产学系等，是一所设备新颖、师资优越的现代化完备医学教育体系。 `,
      content_2 : `YAMAN LAHI FOUNDATION, INC. 财团是西班牙在菲律宾资金雄厚的财团之一，投资的领域甚广， 尤其是房地产获得的利益最丰，转投资于教育方面资金颇巨，EMILIO AGUINALDO
    COLLEGE（EAC）是最成功的一部分，除了综合学院校舍外，另有附设医院及观光饭店，形成最现代化的校区。 `,
      content_3 : `EMILIO 医学院（EAC）的师资来自国立菲律宾大学医学院退休教授，在医学界具领导地位，
    再由教授推荐得意门生，以及欧美留学的学者担任教职，医学界老中青三代具备，薪火相传又能掌握最新的医学新知，将来形成医学研究的重镇。 `,
      content_4 :
        'EMILIO的师资大部分来自国立菲律宾大学转任或退休而来，并有许多教授曾经留学欧美国家，在教育界具有领导的地位。再由资深名誉的教授推荐新进的教授，薪火相传又能互相获得新知，成为教育界的佳话。 ',
      content_5 : `EAC 领导高层本着教育的高素质要求，让学生在求学之外更能锻炼坚强的体魄，于 2001 年斥资 2
    亿元人民币新建一栋体育大楼，内部设施涵盖有游泳池、篮球场、网球场等各式各样的体育设施，共有 11 层楼，总面积超过 3 万平方米，是菲律宾学校内数一数二的体育馆。 `,
      content_6 : `EAC 坐落于 Taft Avenue, United Nation Ave, San Narcelino, Padre Faura 及 Gen Luna St
    之间，临近国立菲律宾总医院、德拉萨大学、世界卫生组织亚洲总部等等，菲律宾警政署亦在其对面。步行 5 分钟就可到达 Roubison
    Mall，是马尼拉大学城的中心精华地带，治安良好，环境特佳的重点地区。 `,
      content_7  : '[伊密里欧大学部学位介绍]',
      content_8  : '[伊密里欧大学学院研究学位介绍]',
      content_9  : '[入学资格及相关日期]',
      content_10 : '大学部：',
      content_11 : '开学日期：',
      content_12 : '第一学期：六月十月',
      content_13 : '第二学期：十一月三月',
      content_14 : '暑修：四月~五月',
      content_15 : '申请资格：高中毕业或同等学历。 ',
      content_16 : '入学考报名日期：开学日期前一个月截止。 ',
      content_17 : '考试日期：一般为开学日期前一个月。 ',
      content_18 : '考试科目：数学、英文、自然科学、智力测验 (特殊科目另外加考)，本公司可协助辅导通过。 ',
      content_19 : '硕士班：',
      content_20 : '开学日期：',
      content_21 : '第一学期：六月十月',
      content_22 : '第二学期：十一月三月',
      content_23 : '署修：四月~五月',
      content_24 : '申请资格：',
      content_25 : '大学毕业或同等学历。 ',
      content_26 : '大专（三年制）毕业。 ',
      content_27 : '大学肄业或大专二年制毕业者，须补修学分。 ',
      content_28 : '有些科系须有三年相关工作经验（商学院、工学院）。 ',
      content_29 : '入学考报名日期：开学日期前一个月截止。 ',
      content_30 : '考试日期：一般为开学日期前一个月。 ',
      content_31 : '考试科目：数学、英文、智力测验（特殊科目另外加考），本公司可协助辅导通过。 ',
      content_32 : '博士班：',
      content_33 : '个别办理'
    }
  },
  university_of_the_east : {
    title   : '东方大学',
    content : {
      content_1 :
        '东方大学成立于第二次世界大战之后，那一年正好是菲律宾获得联合国的承认。东方大学用了半世纪的时间，致力推动高质量教学的发展方针，使得东方大学，许多国内执照考试通过率方面都有很好的成绩，尤其是他的医学院，与菲律宾国立大学、圣多汤玛士大学的医学院并列为菲律宾最佳的医学院。 ',
      content_2  : '在1954年东方大学创立了另一个Caloocan校区，提供更多更好的课系给越来越多的学子。并且在1955年，在奎松市创立了他们现在引以为豪的医学院及自己的附设医院。 ',
      content_3  : '经过了这么多年，东方大学始终把持着当初创校的宗旨，「不以盈利只为教育」的神圣使命继续在努力，培育出更多各领域的菁英在为国家社会做贡献。 ',
      content_4  : '东方大学的立校根本有三点：',
      content_5  : '提供的课程必须时时创新，赶上21世纪现代化国际步伐的需要。 ',
      content_6  : '以最先进的优良仪器设备、高格调的品德修养及强烈的社会进步使命作为努力方向。 ',
      content_7  : '创造一个可以自由发表、深入研究的环境，让学生拥有无限的发展空间。 ',
      content_8  : '[课程介绍]',
      content_9  : '工商管理博士',
      content_10 : '主修：金融管理、人力资源管理',
      content_11 : '教育学博士',
      content_12 : '主修：教育管理与领导',
      content_13 : '牙医学博士',
      content_14 : '2级水平',
      content_15 : '工商管理硕士',
      content_16 : '施工管理硕士',
      content_17 : '教学文学硕士',
      content_18 : '主修：生物科学、英语、菲律宾语、历史、数学、体育、政治学',
      content_19 : '教育学文学硕士',
      content_20 : '主修：教育管理和领导、图书馆科学、英语作为第二语言、指导计划A（含论文）、无论文的计划B：人力资源管理、财政管理、或国家政策和计划管理',
      content_21 : '理科硕士主修环境科学',
      content_22 : '图书馆与资讯研究硕士',
      content_23 : '法学学士',
      content_24 : '广播传播学士',
      content_25 : '新闻学学士',
      content_26 : '文学学士',
      content_27 : '主修：经济学、英语、菲律宾语、历史、国际研究（主修：美国研究、中国研究）、法务管理、政治学、社会学',
      content_28 : '理科学士',
      content_29 : '主修：生物、图书馆与资讯研究、数学、物理学、心理学、统计学',
      content_30 : '会计学与会计电脑化系统学士',
      content_31 : '会计学学士',
      content_32 : '工商管理学士',
      content_33 : '主修：金融管理、商业经济、工商管理、市场营销管理、管理会计',
      content_34 : '工商管理学士特别课程',
      content_35 : '土木工程学士',
      content_36 : '计算机工程学士',
      content_37 : '电机工程学士',
      content_38 : '电子与信息工程学士',
      content_39 : '工业工程学士',
      content_40 : '机械工程学士',
      content_41 : '计算机科学学士',
      content_42 : '信息技术学士',
      content_43 : '信息管理学士',
      content_44 : '中等教育学士',
      content_45 : '主修：生物、化学、物理、综合科学、英语、菲律宾语、历史、数学和体育（PEHM，包括体育、健康、教育和音乐）、指导与咨询、计算机教育',
      content_46 : '初级教育学士',
      content_47 : '按专业分为：英语、菲律宾语、家政学与技术、数学、体育、科学与健康、社会研究、早期儿童教育、指导与咨询初级阶段',
      content_48 : '营养学学士',
      content_49 : '信息技术学文学士',
      content_50 : '会计学与会计电脑化系统学士',
      content_51 : '会计学学士',
      content_52 : '工商管理学士',
      content_53 : '主修：财务管理，商业经济，工商管理，市场营销管理，管理会计',
      content_54 : '土木工程学士',
      content_55 : '计算机工程学士',
      content_56 : '电机工程学士',
      content_57 : '电子与通信工程学士',
      content_58 : '机械工程学士',
      content_59 : '文科学士主修广告学',
      content_60 : '理科学士主修室内设计',
      content_61 : '文科学士主修绘画',
      content_62 : '工业设计文学士',
      content_63 : '医学博士',
      content_64 : '护理学硕士',
      content_65 : '公共卫生学硕士',
      content_66 : '主修：健康设施管理，流行病学',
      content_67 : '临床医学硕士',
      content_68 : '主修：肠胃，生殖，耳鼻喉',
      content_69 : '内科学硕士',
      content_70 : '主修：糖尿病学',
      content_71 : '护理学学士',
      content_72 : '物理治疗学学士',
      content_73 : '[入学资格及相关日期]',
      content_74 : '大学部：',
      content_75 : '开学日期：',
      content_76 : '第一学季：六月~十月',
      content_77 : '第二学季：十一月~三月',
      content_78 : '第三学季：四月~五月',
      content_79 : '申请资格：高中毕业或同等学历。 ',
      content_80 : '入学考报名日期：开学日期前四个月截止。 ',
      content_81 : '考试日期：一般为开学日期前一~二个月。 ',
      content_82 : '考试科目：数学、英文、自然科学、智力测验（特殊科目另外加考），本公司可协助辅导通过。 ',
      content_83 : '硕士班：',
      content_84 : '开学日期：',
      content_85 : '第一学季：六月~十月',
      content_86 : '第二学季：十一月~三月',
      content_87 : '第三学季：四月~五月',
      content_88 : '申请资格：',
      content_89 : '大学毕业或同等学历。 ',
      content_90 : '大专毕业生须补本科课程再读硕士。 ',
      content_91 : '有些科系须有三年相关工作经验（商学院、工学院）。 ',
      content_92 : '入学考报名日期：开学日期前四个月截止。 ',
      content_93 : '考试日期：一般为开学日期前一~二个月。 ',
      content_94 : '考试科目：数学、英文、智力测验（特殊科目另外加考），本公司可协助辅导通过。 ',
      content_95 : '博士班：',
      content_96 : '个别办理'
    }
  },
  philippine_normal_university : {
    title   : '菲律宾师范大学',
    content : {
      content_1 : '学院简介',
      content_2 :
        '菲律宾国立师范大学由美国人，创建于1901年9月1日，并安置于马尼拉中心的TAFT街，作为一个培养老师的初级学院，最初提供了2年制的中等教育课程。 1949年后，提升成菲律宾师范的学院，并且修毕学士、家长教师等的专业课程。 1953年，建立了研究生院，主修包括文学硕士（MA）的专业课程，在1972年7月开始教育学、哲学博士课程做准备。 ',
      content_3 :
        '短短数年间不断扩充学术计划，及扩展多区域的服务，PNUE享有菲律宾教育高等领域相当足够的重视，学校在师范教育上得以立足。主要项目与最有优势课程为英语课本、公立小学计划、特殊教育课程，全国性基础课程及美国和平组织合格的私人教育组织。近期在Agusan del Sur、Isabela和Negros设立了分部。 ',
      content_4 :
        '1991年12月26日，学院改建为菲律宾国立师范大学，并在Quezon City（吕宋岛西南部城市）开办了第二个分院。 2001年9月1日，是学院的百年纪念日，21世纪学院将沿着百年历史继续前进。 ',
      content_5   : '学士学位课程',
      content_6   : '学士学位课程：',
      content_7   : '幼儿期教育学士（BECED）',
      content_8   : '初级教育学士（BEED）',
      content_9   : '中心课程：',
      content_10  : '初级英语',
      content_11  : '初级菲律宾语',
      content_12  : '初级数学',
      content_13  : '重点科学和健康',
      content_14  : '音乐、艺术和休闲教育',
      content_15  : '中学教育学士（BSEd）',
      content_16  : '主修：',
      content_17  : '生物学',
      content_18  : '化学',
      content_19  : '英语',
      content_20  : '菲律宾语',
      content_21  : '自然科学',
      content_22  : '历史',
      content_23  : '家政学',
      content_24  : '数学',
      content_25  : '音乐教育',
      content_26  : '体育',
      content_27  : '物理',
      content_28  : '物理和技术',
      content_29  : '社会科学',
      content_30  : '讲话和戏剧艺术',
      content_31  : '教育评 · 理学士 (BS)',
      content_32  : '主修：',
      content_33  : '生物学',
      content_34  : '生物学教学',
      content_35  : '化学教学',
      content_36  : '家政学和技术教育',
      content_37  : '信息技术教育',
      content_38  : '数学',
      content_39  : '数学教学',
      content_40  : '营养学教学',
      content_41  : '物理',
      content_42  : '物理教学',
      content_43  : '教育学文、理学士主修：文学',
      content_44  : '图书馆信息技术教育学学士（BLIS）',
      content_45  : '心理学学士（BSP）',
      content_46  : '教育心理学',
      content_47  : '指导与咨询',
      content_48  : '信息技术教育学士（BSITE）',
      content_49  : '硕士学位课程：',
      content_50  : '艺术教育硕士（MAED）',
      content_51  : '主修：',
      content_52  : '双语教育',
      content_53  : '儿童研究',
      content_54  : '学院教学',
      content_55  : '戏曲教育和剧院艺术',
      content_56  : '教育管理',
      content_57  : '初级教育',
      content_58  : '英语艺术学科',
      content_59  : '英文教学',
      content_60  : '指导与咨询',
      content_61  : '卫生教育',
      content_62  : '家政学',
      content_63  : '历史研究',
      content_64  : '健康与家政教育',
      content_65  : '语言测试',
      content_66  : '测试与评估',
      content_67  : '音乐教育',
      content_68  : '非正式教育',
      content_69  : '体育',
      content_70  : '科学教育',
      content_71  : '社会科学教学',
      content_72  : '图书馆信息科学',
      content_73  : '文学',
      content_74  : '数学教育',
      content_75  : 'Pagtuturo ng Filipino',
      content_76  : '阅读（英语）',
      content_77  : '学校心理学',
      content_78  : '特殊教育',
      content_79  : '价值教育',
      content_80  : '科学教育',
      content_81  : '教育技术硕士',
      content_82  : '化学教育硕士',
      content_83  : '语言学硕士',
      content_84  : '科学教育硕士',
      content_85  : '生物',
      content_86  : '化学',
      content_87  : '自然科学',
      content_88  : '物理',
      content_89  : '数学教学硕士',
      content_90  : '教学研究硕士 (MAT)',
      content_91  : '价值教育',
      content_92  : '文学',
      content_93  : '博士学位课程：',
      content_94  : '教育学博士',
      content_95  : '哲学博士',
      content_96  : '应用语言学',
      content_97  : '教育',
      content_98  : '教育管理',
      content_99  : '教育经营',
      content_100 : '指导与咨询',
      content_101 : '语言学',
      content_102 : '文学',
      content_103 : '入学资格及相关日期',
      content_104 : '大学部：',
      content_105 : '开学日期：',
      content_106 : '第一学期：六月~十月',
      content_107 : '第二学期：十一月~三月',
      content_108 : '第三学期：四月~五月',
      content_109 : '申请资格：高中毕业或同等学历。 ',
      content_110 : '入学考报名日期：开学日期前六个月截止。 ',
      content_111 : '考试日期：一般为开学日期前三~四个月。 ',
      content_112 : '考试科目：数学、英文、自然科学、智力测验（特殊科目另外加考），本公司可协助辅导通过。 ',
      content_113 : '硕士班：',
      content_114 : '开学日期：',
      content_115 : '第一学期：六月~十月',
      content_116 : '第二学期：十一月~三月',
      content_117 : '第三学期：四月~五月',
      content_118 : '申请资格：',
      content_119 : '大学毕业或同等学历。 ',
      content_120 : '大专毕业生须补本科课程再读硕士。 ',
      content_121 : '有些科系须有三年相关工作经验（商学院、工学院）。 ',
      content_122 : '入学考报名日期：开学日期前六个月截止。 ',
      content_123 : '考试日期：一般为开学日期前三~四个月。 ',
      content_124 : '考试科目：数学、英文、智力测验（特殊科目另外加考），本公司可协助辅导通过。 ',
      content_125 : '博士班：',
      content_126 : '​各别办理'
    }
  },
  de_la_salle_university : {
    title   : '德拉萨大学',
    content : {
      content_1 :
        '德拉萨大学创立于公元1911年，位于马尼拉的市区，是菲律宾一流的贵族基督学校。创办初期为125个不同年龄及程度的学生授课，且在公元1915年有第一批的商业中学学生毕业。公元1925年，德拉萨大学被允许授课文学士及文学硕士课程，且于公元1930年被允许授课教育科学学士及教育科学硕士课程。 ',
      content_2 :
        '在第二次世界大战时，日本军队占领了此大学并作为防守驻扎地。战争期间虽然仍继续授课，但课程却大量减少，且当时军方不断轰炸，导致学校的体育馆、图书馆、实验室严重损坏。公元1945年7月，战争结束后，德拉萨大学重新建立其校园，虽然缺乏人力资源及设备，但在学期末时仍有60个学生毕业于中学部。 ',
      content_3 :
        '德拉萨大学为加强其高等教育课程，先后在公元1947年成立大学部工学院；公元1953年成立艺术与科学学院；公元1959年成立教育学系；公元1960年成立企业管理研究所；公元1963年成立教育研究所；公元1973年成立工业技术学院（后来合并于工学院）；公元1980年成立专业发展部门；公元1981年成立计算机信息学院等。 ',
      content_4 :
        '公元1981年至1982年是德拉萨大学的一个重要的里程碑，因为它将原本学期制的课程（一年两个学期）改为学季制课程（一年三个学季），其开学日期分别在每年一月、六月及九月，而暑假则是在四月至五月。每个学季皆可入学且上课时间约十三周，此学制的改革让有意至此修读的学生不会浪费太多的等待入学时间。 ',
      content_5 : '德拉萨大学的学费要比一般的学校贵，本科段学费比一般学校增加80%，硕士段学费比一般学校增加20%，基本都在14000元人民币左右。 ',
      content_6 :
        '德拉萨大学语言中心为提供学生精进英语能力的机会，特地根据学生的需求及能力安排了不同阶段及课程的英文密集短期训练班，采小班制教学使老师能针对个别的学生提供必要的协助。上课重点在于让学生能热烈参与课程并彼此互动学习，学生可在入学前先至此中心加强英文能力，为日后的正式学程奠定英文基础。 ',
      content_7 :
        '德拉萨大学语言中心依据学生需求共安排了三个不同阶段的英文训练，每个阶段都有数种课程，且每个课程亦根据学生英文程度而区分为八个等级。单个课程上课时间为每天二小时，为期三周共三十小时。学生每个月可选择三种以内的课程并经语言中心评鉴其英文能力等级后分级上课。 ',
      content_8  : '兹分述各阶段课程如下：',
      content_9  : '[德拉萨大学大学部学位介绍]',
      content_10 : '[德拉萨大学研究所学位介绍一]',
      content_11 : '[德拉萨大学研究所学位介绍二]',
      content_12 : '[德拉萨大学研究所学位介绍三]',
      content_13 : '[入学资格及相关日期]',
      content_14 : '大学部：',
      content_15 : '开学日期：',
      content_16 : '第一学季：六月~九月',
      content_17 : '第二学季：九月~十二月',
      content_18 : '第三学季：一月~三月',
      content_19 : '申请资格：高中毕业或同等学历。 ',
      content_20 : '入学考报名日期：开学日期前四个月截止。 ',
      content_21 : '考试日期：一般为开学日期前一~二个月。 ',
      content_22 : '考试科目：数学、英文、自然科学、智力测验（特殊科目另外加考），本公司可协助辅导通过。 ',
      content_23 : '硕士班：',
      content_24 : '开学日期：',
      content_25 : '第一学季：六月~九月',
      content_26 : '第二学季：九月~十二月',
      content_27 : '第三学季：一月~三月',
      content_28 : '申请资格：',
      content_29 : '大学毕业或同等学历。 ',
      content_30 : '大专毕业生须补本科课程再读硕士。 ',
      content_31 : '有些科系须有三年相关工作经验（商学院、工学院）。 ',
      content_32 : '入学考报名日期：开学日期前两个月截止。 ',
      content_33 : '考试日期：一般为开学日期前一个月。 ',
      content_34 : '考试科目：数学、英文、智力测验（特殊科目另外加考），本公司可协助辅导通过。 ',
      content_35 : '博士班：',
      content_36 : '个别办理'
    }
  }
}

export default {
  study : {
    study_intro : {
      content_1  : '一：留學原因',
      content_2  : '國內升學壓力大，升學管道少。',
      content_3  : '國內英語學習環境受限。',
      content_4  : '父母想移民，讓孩子先適應環境。',
      content_5  : '希望孩子能在自由發展的教育環境中成長。',
      content_6  : '培養孩子獨立自主的個性及國際觀、世界觀的視野。',
      content_7  : '二：選擇菲律賓的優勢',
      content_8  : '免托福，手續簡便、迅速，大專直升研究所，高中（職）、中專生可直升大學，學費低廉。',
      content_9  : '菲律賓是全球第三大英語系國家，各科系學生所修得的成績單與學分美國及世界各國皆承認，是最快速、最簡便、最好且唯一的跳板。',
      content_10 : '採用美式教學，所修得的文憑美國、加拿大皆承認，並可轉赴美國、加拿大繼續深造。',
      content_11 : '醫技系、藥學系、護理系、營養系、生物系、生命科學系、公衛系等畢業生進修的最佳選擇。',
      content_12 : '學費低廉，有志者皆具經濟能力前往深造。',
      content_13 : '擁有完善的英語教學環境，培養一流的英文能力。',
      content_14 : '每年有三次假期，大陸沿海城市至馬尼拉飛行時間約3小時（往返便利）。',
      content_15 : '唯一最省錢的出國深造機會。',
      content_16 : '境外有馬尼拉區口碑最佳、規模最大的服務機構，保障學生安全，讓家長放心。',
      content_17 : '三：菲律賓大學簡介',
      content_18 : `就讀菲律賓大學、研究所的展望：在當今國際潮流中，高學歷的碩士、博士人才已成為時勢所需。
              前往菲律賓讀大學的優勢在於可免除國內考試制度的壓力（以成績決定就讀科系），可依個人興趣申請科系就讀。免托福、亞洲唯一的英語系國家、學費低廉、生活費便宜、課程選修彈性化，並且其學歷曾在國際上受到承認。此外，更能讓學生在將來的就業市場上占有一席之地，享有高所得的居家生活。`,
      content_19 : `在菲律賓大約有650所高等學校，其中包括7所公立大學、25所公立學院、35所私立大學以及超過550所私立學院，約有25個不同國籍的學生在菲律賓就讀，包括中國、美國、韓國、中東地區等，這些學生占了大多數。
              一般來說，進入高等學校必須有高中畢業證書，各校獨立招生均需考試和面試，但這並不保證可進入某特定學校，可能需要進一步的申請核准。`,
      content_20 : '菲律賓大多數大學的開學日期一年有三次（4月、6月、11月中），若要入學者，請盡早於2個月以前提出申請，並確認申請日期，以便盡早入境菲律賓。',
      content_21 : '申請人資格',
      content_22 : '初中畢業者可先補讀高中三年即可就讀大學。',
      content_23 : '應屆高中、職校、中專學生，免托福成績，可直接升入大學。',
      content_24 : '具備大學、大專（三年制）畢業證書者可直接升入研究所；若為大學或大專肄業生，可申請補修學分後再升入研究所。',
      content_25 : '公私立教職員可利用暑假、寒假前往進修，取得學位（免托福、免請假、免離職，費用省）。',
      content_26 : '護校或護專畢業生可在菲律賓一邊補修本科學歷，一邊培訓美國境外護士考試，利用菲律賓優良的英語環境及低廉的費用，順利通過考試後赴美國就業。',
      content_27 : '各校名額有限，證件申請亦需時間，請盡早於學期開始前3個月提出申請，以便保留名額，因各大學科系都有名額限制，且菲律賓教育部規定外國學生有10%的名額限制。',
      content_28 : '菲律賓學校學制介紹',
      content_29 :
        '學期時間：菲律賓大學（學期制）一年有2個學期及1個暑修，第一學期（6月10月）、第二學期（11月3月）、暑修（4月~5月），第一、二學期及暑修皆可申請入學，惟暑修因時間較短，上課時間為正常學期的一半，暑修規定每人只能修9-12個學分。',
      content_30 : `大陸與菲律賓的學制比較：由於菲律賓的學制為小學6年、中學（初中加高中）6年，共計12年；而大陸的小學5年、預備班1年、初中3年、高中3年，
              總計12年，因此大陸的高中、高職、中專畢業生均可直接申請大學。而且菲律賓部分高校認定三年以上的大專學歷相當於本科畢業，因此大陸的大專畢業生在菲律賓被視為COLLEGE
              GRADUATE，這樣大專及大學生可直接申請研究所（一般約1年6個月至2年可完成碩士課程）。`
    },
    application_requirements : {
      content_1  : '申請人資格：',
      content_2  : '初中畢業者：完成三年高中課程後，即可申請就讀大學。',
      content_3  : '應屆高中、高職、中專學生：免托福成績，可直接升入大學。',
      content_4  : '具備大學、大專（三年制）畢業證書者：可直接升入研究所；若為大學或大專肄業生，可申請補修學分後再升入研究所。',
      content_5  : '公私立教職員：可利用暑假、寒假前往進修，取得學位。（免托福、免請假、免離職、費用省）',
      content_6  : '護校或護專畢業生：可到菲律賓一邊補修本科學歷，一邊培訓美國境外護士考試，利用菲律賓優良的英語環境及低廉的費用，順利通過考試後赴美國就業。',
      content_7  : '各校名額有限，證件申請亦需時間，請盡早於學期開始前3個月提出申請，以便保留名額，因為各大學科系都有名額限制，且非國教育部規定各大學外國學生有10%的名額限制。',
      content_8  : '申請入學應備文件如下：（無護照者請先辦理護照）',
      content_9  : '持最後學歷之成績單、學位證書：到當地學校管轄之公證處辦理中英文公證（公證為一份正本一份副本）。請申請人優先辦理此項。',
      content_10 : '申請人須到各地公安局辦理無犯罪記錄，然後到戶籍管轄之公證處辦理中英文公證（公證為一份正本一份副本），請申請人優先辦理此項。',
      content_11 : '攜帶戶口本到戶籍管轄的公證處申請初始證明，並辦理中英文公證（公證為一份正本一份副本）。請申請人優先辦理此項。（註明出生地及出生年月日）',
      content_12 : '彩色白底相片2吋6張。（背面要寫上與護照相同之中英文名字）',
      content_13 : '護照正本',
      content_14 : '體檢表1份（含14寸X光片）。（在菲律賓辦理）申請人財力證明須以本人之帳戶存款人民幣5萬元以上，開立金融機構之存款證明（英文版5份）。',
      content_15 : '支持書（英文版5份）',
      content_16 : '個人資料6份。（由本公司處理）',
      content_17 : '校長或院長、教授或系主任的推薦信各2份。（由本公司處理）',
      content_18 : '備註：',
      content_19 : '上述第一、第二、第三項文件在公證處公證之後，送外事辦認證，再送菲律賓領事館（上海、廣州、區（門）或大使館（北京））認證。',
      content_20 : '護照須到菲律賓領事館或大使館辦理入境簽證（本公司可代辦或自行委託旅行社）。',
      content_21 : '以上兩點完成後，將以上文件帶齊即可赴菲律賓。',
      content_22 : '到菲律賓之後，由本公司協助在菲律賓體檢並將入境簽證轉變成學生簽證，正式取得學生居留權，學生即可持正式學生簽證留在菲律賓就讀。'
    },
    supported_schools : {
      schools : {
        school_1  : '安琪大學',
        school_2  : '亞洲管理學院',
        school_3  : '馬尼拉安德雷爾大學',
        school_4  : '宿霧醫師群醫學院',
        school_5  : '宿霧醫學院',
        school_6  : '中央大學',
        school_7  : '中正學院',
        school_8  : '德拉薩大學',
        school_9  : '伊密里歐大學',
        school_10 : '遠東大學',
        school_11 : '亞諾尼塔大學',
        school_12 : '馬尼拉中心大學',
        school_13 : '奧莉維亞大學',
        school_14 : '基督大學',
        school_15 : '菲律賓國立師範大學',
        school_16 : '聖北達大學',
        school_17 : '聖斯伯斯汀大學',
        school_18 : '東南學院',
        school_19 : '宿霧西南大學',
        school_20 : '聖劉易斯大學',
        school_21 : '聖路克斯醫學院',
        school_22 : '東方醫學院',
        school_23 : '馬尼拉大學',
        school_24 : '波比丘大學',
        school_25 : '聖湯瑪士大學',
        school_26 : '東方大學',
        school_27 : '菲律賓國立大學',
        school_28 : '維薩亞斯大學',
        school_29 : '維京大學'
      },
      content_1  : '備註：以上所排列之學校是以英文字母排略，而非學校名次排列',
      content_2  : '文、法、商學院及研究所科系',
      content_3  : '「大學部科系」',
      content_4  : '建築系',
      content_5  : '法文系',
      content_6  : '西班牙文系',
      content_7  : '英文系',
      content_8  : '日文系',
      content_9  : '企業管理系',
      content_10 : '飯店及餐廳管理系',
      content_11 : '公共行政管理系',
      content_12 : '經濟系',
      content_13 : '發展經濟系',
      content_14 : '觀光系',
      content_15 : '基礎教育系',
      content_16 : '中等教育系',
      content_17 : '特殊教育系',
      content_18 : '大眾傳播系',
      content_19 : '音樂系',
      content_20 : '「碩士班科系」',
      content_21 : '建築系',
      content_22 : '英文系',
      content_23 : '法文系',
      content_24 : '西班牙文系',
      content_25 : '企業管理系',
      content_26 : '公共行政管理系',
      content_27 : '飯店及餐廳管理系',
      content_28 : '統計系',
      content_29 : '經濟系',
      content_30 : '化學',
      content_31 : '心理',
      content_32 : '諮詢',
      content_33 : '教育管理',
      content_34 : '圖書館系',
      content_35 : '合唱',
      content_36 : '音樂教育',
      content_37 : '音樂學',
      content_38 : '聲樂',
      content_39 : '「博士班科系」',
      content_40 : '企業管理系',
      content_41 : '公共行政管理系',
      content_42 : '飯店及餐廳管理系',
      content_43 : '教育管理',
      content_44 : '統計系',
      content_45 : '經濟系',
      content_46 : '理、工學院及研究所科系',
      content_47 : '「大學部科系」',
      content_48 : '水利資源系',
      content_49 : '工業工程系',
      content_50 : '環境科學系',
      content_51 : '氣象學系',
      content_52 : '計算機科學系',
      content_53 : '化學工程系',
      content_54 : '計算機工程系',
      content_55 : '電子工程系',
      content_56 : '電子通訊工程系',
      content_57 : '機械工程系',
      content_58 : '運東科學系',
      content_59 : '「碩士班科系」',
      content_60 : '材料工程系',
      content_61 : '化學工程系',
      content_62 : '計算機科學系',
      content_63 : '電子工程系',
      content_64 : '環境工程系',
      content_65 : '工業工程系',
      content_66 : '機械工程系',
      content_67 : '水利資源系',
      content_68 : '應用數學',
      content_69 : '數學',
      content_70 : '生物',
      content_71 : '化學',
      content_72 : '物理',
      content_73 : '「博士班科系」',
      content_74 : '化學工程系',
      content_75 : '電子工程系',
      content_76 : '生物',
      content_77 : '化學',
      content_78 : '環境科學系'
    },
    study_costs : {
      plan : {
        university : {
          title : '大學部',
          year  : '一般四年，最快三年'
        },
        master : {
          title : '碩士班',
          year  : '一年六個月'
        },
        doctoral : {
          title : '博士班',
          year  : '二年(有些科系三年)'
        },
        estimated_cost : '預計花費',
        more           : '了解更多',
        tuition        : '學費{cost} CNY/學期',
        room_and_board : '食宿{cost} CNY/月',
        documents      : '證件{cost} CNY/年',
        donation       : '奉獻金：USD {cost}(美金)'
      },
      content_1 : '以上費用不包含國內代辦中介費、個人機票、水電費及零用錢。',
      content_2 : '房間為二人共享一室，若要一人一室，則每人每月需增加房租623CNY',
      content_3 : '學生所需證件(學位證書英文版，成績單英文版、無犯罪紀錄英文版、出生證明英文版)之公正處',
      content_4 : '公證及外事辦認證、菲律賓大使館認證、皆須在大陸地區辦理',
      content_5 : '學生之護照及菲律賓簽證皆須在大陸地區辦理',
      content_6 : '上述證件費包含學生簽證、體檢、居留證、返菲證、簽證延期等所有在學間須辦理之證件費用。',
      content_7 : '以上費用視實際簽約學校稍有變動'
    },
    authorization_certificate : {
      centro_escolar_university : '菲律賓中央大學',
      josé_rizal_university     : '菲律賓國父大學',
      ellimio_aguinaldo_college : '伊密里歐學院',
      university_of_manila      : '馬尼拉大學',
      attorney                  : '​授權證書'
    }
  }
}

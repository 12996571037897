import { createApp } from 'vue'

//vuetify
import { loadFonts } from './plugins/webfontloader'
import vuetify from './plugins/vuetify'

import installI18n from '@/plugins/i18n'
import router from './router'
import App from './App.vue'

let app = createApp(App)
installI18n(app)
loadFonts()
app.use(router)

app.use(vuetify)

app.mount('#app')

import { i18nInstance } from '@/plugins/i18n.js'

let i18n = i18nInstance()

export default [
  {
    path      : '/',
    component : () => import('@/layouts/home.vue'),
    children  : [
      {
        path      : '/',
        name      : 'home',
        component : () => import('@/views/Home/index.vue')
      },
      {
        path      : '/country-overview',
        name      : 'country-overview',
        component : () => import('@/views/Country/overview.vue')
      },
      {
        path      : '/country-features',
        name      : 'country-features',
        component : () => import('@/views/Country/features.vue')
      },
      {
        path      : '/country-system',
        name      : 'country-system',
        component : () => import('@/views/Country/system.vue')
      },
      {
        path      : '/study-intro',
        name      : 'study-intro',
        component : () => import('@/views/Study/intro.vue')
      },
      {
        path      : '/application-requirements',
        name      : 'application-requirements',
        component : () => import('@/views/Study/application-requirements.vue')
      },
      {
        path      : '/supported-schools',
        name      : 'supported-schools',
        component : () => import('@/views/Study/supported-schools.vue')
      },
      {
        path      : '/study-costs',
        name      : 'study-costs',
        component : () => import('@/views/Study/costs.vue')
      },
      {
        path      : '/authorization-certificate',
        name      : 'authorization-certificate',
        component : () => import('@/views/Study/authorization-certificate.vue')
      },
      {
        path      : '/adams-university',
        name      : 'adams-university',
        component : () => import('@/views/Home/index.vue')
      },
      {
        path      : '/campus-scenery',
        name      : 'campus-scenery',
        component : () => import('@/views/Gallery/campus-scenery.vue')
      },
      {
        path      : '/overseas-services',
        name      : 'overseas-services',
        component : () => import('@/views/OverseasServices/index.vue')
      },
      {
        path      : '/medical-studies',
        name      : 'medical-studies',
        component : () => import('@/views/MedicalStudies/index.vue')
      },
      {
        path      : '/contact-us',
        name      : 'contact-us',
        component : () => import('@/views/ContactUs/index.vue')
      },
      {
        path      : '/about',
        name      : 'about',
        component : () => import('@/views/About/index.vue')
      },
      {
        path     : '/sub',
        children : [
          {
            path      : '1',
            name      : 'sub-1',
            component : () => import('@/views/Sub/1.vue')
          },
          {
            path      : '2',
            name      : 'sub-2',
            component : () => import('@/views/Sub/2.vue')
          },
          {
            path      : '3',
            name      : 'sub-3',
            component : () => import('@/views/Sub/3.vue')
          }
        ]
      },
      {
        path     : '/interaction',
        children : [
          {
            path      : '1',
            name      : 'interaction-1',
            component : () => import('@/views/Interaction/1.vue')
          },
          {
            path      : '2',
            name      : 'interaction-2',
            component : () => import('@/views/Interaction/2.vue')
          },
          {
            path      : '3',
            name      : 'interaction-3',
            component : () => import('@/views/Interaction/3.vue')
          },
          {
            path      : '4',
            name      : 'interaction-4',
            component : () => import('@/views/Interaction/4.vue')
          },
          {
            path      : '5',
            name      : 'interaction-5',
            component : () => import('@/views/Interaction/5.vue')
          }
        ]
      },
      {
        path     : '/institution',
        children : [
          {
            path      : 'University-of-Manila',
            name      : 'University-of-Manila',
            component : () => import('@/views/Institution/University-of-Manila.vue')
          },
          {
            path      : 'Adamson-University',
            name      : 'Adamson-University',
            component : () => import('@/views/Institution/Adamson-University.vue')
          },
          {
            path      : 'Ellimio-Aguinaldo-College',
            name      : 'Ellimio-Aguinaldo-College',
            component : () => import('@/views/Institution/Ellimio-Aguinaldo-College.vue')
          },
          {
            path      : 'University-of-the-East',
            name      : 'University-of-the-East',
            component : () => import('@/views/Institution/University-of-the-East.vue')
          },
          {
            path      : 'Philippine-Normal-University',
            name      : 'Philippine-Normal-University',
            component : () => import('@/views/Institution/Philippine-Normal-University.vue')
          },
          {
            path      : 'De-La-Salle-University',
            name      : 'De-La-Salle-University',
            component : () => import('@/views/Institution/De-La-Salle-University.vue')
          }
        ]
      }
    ]
  }
]

export default {
  medical_studies : {
    content_1 : '菲律賓醫學院簡介',
    content_2 : `菲律賓醫學科系八年學成後為臨床醫學博士學位，其中分為預醫學系（四年）和正醫學系（四年）兩個階段。
          預醫是指大學部醫學相關科系的學士學位，正醫則為臨床醫學博士學位，醫學博士即有資格考美國職業醫師執照。`,
    content_3 : '醫學相關科系（預醫科系）',
    content_4 : '可直接申請就讀醫學院正醫學系的預醫科系，一般與醫學相關的科系，如醫技、藥學、復健、生物、護理、放射、公共衛生、醫管、獸醫等等學士學位科系。',
    content_5 : '就讀正醫科系的基本條件',
    content_6 :
      '具備醫學相關科系的學士學位；非相關醫學科系的學士學位者，需補修醫學系相關的基礎課程一年，包括：數學9學分（包含代數、三角、統計）；化學10學分（包含一般化學、有機化學）；生物學15學分（包含植物學、動物學）；物理學5學分，以下俗稱39學分。',
    content_7 : '通過菲律賓醫學院入學資格考試（NMAT）',
    content_8 : '申請就讀醫學院',
    content_9 :
      '一般在申請學校前，應先報考菲律賓醫學院入學資格考試（NMAT）。但若因申請時間太晚，則可向原畢業學校申請英文版畢業證書及成績單，經申請學校學分評估，認可後直接申請就讀正醫學系一年級，依各校規定不同，NMAT可延後參加。',
    content_10 : '菲律賓醫學院入學資格考試 NMAT',
    content_11 : 'NMAT- National Medical Admission Test，即菲律賓醫學院入學資格考試 考試報名及其條款：',
    content_12 : '大學或學院（醫學相關科系）三年級以上學生、三年制大專畢業或在學最後一年者可報名參加考試。',
    content_13 : '須準備英文畢業（在讀）證明和在校成績單、護照及照片（3張2寸）。',
    content_14 : '報名並購買報名簡章（本公司在菲辦理）。',
    content_15 : '每年會考兩次，約十月、二月中旬前報名：十二月、四月中旬的星期日考試，考試時間為一天，約六週後公布成績。',
    content_16 : '考試科目： Verbal、Inductive Reasoning、Quantitative、Perceptual Acuity、Biology、Physics、Social Science、Chemistry，共八門。',
    content_17 : 'NMAT會考的成績單須保存到醫學院畢業，因回國認證學歷時必備，如成績不理想的可以複考多次，取最高分數。',
    content_18 : '美、加等地的學生可直接在美國紐約或舊金山考場報名考試，每年十月及十二月考試，前兩週截止報名。',
    content_19 : '如UST、FEU部分名校，學校醫學院獨立的招生入學考試安排在1~3月，申請名校就讀的學生需參加十二月NMAT的考試。',
    content_20 : '申請人資格',
    content_21 : '醫學相關大學本科生：',
    content_22 : '醫學相關科系學生，可以直接申請就讀正醫科，並可同時報考NMAT。',
    content_23 : '非醫學相關大學本科生：',
    content_24 : '提供英文的成績單，給醫學院評估，非醫學相關科系須補修39學分，並可同時報考NMAT。（若成績單中有科目與醫學科系有相關性的，即可抵免部分學分）。',
    content_25 : '三年制大專生：',
    content_26 : '先完成學士學位（預醫）資格才可就讀正醫。相關科系約需一年半至兩年，非相關科系約需兩年至兩年半。補修學士學位中含39基礎學分，並可同時報考NMAT。',
    content_27 : '在讀、大專肄業生：',
    content_28 : '申請轉學菲律賓醫學院預醫學大三或大四。預醫學系畢業後再就讀正醫學系。',
    content_29 : '高中畢業生：',
    content_30 :
      '申請就讀醫學院預醫學系，預醫三年級以上即可報考NMAT，預醫畢業後再進入正醫學系就讀。以高中生而言，就讀醫學系的畢業年限為八年（預醫學、正醫學各四年），部分學校預醫學每年參加暑修，三年即能畢業。',
    content_31 : '初中畢業、高中肄業生',
    content_32 : '申請轉學菲律賓高中就讀，一般初中畢業生補讀三年高中，高一完成補讀兩年，高中畢業後直接申請就讀醫學院預醫學，畢業後再就讀正醫學。',
    content_33 : '備註：',
    content_34 : '臨床醫學預科階段攻讀醫學相關學系專業，如藥學、護理學、生物學等等。',
    content_35 : '畢業後可拿醫學相關學系學士學位，隨後決定是否繼續讀臨床醫學正科。',
    content_36 : '臨床醫學博士畢業後，可以考美國醫師執照。',
    content_37 : '預醫科+正醫科',
    content_38 : '預醫四年、正醫四年 總計八年',
    content_39 : '預計花費',
    content_40 : '了解更多',
    content_41 : '預醫 學費5,800 CNY/學期',
    content_42 : '正醫學費14,000 CNY/學期',
    content_43 : '食宿費1,730 CNY/月',
    content_44 : '證件 首年4,000 CNY',
    content_45 : '(學簽、體檢、居住證)',
    content_46 : '第二年起1,000 CNY 年',
    content_47 : '預醫奉獻金5,000 CNY',
    content_48 : '正醫奉獻金13,000 CNY',
    content_49 : '正醫科',
    content_50 : '四年',
    content_51 : '預計花費',
    content_52 : '了解更多',
    content_53 : '正醫學費14,000 CNY/學期',
    content_54 : '食宿費1,730 CNY/月',
    content_55 : '證件 首年4,000 CNY',
    content_56 : '(學簽、體檢、居住證)',
    content_57 : '第二年起1,000 CNY年',
    content_58 : '正醫奉獻金13,000 CNY',
    content_59 : '醫師執照',
    content_60 : '中國醫師執照：',
    content_61 : '醫師資格考試的性質是行業准入考試，是評價申請醫師資格者是否具備從事醫師工作所必須的專業知識與技能的考試。醫師資格考試分實踐技能考試和醫學綜合筆試兩部分。',
    content_62 :
      '實踐技能考試採用多站測試的方式，考區設有實踐技能考試基地，根據考試內容設置若干考站，考生依次通過考站接受實踐技能的測試。每位考生必須在同一考試基地的考站進行測試。',
    content_63 :
      '醫師資格考試醫學綜合筆試於9月中旬舉行，具體時間以衛生部醫師資格考試委員會公告時間為準，執業醫師考試時間為2天，分4個單元；執業助理醫師考試時間為1天，分2個單元，每單元均為兩個半小時。',

    content_64 : '美國醫師執照',
    content_65 : `美國醫師執照考試被全世界公認，醫學院的學生正在醫學系二年級以上，即可在菲律賓當地報考美國執照第一階段基礎醫學考試。正醫學系三年級以上，即可在菲律賓當地報考美國執照第二階段臨床醫學考試。
          每年的考試時間為第一階段6月、10月；第二階段3月、8月，第三階段則須赴美參加考試。`,
    content_66 : '大學部預醫學系',
    content_67 : '藥學',
    content_68 : '護理學',
    content_69 : '醫學科學',
    content_70 : '生物化學',
    content_71 : '心理學',
    content_72 : '生物學',
    content_73 : '物理學',
    content_74 : '化學',
    content_75 : '職能治療學',
    content_76 : '物理治療學',
    content_77 : '放射技術學',
    content_78 : '醫事技術學',
    content_79 : '動物學',
    content_80 : '醫學院名校碌',
    content_81 : '備註：有"★"的學校是本公司保證入學的，且位於馬尼拉市區的名校',
    schools    : {
      school_1  : '安琪大學 ★',
      school_2  : '德拉薩大學 ★',
      school_3  : '伊密里歐大學 ★',
      school_4  : '遠東大學 ★',
      school_5  : '馬尼拉中心大學 ★',
      school_6  : '聖劉易斯大學',
      school_7  : '聖路克斯醫學院 ★',
      school_8  : '東方醫學院 ★',
      school_9  : '聖湯瑪士大學',
      school_10 : '菲律賓國立大學',
      school_11 : '維京大學'
    }
  }
}
